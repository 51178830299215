.legal-page {
  padding: 24px 0 50px;
  font-family: $font-default;
  background-color: $primary-background;

  @include md {
    padding: 40px 0 70px; }

  h2, h3, h4, h5 {
    color: $text-dark;
    text-align: center;
    font-weight: 700; }

  h2 {
    font-size: 26px;
    padding: 15px 0;

    @include md {
      font-size: 36px;
      padding: 25px 0; } }

  h3 {
    font-size: 20px;
    text-transform: uppercase;
    padding: 15px 0;

    @include md {
      font-size: 24px;
      padding: 25px 30px; } }

  h5 {
    font-size: 16px;
    margin-bottom: 20px;

    @include md {
      font-size: 18px;
      margin-bottom: 30px; } }

  p {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 400;
    color: $text-dark;

    @include md {
      font-size: 16px;
      margin-bottom: 20px; } }

  a {
    color: $green;
    font-weight: 600; }

  .btn {
    color: white;
    font-weight: 700; }

  ul {
    list-style: none;

    li {
      position: relative;
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 3px;
      font-weight: 400;
      color: $text-dark;

      @include md {
        font-size: 16px; }

      &:before {
        content: '';
        font-weight: bold;
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: $green;
        border-radius: 50%;
        margin-right: 7px;
        top: 50%;
        transform: translateY(-50%); } } }

  ol {
    list-style: decimal;
    margin-left: 18px;

    li {
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 3px;
      font-weight: 400;
      color: $text-dark;

      @include md {
        font-size: 16px; } } } }

.about-page {
  strong {
    font-size: 16px;
    color: $green;

    @include md {
      font-size: 18px; } }

  &__icons {
    .icons-about {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      max-width: 100%;
      margin: 30px auto;

      @include md {
        flex-direction: row;
        width: 80%; }

      &__square {
        background: $gray-lighter;
        box-shadow: 0px 2px 20px rgba(23, 128, 224, 0.15);
        border-radius: 15px;
        transform: rotate(45deg);
        height: 55px;
        width: 55px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          position: relative;
          transform: rotate(-45deg);
          font-size: 20px;
          color: $green;

          &:after {
            content: '';
            width: 28px;
            height: 28px;
            border-radius: 11px;
            background: linear-gradient(0deg, white -51.32%, #DAE9F9 99.16%);
            position: absolute;
            z-index: -1;
            left: 0;
            top: -2px; }

          @include md {
            font-size: 25px;

            &:after {
              width: 48px;
              height: 48px;
              left: -5px;
              top: -10px; } } }

        @include lg {
          width: 83px;
          height: 83px; } }

      &__row {
        display: flex;
        justify-content: space-around;
        flex: 1;
        margin-bottom: 35px;

        &:last-child {
          margin-bottom: 0; }

        @include md {
          margin-bottom: 0; } } } } }
