.header {
  transition: $base-transition;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 1050;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  .start-listing-btn {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;

    .btn {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      width: 0;
      transition: background-color 0.2s;
      background: transparent;
      height: 0; } }

  &.is-fixed {
    &.start-listing {
      .nav__item {
        display: none !important; }

      .start-listing-btn {
        width: fit-content;
        display: block !important;
        margin-right: 0;
        height: fit-content;

        .btn {
          opacity: 1;
          visibility: inherit;
          pointer-events: inherit;
          width: fit-content;
          background: $green;
          color: white;
          height: 43px; } } } }

  &__inner {
    width: 100%;
    position: relative; }

  &__main-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;

    .logo {
      width: 120px;
      height: auto; }

    @include md {
      width: auto;
      display: inline-block;
      padding-left: 0;

      .logo {
        width: 177px; } } }

  &__nav {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    padding-top: 20px;
    width: 100%;
    flex: 1;
    background-color: white;
    box-shadow: 0 5px 10px rgba( black, .2);

    @include md {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      position: static;
      padding-top: 0;
      box-shadow: none;

      .nav {
        &__item {
          margin-right: 25px;

          &:first-child, &:last-child {
            margin-right: 0; }

          &_button {
            margin-right: 16px;
            margin-left: 16px; } } } }

    @include lg {
      .nav {
        &__item {
          &_button {
            margin-right: 60px;
            margin-left: 60px; } } } } }

  &__burger {
    display: block;

    &.has-messages {
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #099;
        top: -3px;
        right: 1px;
        z-index: 55; } }

    @include md {
      display: none; } }

  &__user {
    position: absolute;
    right: 16px;
    display: block;
    top: -8px;

    @include md {
      position: static; } }

  &_authorized {
    .header {
      &__main-wrap {
        padding-left: 0; }

      &__logo {
        margin: 0 auto;

        @include md {
          margin: auto; } }

      &__nav {
        .nav {
          &__item {
            margin-right: 0;

            &:nth-child(2) {
              margin-right: 0; }

            @include md {
              margin-right: 30px;

              &:nth-child(2) {
                margin-right: 45px; } }

            @include lg {
              margin-right: 55px;

              &:nth-child(2) {
                margin-right: 150px; } } } } }

      &__burger {
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10; } } } }
