.non-profits {
  &__text {
    margin: 20px 0;

    @include md {
      margin: 50px 0; }

    p {
      font-size: 17px;
      margin-bottom: 15px;
      line-height: 20px;
      text-align: center;

      @include md {
        font-size: 22px;
        margin-bottom: 22px;
        line-height: 26px; }

      &:last-child {
        margin-bottom: 0; } } }

  &__consideration {
    background-color: white;
    padding: 30px;
    margin-top: 30px;

    @include md {
      margin-top: 90px; }

    .consideration {
      display: flex;
      align-items: center;

      &__image {
        min-width: 170px;
        height: 70px;
        margin-right: 20px;

        @include lg {
          min-width: 270px;
          height: 170px;
          margin-right: 40px; }

        img {
          width: 100%;
          height: auto; } }

      &__text {
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 26px; } }

      &__title {
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        margin-bottom: 15px; } } } }
