.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 4px;

  @include lg {
    padding: 10px; }

  &_input {
    flex-wrap: wrap;

    input[type="checkbox"] {
      transform: translate(0, 0);
      top: 11px; } }

  &__label {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.5;
    color: $text-dark;
    position: relative;

    br {
      content: '';
      display: block;
      margin-top: 10px; } }

  a {
    color: $green; }

  input {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    width: 20px;
    height: 20px;
    opacity: 0;
    top: 50%;
    left: 10px;
    transform: translateY(-50%); }

  &__input {
    width: 100%;
    margin-top: 10px;

    input {
      border: 1px solid $gray;
      min-height: 41px;
      min-width: 210px;
      opacity: 1;
      margin-top: 30px;
      margin-left: 30px;
      padding: 0 10px;
      font-size: 16px;
      color: $text-dark;
      border-radius: 4px;

      @include md {
        min-width: 310px; } } }

  &__ico {
    position: relative;
    display: block;
    margin-right: 11px; }

  &_common {
    input:checked {
      & + .checkbox__ico {
        border-color: $green;
        background-color: $green;

        &:before {
          width: 7px;
          transition-delay: 0ms; }

        &:after {
          width: 11px;
          transition-delay: 150ms; } } }

    .checkbox__ico {
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid $text-dark;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: white;
        bottom: 9px;
        left: 2px;
        transform: rotate(45deg);
        transform-origin: left;
        transition: all 150ms;
        transition-delay: 150ms; }

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: white;
        transform: translate(7px, 11px) rotate(-45deg);
        transform-origin: left;
        transition: all 150ms;
        transition-delay: 0ms; } } }

  &_secondary {
    padding: 0;

    .checkbox__label {
      font-size: 15px; } }

  &_primary {
    .checkbox__label {
      font-size: 15px;

      @include lg {
        font-size: 18px; } }

    .checkbox__ico {
      margin-right: 10px; }

    &__error-message {
      color: $red;
      font-size: 16px;
      margin-top: 10px; } }

  &.is-error {
    border: 1px solid $red;
    padding: 10px;
    box-shadow: inset 0 0 1px $red; } }

.checkboxes-block {

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .checkbox {
      width: 100%;

      @include md {
        width: 50%; } } }

  &_main {
    max-width: 1180px;
    margin: 0 auto 80px;
    padding-left: 5%; } }

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0,0,0) rotate(45deg); }

  33% {
    width: .2em;
    height: 0;
    transform: translate3d(0,0,0) rotate(45deg); }

  100% {
    width: .2em;
    height: .5em;
    border-color: #212121;
    transform: translate3d(0,-.5em,0) rotate(45deg); } }

.error-checkbox {
  margin-left: 12px;
  margin-top: -4px; }
