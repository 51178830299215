.pagination {
  &__numb-wrap {
    display: flex;
    justify-content: center;
    align-items: center; }

  &__amount {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.88px;
    color: $text-dark;
    margin-top: 20px;
    text-align: center; }

  &__numb {
    width: 35px;
    height: 35px;
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

    @include md {
      height: 48px;
      width: 48px; }

    a {
      color: $text-dark;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center; }

    &.is-active {
      border: 1px solid $green;
      background-color: $green;
      color: white;
      font-size: 18px;
      display: flex;
      align-items: center;
      line-height: 1;
      justify-content: center;
      a {
        color: white; } } }
  .gap {
    font-size: 24px;
    margin: 0 12px; }

  &__left, &__right {
    position: relative;
    width: 35px;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    line-height: 35px;
    text-align: center;

    a {
      display: block;
      height: 35px;
      background: transparent;
      position: relative;
      z-index: 2; }

    i {
      font-size: 10px;
      color: $text-dark;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      line-height: 35px;
      z-index: 1;
      top: 0; }

    @include md {
      height: 48px;
      width: 98px;
      line-height: 48px;

      a {
        height: 48px; }

      i {
        font-size: 13px;
        line-height: 48px; } } }

  &__left {
    margin-right: 5px;

    i {
      transform: scale(-1, 1); } }

  &__right {
    margin-left: 5px; }

  &_half {
    .pagination__numb {
      width: 35px;
      height: 35px; }

    &.is-active {
      font-size: 16px; }

    .gap {
      font-size: 18px; }

    .pagination__left, .pagination__right {
      width: 35px;
      height: 35px;
      line-height: 35px;

      a {
        height: 35px; }

      i {
        font-size: 10px;
        line-height: 35px; } } } }
