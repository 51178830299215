.datepicker-select {
  height: 38px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 33%;
    right: 12px;
    height: 7px;
    width: 7px;
    border-left: 2px solid $text-dark;
    border-bottom: 2px solid $text-dark;
    transform: rotate(-45deg) scale(1, 1) translate(0, 0);
    transform-origin: center;
    transition: .3s ease;
    z-index: 15; }

  input::placeholder {
    color: $text-dark;
    font-size: 14px; }

  &__input {
    height: 100%;
    width: 100%;
    font-size: 13px;
    border: 1px solid $gray;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 20px;
    white-space: nowrap;
    color: $text-dark;
    font-weight: 600;
    position: relative; } }


.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {

  &.booked {
    color: #4a4a4a;
    text-align: right;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background-color: #dcdcdc;
      width: 50px;
      height: 200px;
      transform: rotate(45deg);
      left: -25px;
      top: -65px; }

    &:after {
      content: 'x';
      color: white;
      font-weight: 700;
      position: absolute;
      left: 14px;
      top: 10px;
      line-height: 1; } } }
