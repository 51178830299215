.reasons-video {
  background-color: $primary;
  overflow: hidden;
  position: relative;

  &:after, &:before {
    position: absolute;
    content: '';
    width: 600px;
    height: 190px;
    background-size: contain;
    background-repeat: no-repeat; }

  &:after {
    background-image: url(../img/primary-bottom.svg);
    bottom: -50px;
    left: 0; }

  &:before {
    background-image: url(../img/primary_small.svg);
    right: -30px;
    top: 0; }

  .video {
    &:before {
      display: none; } }

  .draggable {
    min-height: 300px !important; } }
