.control-bar {
  margin-bottom: 20px;
  margin-top: -22px;

  @include lg {
    margin-top: -60px; }

  &__buttons {
    justify-content: center;

    button {
      width: 126px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border-color: $primary;
      padding: 11px 0;
      background-color: transparent;
      color: $text-dark;
      font-weight: 500;
      margin: 0 10px;
      font-size: 14px;

      i {
        color: $green;
        font-size: 16px; } } }

  input.active {
    border-color: $green;
    box-shadow: 0 0 22px -10px $green;
    animation-name: flash;
    animation-duration: 2s;
    animation-fill-mode: both; }

  @keyframes flash {
    from, 50%, to {
      opacity: 1; }
    25%,75% {
      opacity: 0; } }

  &__inner {
    background: white;
    padding: 18px 6px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 3px 3px 0 0;
    position: relative;

    @include lg {
      padding: 31px 60px; } }

  &__filters {
    display: block;
    position: absolute;
    left: 0;
    transition: 0s;
    max-height: 1px;
    width: 100%;
    background-color: white;
    padding: 0 15px;
    opacity: 0;
    visibility: hidden;
    z-index: 99;

    @include lg {
      display: flex;
      position: static;
      opacity: 1;
      visibility: visible;
      max-height: fit-content;
      padding: 0; }

    &.opened {
      max-height: 670px;
      padding: 15px;
      opacity: 1;
      visibility: visible;
      transition: .4s;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

      .select, .input {
        margin-top: 10px; }

      .select,
      .input,
      .custom-select,
      .custom-select__text {
        width: 100%; } } } }

.select-row {
  display: flex !important;
  flex-direction: column;

  @include xl {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap; }

  .input {
    &_xs {
      width: 119px; } }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    flex-wrap: wrap;

    @include xl {
      width: 55%; }

    &.opened {
      max-height: 670px;
      padding: 15px;
      border-bottom: 1px solid $gray-darker;
      opacity: 1;
      visibility: visible;
      transition: .4s;

      .select, .input {
        margin-bottom: 10px; }

      .select,
      .input,
      .custom-select,
      .custom-select__text {
        width: 100%; } } }

  &__button {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;
    padding-bottom: 20px;
    order: 3;

    input {
      width: 100%;
      margin-top: 20px; }

    @include lg {
      flex-direction: row;
      padding-bottom: 0;
      order: inherit;

      input {
        width: fit-content;
        margin-top: 0; } } }

  &__absolute {
    position: static;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 50;

    @include lg {
      flex-direction: row;
      flex-wrap: wrap;
      left: 0;
      top: 100%;
      align-items: flex-start;
      border-top: 1px solid $gray;
      position: relative;
      width: 100%;
      padding: 25px 0 0;
      box-shadow: none;
      margin-top: 25px;

      & > div {
        margin-bottom: 10px; } }

    .hidden {
      padding: 0;
      box-shadow: none;
      border-top: none; }

    .select, .input {
      margin-right: 25px; } } }
