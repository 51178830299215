.hero-list-item {
  padding: 30px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 82px;

  .btn {
    margin: 0 auto;
    display: block;
    width: 240px;

    @include lg {
      margin-left: 30px; } }

  @include lg {
    padding: 108px 0; }

  h1 {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: white;
    margin-bottom: 20px;
    text-align: center;

    @include lg {
      font-size: 44px;
      font-weight: 900;
      line-height: 58px;
      margin-bottom: 40px;
      text-align: left; } }

  .list-icons {
    margin-bottom: 25px;
    margin-left: 25px;

    @include lg {
      margin-bottom: 50px;
      margin-left: 30px; }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      @include lg {
        margin-bottom: 5px; } }

    &__icon {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-radius: 6px;
      background-color: white;
      line-height: 35px;
      text-align: center;
      margin-right: 12px;

      i {
        color: $green;
        font-size: 18px; } }

    &__text {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      color: white;

      @include lg {
        font-size: 24px;
        line-height: 45px; } } } }
