.custom-select {
  position: relative;

  select {
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }

  &__text, &__input {
    height: 38px;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    line-height: 38px;
    color: $text-dark;
    vertical-align: middle;
    padding-left: 12px;
    padding-right: 25px;
    width: 180px;
    border: 1px solid $gray-darker;
    border-radius: 4px;
    white-space: nowrap;
    background-color: white;
    overflow: hidden;

    input {
      font-size: 14px;
      color: $text-dark;
      width: 100%; }

    input::placeholder {
      color: $text-dark; } }

  &__field {
    position: relative;
    display: block;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer; }

  &__text {
    position: relative;
    text-overflow: ellipsis;

    &:before {
      content: '';
      position: absolute;
      top: 33%;
      right: 17px;
      height: 7px;
      width: 7px;
      border-left: 2px solid $text-dark;
      border-bottom: 2px solid $text-dark;
      transform: rotate(-45deg) scale(1,1) translate(0, 0);
      transform-origin: center;
      transition: .3s ease;
      z-index: 15; } }

  &__icon {
    position: absolute; }

  &__dropdown {
    position: absolute;
    top: 140%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: .25s ease;
    background-color: white;
    z-index: 80;
    max-width: 175px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid white;
      top: -9px;
      left: 12px; } }

  &__list {
    text-align: left;
    overflow: hidden;
    overflow-y: auto;
    max-height: 260px;

    &::-webkit-scrollbar {
      width: 5px; }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba($green, 0.4);
      border-radius: 10px;
      width: 5px; }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($green, 0.7); }

    li {
      font-size: 16px;
      color: $text-dark;
      font-weight: 600;
      cursor: pointer;
      padding: 7px 11px;

      &:hover, &:focus, &:active, &.is-active {
        background-color: $green;
        color: white; } } }

  &_input {
    .custom-select__input {
      padding-right: 25px; }

    .field {
      margin-bottom: 0; } }

  &.is-open {
    .custom-select__dropdown {
      visibility: visible;
      opacity: 1; }

    .custom-select__dropdown__text {
      &:before {
        transform: rotate(45deg) scale(1,-1) translate(3px, -3px); } } } }

.select {
  &.is-error {
    .custom-select {
      &__field {
        background-color: #fff8f9;
        border: 2px solid $red;
        box-shadow: none;
        overflow: hidden; } } }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
    margin-bottom: 5px; }

  &_top {
    .custom-select__dropdown {
      bottom: 100%;
      top: auto; } }

  &_lg {
    .custom-select__text {
      width: 300px; } }

  &_md {
    .custom-select__text {
      width: 200px; } }

  &_secondary {
    .custom-select__text {
      padding-left: 25px;
      font-size: 16px;
      letter-spacing: 0.88px;

      &:before {
        top: 21%;
        height: 15px;
        width: 15px;
        border-left: 2px solid $text-dark;
        border-bottom: 2px solid $text-dark; } }

    .custom-select__dropdown {
      padding-top: 4px; }

    .custom-select__list {
      text-align: left;
      li {
        padding: 5px 20px;
        letter-spacing: 0.9px; } }

    .custom-select.is-open {
      .custom-select__text {
        &:before {
          transform: rotate(45deg) scale(1,-1) translate(5px, -5px); } } }

    &_xs {
      .custom-select__text {
        width: 80px;
        padding-left: 8px;
        font-size: 16px;

        &:before {
          right: 8px; }

        .select__title {
          margin-bottom: 12px;
          font-size: 16px; } } }

    &_xxs {
      .custom-select__text {
        width: 85px;
        padding: 0 24px 0 8px; } }

    &_width-md {
      .custom-select__text {
        width: 173px; } }

    &_full {
      .custom-select__text {
        width: 100%; }

      .custom-select__input {
        width: 100%; } }
    &_mb {
      margin-bottom: 25px; } }

  &_center-holder {
    .custom-select__text {
      text-align: left; }

    &_arrow-bg {
      .custom-select__text {
        &:before {
          top: 26%;
          right: 12px;
          height: 14px;
          width: 14px;
          border-left: 4px solid #4a4a4a;
          border-bottom: 4px solid #4a4a4a; } } } } }

.select-column {
  &:not(:last-child) {
    margin-bottom: 25px; }

  .select {
    &:not(:last-child) {
      margin-bottom: 15px; } } }

.select-tabs {
  &__title {
    color: $text-dark;
    letter-spacing: 1.71px; }

  .custom-select {
    display: flex;

    &__list {
      display: flex;
      align-items: flex-start;
      border: 1px solid $gray-darker;
      background-color: white;
      overflow: hidden;
      width: 100%;

      li {
        background-color: white;
        font-size: 12px;
        color: $text-dark;
        letter-spacing: 0.81px;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        flex: 1;

        &:not(:last-of-type) {
          border-right: 1px solid $gray-darker;
          border-bottom: none; }

        &.is-active {
          background-color: $green;
          color: white; } } } } }
