.content-wrapper {
  width: 100%; }

.no-results {
  text-align: center;

  a {
    color: $green; }

  &__btn {
    margin-top: 50px; }

  &__info {
    text-align: center;
    margin-top: 20px;

    p {
      color: $text-dark;
      font-size: 18px;
      font-weight: 400;

      &:not(:last-of-type) {
        margin-bottom: 25px; } } }

  &__back-link {
    text-align: center;
    margin-top: 40px; }

  a {
    font-weight: 400;
    text-decoration: none;
    color: $green;
    font-size: 20px;

    &:hover {
      text-decoration: underline; } } }
