.textarea {
  position: relative;

  .error-text {
    color: $red;
    margin-top: 4px; }

  textarea {
    width: 100%;
    min-height: 100px;
    resize: none;
    border-radius: 4px;
    border: 1px solid $gray;
    padding: 7px 15px;
    background-color: white; }

  textarea::placeholder {
    color: #979797;
    font-weight: 400;
    font-size: 15px; }

  &__counter {
    position: absolute;
    bottom: 7px;
    right: 7px;
    font-size: 12px;
    color: $gray-dark;
    font-weight: 400; }

  &__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 5px;

    @include md {
      font-size: 18px; } } }
