.nav {
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include md {
      flex-direction: row;
      justify-content: flex-end; } }

  &__item {
    border: 2px solid transparent;
    position: relative;
    margin-bottom: 5px;
    padding: 0;
    cursor: pointer;

    &.has-messages {
      span {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: #099;
          top: 0;
          right: -8px;

          @include md {
            width: 10px;
            height: 10px; } } } }

    @include md {
      padding: 20px 0;
      margin-bottom: 0; }

    > a {
      color: $text-dark;
      font-size: 15px;
      font-weight: 700;
      line-height: 25px;
      transition: color 0.3s ease;

      @include md {
        font-size: 18px; } }

    &:hover {
      > a {
        color: $green; } }

    &_sublist {
      > a {
        color: $green;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          right: -17px;
          top: 4px;
          border: solid $green;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          transition: transform 0.3s ease; } }

      &:hover {
        a {
          &:after {
            transform: rotate(-135deg);
            top: 6px; } }

        .dropdown {
          opacity: 1;
          visibility: visible;
          pointer-events: all; } }

      @include md {
        > a {
          &:after {
            top: 7px; } }

        &:hover {
          a {
            &:after {
              top: 9px; } } } } }

    &_button {
      @include md {
        padding: 10px 30px;
        border-color: $green;
        transition: background-color 0.3s ease;
        border-radius: 6px;

        &:hover {
          background-color: $green;

          a {
            color: white; } }

        a {
          color: $green;
          transition: color 0.3s ease; } } } } }
