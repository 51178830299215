.booking {

  &__content {
    &_checkout {
      padding-bottom: 20px;
      margin-bottom: 20px;

      @include lg {
        padding-bottom: 30px;
        margin-bottom: 30px; }

      .custom-select__field {
        width: fit-content; } } }

  &__name {
    &_checkout {
      margin-bottom: 12px; } }

  &__subtitle {
    &_checkout {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      margin-bottom: 12px; } }

  &__id {
    &_checkout {
      font-size: 18px;
      font-weight: 600; } }

  &__information {
    padding-top: 20px;
    padding-bottom: 50px;
    font-size: 14px;
    line-height: 19px;
    color: #999999; }

  &__payment {
    .payment {
      margin-left: -15px;
      width: calc(100% + 30px);

      @include lg {
        width: 100%; }

      &__title {}
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 15px;

        @include lg {
          margin-bottom: 15px; } }

      .field {
        width: 100%;

        &__input {
          width: 100%; } } } } }

.checkout-form {
  .select {
    margin-bottom: 20px;

    .custom-select__text, .custom-select__input {
      width: 225px; } }

  .field {
    width: 200px;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    &__desc {
      font-size: 16px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        color: $green; } }

    &__input {
      width: 140px; }

    &__note {
      font-size: 16px;
      line-height: 22px;
      color: #999999;
      margin-top: 0; }

    &__flex {
      width: 285px;
      justify-content: space-between; } }

  .user-description {
    &__head {
      width: 100%;
      background-color: transparent;
      padding: 0;
      margin: 0; }

    &__image {
      width: 49px;
      height: 49px;

      @include lg {
        width: 80px;
        height: 80px;
        min-width: 80px; } }

    p {
      margin-top: 10px;

      @include lg {
        margin-left: 100px; } }

    &__list {
      margin-top: 10px;
      margin-left: 15px;

      @include lg {
        margin-left: 110px;
        margin-top: 30px; }

      li {
        font-weight: 500;
        font-size: 14px;

        @include lg {
          font-size: 16px; } } }

    &__textarea {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 0;

      @include lg {
        width: calc(100% - 100px);
        margin-left: 100px;
        margin-top: 30px;
        margin-bottom: 0; } } } }


.booking-request {
  &_checkout {
    margin-top: -30px;

    @include md {
      margin: 0;
      width: 100%; }

    .booking-request {
      &__content {
        border-radius: 0;
        background-color: $primary;
        padding-bottom: 20px;

        @include lg {
          border-radius: 6px; }

        p, h3 {
          color: white;
          font-size: 20px; } }

      &__summary {
        margin: 0; }

      .cost-summ__total {
        border-top-color: #79A8D7; } } } }

.timetable {
  max-width: 350px;
  margin: 0 auto;

  table {
    border-radius: 6px;
    border: 1px solid $primary;
    overflow: hidden;
    text-align: center;
    border-collapse: separate;
    margin-bottom: 15px; }

  tr:not(:last-child) td {
    border-bottom: 1px solid $primary; }

  thead {
    background-color: rgba($primary, 0.8); }

  th, td {
    color: $text-dark;
    font-weight: 400;
    font-size: 14px;

    @include lg {
      font-size: 16px; } }

  th {
    border-bottom: 1px solid $primary;
    border-left: 1px solid white;
    padding: 7px;
    color: white; }

  td {
    padding: 10px 5px;
    border-left: 1px solid $primary; }

  td:first-child, th:first-child {
    border-left: none; }

  &__edit {
    font-size: 16px;
    margin-top: 10px; } }

.payment-info {
  max-width: 450px;

  &__row {
    display: flex; }

  &__cell {
    flex: 1;
    display: flex;
    align-items: center;

    i {
      color: $green;
      margin-right: 5px; }

    &:last-child {
      margin-bottom: 0; }

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 36px;

      @include md {
        font-size: 18px; } } } }
