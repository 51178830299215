.residence-cards {
  @include lg {
    margin-right: -17px; } }

.residence-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: left;
  box-shadow: none;
  transform: translateY(0);
  transition: box-shadow .35s ease;
  width: 100%;
  border-radius: 6px;
  background-color: white;

  @include lg {
    width: calc(100%/3 - 17px);
    margin-right: 17px;

    &:nth-child(3n+3) {
      margin-right: 0; } }

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }

  &__title {
    margin-bottom: 5px;
    font-size: 16px;

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $text-dark; } }

  &__inner {
    text-decoration: none;
    display: block;
    overflow: hidden;
    position: relative; }

  &__discount {
    width: 220px;
    height: 50px;
    position: absolute;
    top: 14px;
    left: -69px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $green;
    font-size: 16px;
    font-weight: 700;
    color: white;
    line-height: 1;
    transform: rotate(-45deg);
    text-shadow: 1px 1px rgba(black, .5); }

  &__images {
    width: 100%;
    position: relative;
    border-radius: 6px 6px 0 0; }

  &__image {
    width: 100%;
    height: 235px;
    position: relative;
    border-radius: 6px 6px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0; } }

  &__description {
    background-color: white;
    padding: 11px 17px 17px;
    border-radius: 0 0 6px 6px; }

  &__price {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $primary; }

  &__subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
    color: #999; }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__like-btn {
    position: absolute;
    display: block;
    top: 12px;
    right: 13px;
    font-size: 17px;
    z-index: 15;
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px; }

  .slider-arrows {
    .next, .prev {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: white;
      border: 1px solid $green;
      line-height: 33px;
      top: 35%;

      svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-height: 15px;
        stroke: none; } }

    .next {
      right: 10px;
      transform: translate3d(0, 0, 0) translateY(-50%); }

    .prev {
      left: 10px;
      transform: translate3d(0, 0, 0) translateY(-50%) scale(-1,1); } } }

.infoBox {
  min-width: 250px;
  width: 250px;
  margin-left: 25px;
  border-radius: 6px;

  @include lg {
    min-width: 317px;
    width: 317px; }

  .residence-card__image, .search-map_half-map .residence-card__image {
    height: 206px!important; }

  & > img {
    position: absolute !important;
    right: -18px;
    top: -18px;
    z-index: 1000; } }
