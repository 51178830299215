.steps {
  margin-bottom: 50px; }

.step {
  width: 100%;
  padding: 12px;
  background-color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  margin-bottom: 25px;

  @include lg {
    margin-bottom: 0; }

  &_md {
    padding-bottom: 90px; }

  &_lg {
    padding-bottom: 20px; }

  &__top {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background: linear-gradient(197.87deg, #99CCCC 40.5%, #009999 96.44%);
    text-align: center;
    position: relative;
    margin: -95px auto 0; }

  &__icon {
    position: absolute;
    bottom: 25px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);

    i {
      font-size: 52px;
      color: white; } }

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    color: $text-dark;
    margin: 10px 0; }

  &__description {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px; }

  &__bg {
    text-align: center;

    @include lg {
      background-image: url(../img/bgicon.svg);
      min-height: 170px;
      min-width: 185px;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 25px;
      display: flex;
      position: relative; }

    span {
      color: $green;
      font-size: 30px;
      line-height: 46px;
      font-weight: 700;
      position: static;

      @include lg {
        position: absolute;
        top: 30px; } } } }
