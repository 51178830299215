.notification {
  &__wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto; }

  &__modal {
    color: white;
    font-size: 10px;
    min-height: 40px;
    background: $green;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
    padding: 7px 20px;
    margin-bottom: 15px;
    transition: 0.3s;

    @include sm {
      font-size: 12px; }

    @include md {
      max-width: 720px;
      width: 720px;
      font-size: 18px; }

    &.error,
    &.error .info-modal {
      background-color: $red;
      color: white; }

    &.info,
    &.info .info-modal {
      background-color: #fcf8e3;
      color: $text-dark;

      .info-modal__close {
        &:before, &:after {
          background-color: $text-dark; } } } } }

