/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*, ::after, ::before {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

/*# sourceMappingURL=bootstrap-grid.min.css.map */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: inline-block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  font-size: 14px;
  line-height: 24px;
  border-radius: 6px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  font-family: "NunitoSans", sans-serif; }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid rgba(72, 72, 72, 0.2); }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  border-bottom: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid rgba(72, 72, 72, 0.2); }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72, 72, 72, 0.2); }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: white; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72, 72, 72, 0.2); }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: white; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px; }

.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: white;
  color: #33669A;
  fill: #33669A;
  height: 44px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 44px;
  padding: 10px;
  z-index: 3;
  color: #4B4B4B;
  fill: #4B4B4B; }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #bbb; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 17px;
  height: 17px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.3); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.35); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1;
  background: rgba(0, 0, 0, 0.25); }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 500;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 3px 0 0 0;
  line-height: 1;
  height: 30px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: "NunitoSans", sans-serif;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 7ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff; }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff; }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: "NunitoSans", sans-serif;
  font-weight: 500;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: white;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  font-family: "NunitoSans", sans-serif;
  font-weight: 500;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  display: none; }

.flatpickr-weekdays {
  background: white;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: white;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  padding: 0 5px 5px; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2);
  box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2); }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin: 1.5px 0; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2; }

.flatpickr-day.today {
  border-color: #bbb; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #4B4B4B;
  border-color: #009999; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #009999;
  box-shadow: -10px 0 0 #009999; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(72, 72, 72, 0.35);
  background-color: rgba(72, 72, 72, 0.05); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #009999, 5px 0 0 #009999;
  box-shadow: -5px 0 0 #009999, 5px 0 0 #009999; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid rgba(72, 72, 72, 0.2); }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 6px 6px; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eaeaea; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

img, fieldset, a img {
  border: none; }

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none; }

input[type="submit"],
button {
  cursor: pointer; }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0; }

textarea {
  overflow: auto; }

input, button {
  margin: 0;
  padding: 0;
  border: 0; }

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none; }

ul, ol {
  list-style-type: none; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important; } }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-Regular"), url("../redesign-fonts/NunitoSans-Regular/NunitoSans-Regular.woff") format("woff");
  src: local("NunitoSans-Regular"), url("../redesign-fonts/NunitoSans-Regular/NunitoSans-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-SemiBold"), url("../redesign-fonts/NunitoSans-SemiBold/NunitoSans-SemiBold.woff") format("woff");
  src: local("NunitoSans-SemiBold"), url("../redesign-fonts/NunitoSans-SemiBold/NunitoSans-SemiBold.woff2") format("woff2");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-Light"), url("../redesign-fonts/NunitoSans-Light/NunitoSans-Light.woff") format("woff");
  src: local("NunitoSans-Light"), url("../redesign-fonts/NunitoSans-Light/NunitoSans-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-LightItalic"), url("../redesign-fonts/NunitoSans-LightItalic/NunitoSans-LightItalic.woff") format("woff");
  src: local("NunitoSans-LightItalic"), url("../redesign-fonts/NunitoSans-LightItalic/NunitoSans-LightItalic.woff2") format("woff2");
  font-style: italic;
  font-weight: 300; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-Italic"), url("../redesign-fonts/NunitoSans-Italic/NunitoSans-Italic.woff") format("woff");
  src: local("NunitoSans-Italic"), url("../redesign-fonts/NunitoSans-Italic/NunitoSans-Italic.woff2") format("woff2");
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-ExtraLightItalic"), url("../redesign-fonts/NunitoSans-ExtraLightItalic/NunitoSans-ExtraLightItalic.woff") format("woff");
  src: local("NunitoSans-ExtraLightItalic"), url("../redesign-fonts/NunitoSans-ExtraLightItalic/NunitoSans-ExtraLightItalic.woff2") format("woff2");
  font-style: italic;
  font-weight: 200; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-ExtraLight"), url("../redesign-fonts/NunitoSans-ExtraLight/NunitoSans-ExtraLight.woff") format("woff");
  src: local("NunitoSans-ExtraLight"), url("../redesign-fonts/NunitoSans-ExtraLight/NunitoSans-ExtraLight.woff2") format("woff2");
  font-style: normal;
  font-weight: 200; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-ExtraBold"), url("../redesign-fonts/NunitoSans-ExtraBold/NunitoSans-ExtraBold.woff") format("woff");
  src: local("NunitoSans-ExtraBold"), url("../redesign-fonts/NunitoSans-ExtraBold/NunitoSans-ExtraBold.woff2") format("woff2");
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-Bold"), url("../redesign-fonts/NunitoSans-Bold/NunitoSans-Bold.woff") format("woff");
  src: local("NunitoSans-Bold"), url("../redesign-fonts/NunitoSans-Bold/NunitoSans-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans-Black"), url("../redesign-fonts/NunitoSans-Black/NunitoSans-Black.woff") format("woff");
  src: local("NunitoSans-Black"), url("../redesign-fonts/NunitoSans-Black/NunitoSans-Black.woff2") format("woff2");
  font-style: normal;
  font-weight: 900; }

h1, h2, h3, h4, h5, .heading {
  display: block;
  padding: 0;
  margin: 0;
  font-family: "NunitoSans", sans-serif;
  font-weight: 700;
  line-height: 1.43;
  color: #4b4b4b; }

h2 {
  font-size: 20px; }
  @media only screen and (min-width: 576px) {
    h2 {
      font-size: 24px; } }
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 34px; } }
  @media only screen and (min-width: 992px) {
    h2 {
      font-size: 48px; } }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 56px; } }

a {
  cursor: pointer;
  text-decoration: none; }

img {
  max-width: 100%; }

ul {
  list-style-type: none; }

input {
  font-family: "NunitoSans", sans-serif; }

input::placeholder {
  color: #d8dde6;
  font-family: "NunitoSans", sans-serif;
  font-size: 18px; }

p {
  margin-bottom: 0; }

.color-green {
  color: #009999; }

.link {
  color: #009999; }

.title {
  font-size: 24px;
  line-height: 21px;
  margin-bottom: 15px; }
  @media only screen and (min-width: 768px) {
    .title {
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 21px; } }
  .title_lg {
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .title_lg {
        font-size: 48px;
        line-height: 61px; } }
  .title_white {
    color: white; }

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .subtitle {
      font-size: 18px; } }
  .subtitle_white {
    color: white; }
  .subtitle_italic {
    font-style: italic; }

.list {
  position: relative;
  padding: 23px 33px;
  background-color: white;
  border-radius: 6px;
  z-index: 3; }
  .list__title {
    color: #4b4b4b;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    margin-bottom: 7px; }
  .list li {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px; }

body {
  background-color: white;
  line-height: 1.43;
  color: #4b4b4b;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  font-family: "NunitoSans", sans-serif;
  overflow-x: hidden; }
  body.opened {
    overflow: hidden; }

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px; }
  @media only screen and (min-width: 992px) {
    main {
      margin-bottom: 85px; } }

.hidden {
  display: none !important; }

ul {
  margin: 0;
  padding: 0; }

a {
  transition: all 0.4s ease-in-out;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.text-center {
  text-align: center !important; }

.out {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #fafafc; }

.main {
  margin-top: 92px; }
  .main_checkout {
    margin-top: 180px; }
  .main_checkout_small {
    margin-top: 130px;
    padding-bottom: 70px; }

.mt-default {
  margin-top: 30px !important; }

.mb-default {
  margin-bottom: 30px; }

.info-modal__close {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer; }
  .info-modal__close:before, .info-modal__close:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4b4b4b; }
  .info-modal__close:before {
    transform: translateY(-50%) rotate(45deg); }
  .info-modal__close:after {
    transform: translateY(-50%) rotate(-45deg); }

.content-wrap {
  border-radius: 6px;
  position: relative;
  z-index: 1;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05); }
  @media only screen and (min-width: 768px) {
    .content-wrap:before, .content-wrap:after {
      content: '';
      position: absolute;
      background-image: url(../img/secondary.svg);
      background-repeat: no-repeat;
      width: 115px;
      height: 115px;
      z-index: -1; }
    .content-wrap:before {
      left: -25px;
      bottom: -25px; }
    .content-wrap:after {
      right: -25px;
      top: -25px; } }
  .content-wrap_white:before, .content-wrap_white:after {
    background-image: url(../img/secondary_white.svg); }

.map, .map-wrap, .map-wrap__inner {
  width: 100%;
  height: 100%; }

.like-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #4b4b4b; }
  .like-btn.is-active .icon-like:before {
    content: "\e921";
    color: #ff0000; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.pos-sticky {
  position: sticky;
  top: -160px;
  height: fit-content; }
  @media (min-height: 720px) {
    .pos-sticky {
      top: 70px; } }
  .pos-sticky_top-big {
    top: 150px; }
    @media (min-height: 720px) {
      .pos-sticky_top-big {
        top: 150px; } }

.read-more {
  position: relative;
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  text-decoration: none;
  padding-right: 40px;
  color: #009999; }
  .read-more:before, .read-more:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    background-color: #009999;
    width: 10px;
    height: 1px; }
  .read-more.is-open:after {
    transform: translateX(4px) rotate(40deg); }
  .read-more.is-open:before {
    transform: translateX(-4px) rotate(-40deg); }
  .read-more:before {
    transform: translateX(-4px) rotate(40deg); }
  .read-more:after {
    transform: translateX(4px) rotate(-40deg); }

.read-more-text {
  max-height: 20px;
  overflow: hidden; }
  .read-more-text_lg {
    max-height: 125px; }
  .read-more-text.is-open {
    max-height: none; }

.copy-link {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 17px;
  display: none; }
  .copy-link.is-open {
    display: flex;
    flex-direction: column; }
  .copy-link__btn {
    font-size: 16px;
    text-decoration: none;
    color: #009999;
    display: block; }
    .copy-link__btn:hover {
      text-decoration: underline; }
  .copy-link__text {
    font-size: 12px;
    letter-spacing: 0.81px;
    color: #9b9b9b; }
  .copy-link__message {
    display: none;
    text-align: center;
    color: #009999;
    margin-top: 10px; }

.section-wrapper {
  position: relative;
  padding: 50px 0; }
  @media only screen and (min-width: 992px) {
    .section-wrapper {
      padding: 100px 0; } }

.info-modal {
  width: 100%;
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 25px;
  text-align: center;
  background-color: #009999; }
  @media only screen and (min-width: 992px) {
    .info-modal {
      padding: 0 35px;
      min-height: 60px; } }
  .info-modal [class^="icon-"], .info-modal [class*=" icon-"] {
    margin: 0 10px;
    color: #4b4b4b;
    font-size: 18px; }
  .info-modal__close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer; }
    @media only screen and (min-width: 992px) {
      .info-modal__close {
        width: 24px;
        height: 24px; } }
    .info-modal__close:before, .info-modal__close:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: white; }
    .info-modal__close:before {
      transform: translateY(-50%) rotate(45deg); }
    .info-modal__close:after {
      transform: translateY(-50%) rotate(-45deg); }
  .info-modal_fixed {
    position: fixed;
    top: 90px;
    height: 50px;
    min-height: 50px;
    font-size: 14px;
    z-index: 999;
    transition: .3s; }
    @media only screen and (min-width: 992px) {
      .info-modal_fixed {
        top: 90px;
        font-size: 18px; }
        .info-modal_fixed span {
          display: none; } }
  .info-modal.warning {
    background-color: #ff0000;
    color: white; }
    .info-modal.warning [class^="icon-"]:before {
      color: white; }

.error, .error-text, .payment__error-message {
  color: #ff0000;
  font-size: 14px;
  margin: 4px 0 0;
  display: block; }
  @media only screen and (min-width: 992px) {
    .error, .error-text, .payment__error-message {
      font-size: 16px; } }

.bb-default {
  border-bottom: 1px solid #009999;
  padding-bottom: 1px;
  width: fit-content; }

@font-face {
  font-family: 'icomoon';
  src: url("../redesign-fonts/icomoon.eot?gwitnp");
  src: url("../redesign-fonts/icomoon.eot?gwitnp#iefix") format("embedded-opentype"), url("../redesign-fonts/icomoon.ttf?gwitnp") format("truetype"), url("../redesign-fonts/icomoon.woff?gwitnp") format("woff"), url("../redesign-fonts/icomoon.svg?gwitnp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon {
  display: inline-block;
  vertical-align: middle; }
  .icon-sm {
    font-size: 16px; }
  .icon-md {
    font-size: 20px; }
  .icon-lg {
    font-size: 24px; }

.icon-filter:before {
  content: "\e900"; }

.icon-listing:before {
  content: "\e901"; }

.icon-profit:before {
  content: "\e902"; }

.icon-non-profit:before {
  content: "\e903"; }

.icon-medical:before {
  content: "\e904"; }

.icon-money:before {
  content: "\e905"; }

.icon-dog:before {
  content: "\e906"; }

.icon-charity:before {
  content: "\e907"; }

.icon-eco:before {
  content: "\e908"; }

.icon-list:before {
  content: "\e909"; }

.icon-earth:before {
  content: "\e90a"; }

.icon-save-money:before {
  content: "\e90b"; }

.icon-make-money:before {
  content: "\e90c"; }

.icon-map:before {
  content: "\e90d"; }

.icon-youtube:before {
  content: "\e90e"; }

.icon-mail:before {
  content: "\e90f"; }

.icon-instagram:before {
  content: "\e910"; }

.icon-coins:before {
  content: "\e911"; }

.icon-twitter:before {
  content: "\e912"; }

.icon-facebook:before {
  content: "\e913"; }

.icon-event:before {
  content: "\e914"; }

.icon-item:before {
  content: "\e915"; }

.icon-service:before {
  content: "\e916"; }

.icon-support:before {
  content: "\e917"; }

.icon-space:before {
  content: "\e918"; }

.icon-world:before {
  content: "\e919"; }

.icon-search:before {
  content: "\e91a"; }

.icon-user:before {
  content: "\e91b"; }

.icon-email:before {
  content: "\e91d"; }

.icon-phone:before {
  content: "\e91c"; }

.icon-warning:before {
  content: "\e91e"; }

.icon-arrow:before {
  content: "\e91f"; }

.icon-like:before {
  content: "\e920"; }

.icon-share:before {
  content: "\e923"; }

.icon-listing-id:before {
  content: "\e922"; }

.icon-benefiting:before {
  content: "\e91d"; }

.icon-team:before {
  content: "\e924"; }

.icon-share:before {
  content: "\e923"; }

.icon-listing-id:before {
  content: "\e922"; }

.icon-benefiting:before {
  content: "\e91d"; }

.icon-team:before {
  content: "\e924"; }

.icon-fb-share:before {
  content: "\e925"; }

.icon-twitter-share:before {
  content: "\e928"; }

.icon-link-share:before {
  content: "\e927"; }

.icon-mail-share:before {
  content: "\e926"; }

.icon-check:before {
  content: "\e929"; }

.icon-reset:before {
  content: "\e92a"; }

.icon-clock:before {
  content: "\e92b"; }

.icon-card:before {
  content: "\e92c"; }

.header {
  transition: all 0.4s ease-in-out;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 1050;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); }
  .header .start-listing-btn {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0; }
    .header .start-listing-btn .btn {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      width: 0;
      transition: background-color 0.2s;
      background: transparent;
      height: 0; }
  .header.is-fixed.start-listing .nav__item {
    display: none !important; }
  .header.is-fixed.start-listing .start-listing-btn {
    width: fit-content;
    display: block !important;
    margin-right: 0;
    height: fit-content; }
    .header.is-fixed.start-listing .start-listing-btn .btn {
      opacity: 1;
      visibility: inherit;
      pointer-events: inherit;
      width: fit-content;
      background: #009999;
      color: white;
      height: 43px; }
  .header__inner {
    width: 100%;
    position: relative; }
  .header__main-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px; }
    .header__main-wrap .logo {
      width: 120px;
      height: auto; }
    @media only screen and (min-width: 768px) {
      .header__main-wrap {
        width: auto;
        display: inline-block;
        padding-left: 0; }
        .header__main-wrap .logo {
          width: 177px; } }
  .header__nav {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    padding-top: 20px;
    width: 100%;
    flex: 1;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
    @media only screen and (min-width: 768px) {
      .header__nav {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        position: static;
        padding-top: 0;
        box-shadow: none; }
        .header__nav .nav__item {
          margin-right: 25px; }
          .header__nav .nav__item:first-child, .header__nav .nav__item:last-child {
            margin-right: 0; }
          .header__nav .nav__item_button {
            margin-right: 16px;
            margin-left: 16px; } }
    @media only screen and (min-width: 992px) {
      .header__nav .nav__item_button {
        margin-right: 60px;
        margin-left: 60px; } }
  .header__burger {
    display: block; }
    .header__burger.has-messages:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #099;
      top: -3px;
      right: 1px;
      z-index: 55; }
    @media only screen and (min-width: 768px) {
      .header__burger {
        display: none; } }
  .header__user {
    position: absolute;
    right: 16px;
    display: block;
    top: -8px; }
    @media only screen and (min-width: 768px) {
      .header__user {
        position: static; } }
  .header_authorized .header__main-wrap {
    padding-left: 0; }
  .header_authorized .header__logo {
    margin: 0 auto; }
    @media only screen and (min-width: 768px) {
      .header_authorized .header__logo {
        margin: auto; } }
  .header_authorized .header__nav .nav__item {
    margin-right: 0; }
    .header_authorized .header__nav .nav__item:nth-child(2) {
      margin-right: 0; }
    @media only screen and (min-width: 768px) {
      .header_authorized .header__nav .nav__item {
        margin-right: 30px; }
        .header_authorized .header__nav .nav__item:nth-child(2) {
          margin-right: 45px; } }
    @media only screen and (min-width: 992px) {
      .header_authorized .header__nav .nav__item {
        margin-right: 55px; }
        .header_authorized .header__nav .nav__item:nth-child(2) {
          margin-right: 150px; } }
  .header_authorized .header__burger {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10; }

.footer {
  width: 100%;
  background-color: #33669a;
  position: relative;
  margin-top: auto;
  padding: 35px 0 15px;
  background-image: url(../img/primary.svg);
  background-repeat: no-repeat;
  background-position: right 0; }
  @media only screen and (min-width: 992px) {
    .footer {
      padding: 55px 0 25px; } }
  .footer .error-text {
    margin-top: 10px;
    color: #f7a6ac; }
  .footer__conf-msg {
    color: #009999; }
  .footer li {
    margin-bottom: 12px; }
  .footer .logo {
    display: none; }
    @media only screen and (min-width: 992px) {
      .footer .logo {
        width: 220px;
        height: auto;
        display: block; } }
    .footer .logo .logo-name, .footer .logo .logo-subname, .footer .logo .logo-tm {
      fill: white;
      stroke: white; }
  .footer__form {
    margin-top: 35px;
    margin-bottom: 35px; }
    @media only screen and (min-width: 768px) {
      .footer__form {
        margin-top: 0; } }
    @media only screen and (min-width: 992px) {
      .footer__form {
        margin-top: 35px;
        margin-bottom: 0; } }
  .footer__text {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 12px; }
    @media only screen and (min-width: 992px) {
      .footer__text {
        text-align: left; } }
  .footer__link {
    color: white;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    line-height: 24px;
    position: relative; }
    .footer__link:after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      left: 0;
      bottom: -3px;
      background-color: white;
      transition: width 0.3s ease; }
    .footer__link:hover:after {
      width: 100%; }
    .footer__link_center {
      text-align: center;
      display: block; }
      @media only screen and (min-width: 992px) {
        .footer__link_center {
          display: inline-block;
          text-align: left; } }
  .footer__social .social {
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    margin: 0 auto; }
    .footer__social .social__link {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease-in-out; }
      .footer__social .social__link:hover {
        background-color: #009999; }
        .footer__social .social__link:hover i {
          color: white; }
      .footer__social .social__link i {
        font-size: 14px;
        color: #009999;
        transition: all 0.4s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .footer__social .social {
        max-width: 50%; } }
    @media only screen and (min-width: 992px) {
      .footer__social .social {
        max-width: 100%;
        margin: 0; } }
  .footer__copy {
    padding-top: 15px;
    border-top: 1px solid #78b0dd;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-top: 30px; }
    @media only screen and (min-width: 992px) {
      .footer__copy {
        padding-top: 25px; } }
  .footer__conf-msg {
    color: white;
    font-size: 14px;
    text-align: left; }
  .footer__list {
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .footer__list {
        width: fit-content;
        margin-left: auto; } }
  .footer__field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    @media only screen and (min-width: 768px) {
      .footer__field {
        width: 70%;
        margin: 0 auto; } }
    @media only screen and (min-width: 992px) {
      .footer__field {
        width: 100%;
        flex-direction: row; } }
    .footer__field input {
      background: white;
      border: 1px solid #D8DDE6;
      border-radius: 6px;
      min-height: 43px;
      padding: 11px 18px;
      flex: 1;
      margin-bottom: 15px; }
      @media only screen and (min-width: 992px) {
        .footer__field input {
          margin-bottom: 0;
          margin-right: 10px; } }

.caption {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #33669a;
  position: relative;
  overflow: hidden;
  padding: 25px 10px; }
  .caption:before, .caption:after {
    content: '';
    position: absolute;
    height: 100px;
    min-width: 140px;
    width: 140px;
    background-repeat: no-repeat; }
  .caption:after {
    background-image: url(../img/primary-desktop.svg);
    right: -50px;
    top: -15px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1; }
  @media only screen and (min-width: 992px) {
    .caption {
      padding: 35px 0; }
      .caption:before, .caption:after {
        height: 280px;
        min-width: 330px;
        width: 330px;
        top: -100px; }
      .caption:before {
        background-image: url(../img/primary-left.svg);
        left: 0;
        top: -100px; }
      .caption .after {
        right: 0; } }
  .caption_big {
    padding-bottom: 45px; }
    @media only screen and (min-width: 992px) {
      .caption_big {
        padding-bottom: 85px; } }
  .caption__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: white;
    margin-bottom: 5px;
    position: relative;
    z-index: 2; }

.legal-page {
  padding: 24px 0 50px;
  font-family: "NunitoSans", sans-serif;
  background-color: #fafafc; }
  @media only screen and (min-width: 768px) {
    .legal-page {
      padding: 40px 0 70px; } }
  .legal-page h2, .legal-page h3, .legal-page h4, .legal-page h5 {
    color: #4b4b4b;
    text-align: center;
    font-weight: 700; }
  .legal-page h2 {
    font-size: 26px;
    padding: 15px 0; }
    @media only screen and (min-width: 768px) {
      .legal-page h2 {
        font-size: 36px;
        padding: 25px 0; } }
  .legal-page h3 {
    font-size: 20px;
    text-transform: uppercase;
    padding: 15px 0; }
    @media only screen and (min-width: 768px) {
      .legal-page h3 {
        font-size: 24px;
        padding: 25px 30px; } }
  .legal-page h5 {
    font-size: 16px;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .legal-page h5 {
        font-size: 18px;
        margin-bottom: 30px; } }
  .legal-page p {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 400;
    color: #4b4b4b; }
    @media only screen and (min-width: 768px) {
      .legal-page p {
        font-size: 16px;
        margin-bottom: 20px; } }
  .legal-page a {
    color: #009999;
    font-weight: 600; }
  .legal-page .btn {
    color: white;
    font-weight: 700; }
  .legal-page ul {
    list-style: none; }
    .legal-page ul li {
      position: relative;
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 3px;
      font-weight: 400;
      color: #4b4b4b; }
      @media only screen and (min-width: 768px) {
        .legal-page ul li {
          font-size: 16px; } }
      .legal-page ul li:before {
        content: '';
        font-weight: bold;
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: #009999;
        border-radius: 50%;
        margin-right: 7px;
        top: 50%;
        transform: translateY(-50%); }
  .legal-page ol {
    list-style: decimal;
    margin-left: 18px; }
    .legal-page ol li {
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 3px;
      font-weight: 400;
      color: #4b4b4b; }
      @media only screen and (min-width: 768px) {
        .legal-page ol li {
          font-size: 16px; } }

.about-page strong {
  font-size: 16px;
  color: #009999; }
  @media only screen and (min-width: 768px) {
    .about-page strong {
      font-size: 18px; } }

.about-page__icons .icons-about {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 100%;
  margin: 30px auto; }
  @media only screen and (min-width: 768px) {
    .about-page__icons .icons-about {
      flex-direction: row;
      width: 80%; } }
  .about-page__icons .icons-about__square {
    background: #f6f9fd;
    box-shadow: 0px 2px 20px rgba(23, 128, 224, 0.15);
    border-radius: 15px;
    transform: rotate(45deg);
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .about-page__icons .icons-about__square i {
      position: relative;
      transform: rotate(-45deg);
      font-size: 20px;
      color: #009999; }
      .about-page__icons .icons-about__square i:after {
        content: '';
        width: 28px;
        height: 28px;
        border-radius: 11px;
        background: linear-gradient(0deg, white -51.32%, #DAE9F9 99.16%);
        position: absolute;
        z-index: -1;
        left: 0;
        top: -2px; }
      @media only screen and (min-width: 768px) {
        .about-page__icons .icons-about__square i {
          font-size: 25px; }
          .about-page__icons .icons-about__square i:after {
            width: 48px;
            height: 48px;
            left: -5px;
            top: -10px; } }
    @media only screen and (min-width: 992px) {
      .about-page__icons .icons-about__square {
        width: 83px;
        height: 83px; } }
  .about-page__icons .icons-about__row {
    display: flex;
    justify-content: space-around;
    flex: 1;
    margin-bottom: 35px; }
    .about-page__icons .icons-about__row:last-child {
      margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .about-page__icons .icons-about__row {
        margin-bottom: 0; } }

.feedback__forrm {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px; }

.contact-us-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px; }
  .contact-us-page h3 {
    text-align: center;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    margin: 0 auto 40px;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .contact-us-page h3 {
        width: 70%;
        margin-bottom: 80px;
        font-size: 30px; } }
    .contact-us-page h3 a {
      color: #009999;
      font-weight: 700; }
  .contact-us-page__cols {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1; }
    @media only screen and (min-width: 992px) {
      .contact-us-page__cols {
        max-width: 66%;
        flex-direction: row;
        margin: 0 auto; }
        .contact-us-page__cols:before, .contact-us-page__cols:after {
          position: absolute;
          content: '';
          top: 50%;
          transform: translateY(-50%);
          width: 115px;
          height: 115px;
          background-image: url(../img/secondary.svg);
          background-repeat: no-repeat;
          z-index: -1; }
        .contact-us-page__cols:before {
          left: -10px; }
        .contact-us-page__cols:after {
          right: -10px; } }
  .contact-us-page__col {
    margin: 0 0 20px;
    flex: 1;
    background-color: white;
    padding: 20px;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center; }
    @media only screen and (min-width: 992px) {
      .contact-us-page__col {
        max-width: 325px;
        margin: 0 20px 0; } }
    .contact-us-page__col i {
      font-size: 28px;
      color: #009999;
      margin-right: 12px; }
    .contact-us-page__col a {
      color: black;
      font-weight: 600;
      font-size: 18px;
      transition: color 0.3s ease; }
      @media only screen and (min-width: 768px) {
        .contact-us-page__col a {
          font-size: 22px; } }
      .contact-us-page__col a:hover {
        color: #009999; }

.subscription-form {
  padding: 25px 0;
  position: relative;
  background-color: #33669a;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .subscription-form {
      padding: 50px 0; }
      .subscription-form:before, .subscription-form:after {
        content: '';
        position: absolute;
        height: 200px;
        min-width: 270px;
        width: 270px;
        background-image: url(../img/primary-desktop.svg);
        background-repeat: no-repeat;
        background-size: contain; }
      .subscription-form:before {
        left: 0;
        bottom: -50px;
        transform: rotate(180deg); }
      .subscription-form:after {
        right: 0;
        top: -50px; } }
  .subscription-form__text {
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    color: white;
    margin-bottom: 10px; }
  .subscription-form p {
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 600; }
  .subscription-form__inner {
    position: relative;
    z-index: 4; }
  .subscription-form .field {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .subscription-form .field {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
    .subscription-form .field__input {
      flex: 1;
      margin-bottom: 20px; }
      @media only screen and (min-width: 768px) {
        .subscription-form .field__input {
          margin-right: 20px;
          margin-bottom: 0; } }
  .subscription-form .error-text {
    color: #f7a6ac; }

.control-bar {
  margin-bottom: 20px;
  margin-top: -22px; }
  @media only screen and (min-width: 992px) {
    .control-bar {
      margin-top: -60px; } }
  .control-bar__buttons {
    justify-content: center; }
    .control-bar__buttons button {
      width: 126px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border-color: #33669a;
      padding: 11px 0;
      background-color: transparent;
      color: #4b4b4b;
      font-weight: 500;
      margin: 0 10px;
      font-size: 14px; }
      .control-bar__buttons button i {
        color: #009999;
        font-size: 16px; }
  .control-bar input.active {
    border-color: #009999;
    box-shadow: 0 0 22px -10px #009999;
    animation-name: flash;
    animation-duration: 2s;
    animation-fill-mode: both; }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }
  .control-bar__inner {
    background: white;
    padding: 18px 6px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 3px 3px 0 0;
    position: relative; }
    @media only screen and (min-width: 992px) {
      .control-bar__inner {
        padding: 31px 60px; } }
  .control-bar__filters {
    display: block;
    position: absolute;
    left: 0;
    transition: 0s;
    max-height: 1px;
    width: 100%;
    background-color: white;
    padding: 0 15px;
    opacity: 0;
    visibility: hidden;
    z-index: 99; }
    @media only screen and (min-width: 992px) {
      .control-bar__filters {
        display: flex;
        position: static;
        opacity: 1;
        visibility: visible;
        max-height: fit-content;
        padding: 0; } }
    .control-bar__filters.opened {
      max-height: 670px;
      padding: 15px;
      opacity: 1;
      visibility: visible;
      transition: .4s;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05); }
      .control-bar__filters.opened .select, .control-bar__filters.opened .input {
        margin-top: 10px; }
      .control-bar__filters.opened .select,
      .control-bar__filters.opened .input,
      .control-bar__filters.opened .custom-select,
      .control-bar__filters.opened .custom-select__text {
        width: 100%; }

.select-row {
  display: flex !important;
  flex-direction: column; }
  @media only screen and (min-width: 1200px) {
    .select-row {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap; } }
  .select-row .input_xs {
    width: 119px; }
  .select-row__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    flex-wrap: wrap; }
    @media only screen and (min-width: 1200px) {
      .select-row__filters {
        width: 55%; } }
    .select-row__filters.opened {
      max-height: 670px;
      padding: 15px;
      border-bottom: 1px solid #e5e5e5;
      opacity: 1;
      visibility: visible;
      transition: .4s; }
      .select-row__filters.opened .select, .select-row__filters.opened .input {
        margin-bottom: 10px; }
      .select-row__filters.opened .select,
      .select-row__filters.opened .input,
      .select-row__filters.opened .custom-select,
      .select-row__filters.opened .custom-select__text {
        width: 100%; }
  .select-row__button {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;
    padding-bottom: 20px;
    order: 3; }
    .select-row__button input {
      width: 100%;
      margin-top: 20px; }
    @media only screen and (min-width: 992px) {
      .select-row__button {
        flex-direction: row;
        padding-bottom: 0;
        order: inherit; }
        .select-row__button input {
          width: fit-content;
          margin-top: 0; } }
  .select-row__absolute {
    position: static;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 50; }
    @media only screen and (min-width: 992px) {
      .select-row__absolute {
        flex-direction: row;
        flex-wrap: wrap;
        left: 0;
        top: 100%;
        align-items: flex-start;
        border-top: 1px solid #d8dde6;
        position: relative;
        width: 100%;
        padding: 25px 0 0;
        box-shadow: none;
        margin-top: 25px; }
        .select-row__absolute > div {
          margin-bottom: 10px; } }
    .select-row__absolute .hidden {
      padding: 0;
      box-shadow: none;
      border-top: none; }
    .select-row__absolute .select, .select-row__absolute .input {
      margin-right: 25px; }

.search-map__pagination {
  margin: 60px 0; }

.search-map_half-map .container {
  max-width: none;
  padding: 0; }

.search-map_half-map .search-map__container {
  display: flex;
  position: relative; }

.search-map_half-map .search-map__cards {
  display: none; }
  @media only screen and (min-width: 992px) {
    .search-map_half-map .search-map__cards {
      width: 50%;
      display: block;
      padding-left: 30px;
      padding-right: 10px;
      margin-right: auto;
      max-height: 100vh;
      overflow: auto;
      transition: all .25s ease; } }
  .search-map_half-map .search-map__cards.is-open {
    width: 0;
    padding: 0; }

.search-map_half-map .search-map__map-wrapper {
  width: 100%;
  padding: 0 15px;
  height: 100vh;
  transition: all .25s ease; }
  @media only screen and (min-width: 992px) {
    .search-map_half-map .search-map__map-wrapper {
      padding-left: 10px;
      padding-right: 30px;
      width: 50%;
      max-height: 100vh; } }
  .search-map_half-map .search-map__map-wrapper.is-open {
    width: 100%;
    padding-left: 30px; }

.search-map_half-map .residence-cards {
  margin-right: 0; }

.search-map_half-map .residence-card {
  width: calc(50% - 14px);
  margin-right: 14px;
  max-width: none; }
  .search-map_half-map .residence-card:nth-child(2n) {
    margin-right: 0; }
  .search-map_half-map .residence-card:nth-child(3n +3) {
    margin-right: 14px; }

.search-map__cards::-webkit-scrollbar {
  width: 7px; }

.search-map__cards::-webkit-scrollbar-track {
  background-color: #e5e5e5;
  border-radius: 10px; }

.search-map__cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #009999; }

.content-wrapper {
  width: 100%; }

.no-results {
  text-align: center; }
  .no-results a {
    color: #009999; }
  .no-results__btn {
    margin-top: 50px; }
  .no-results__info {
    text-align: center;
    margin-top: 20px; }
    .no-results__info p {
      color: #4b4b4b;
      font-size: 18px;
      font-weight: 400; }
      .no-results__info p:not(:last-of-type) {
        margin-bottom: 25px; }
  .no-results__back-link {
    text-align: center;
    margin-top: 40px; }
  .no-results a {
    font-weight: 400;
    text-decoration: none;
    color: #009999;
    font-size: 20px; }
    .no-results a:hover {
      text-decoration: underline; }

.booking .custom-select__list li {
  font-size: 14px; }

.booking .custom-select__list .disabled {
  background-color: transparent;
  padding: 0;
  display: none; }

.booking .custom-select__text, .booking .custom-select__input {
  font-size: 13px;
  font-weight: 600; }

.booking .residence-card {
  min-height: 365px; }

.booking .slider_primary {
  width: calc(100% + 30px);
  margin-left: -15px; }
  @media only screen and (min-width: 992px) {
    .booking .slider_primary {
      width: 100%;
      margin-left: 0; } }
  .booking .slider_primary .slick-arrow {
    position: absolute;
    top: 40%;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 37px;
    width: 35px;
    height: 35px;
    z-index: 1;
    border: 1px solid #009999; }
  .booking .slider_primary .next {
    right: 10px; }
  .booking .slider_primary .prev {
    left: 10px;
    transform: scale(-1, 1); }
  .booking .slider_primary .slick-slide {
    min-height: 350px;
    height: 350px;
    border-radius: 0; }
    .booking .slider_primary .slick-slide img {
      border-radius: 0; }
    @media only screen and (min-width: 992px) {
      .booking .slider_primary .slick-slide {
        min-height: 480px;
        height: 480px;
        border-radius: 6px; }
        .booking .slider_primary .slick-slide img {
          border-radius: 6px; } }

.booking__return-link {
  margin-bottom: 30px; }

.booking__message {
  background-color: white;
  padding: 15px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 22px; }

.booking .card {
  margin-top: 0;
  min-height: fit-content;
  margin-bottom: 20px;
  padding: 15px; }
  @media only screen and (min-width: 768px) {
    .booking .card {
      width: 70%;
      margin: 0 auto 20px; } }
  @media only screen and (min-width: 992px) {
    .booking .card {
      margin-bottom: 0;
      min-height: 180px;
      padding: 30px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .booking .card i {
    font-size: 24px;
    margin-bottom: 16px; }
    @media only screen and (min-width: 992px) {
      .booking .card i {
        font-size: 44px; } }
  .booking .card__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px; }
    @media only screen and (min-width: 992px) {
      .booking .card__title {
        font-size: 20px; } }
  .booking .card__info {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px; }
    @media only screen and (min-width: 992px) {
      .booking .card__info {
        font-size: 20px; } }
  .booking .card .company-name {
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    margin-top: 5px; }
    @media only screen and (min-width: 992px) {
      .booking .card .company-name {
        font-size: 22px; } }

.booking__content {
  padding-bottom: 30px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 30px; }
  .booking__content .residence-card .slider-arrows .prev {
    transform: translate3d(0, 0, 0) translateY(-50%); }
  .booking__content .residence-card .slider-arrows .next {
    transform: translate3d(0, 0, 0) translateY(-50%) scale(-1, 1); }

.booking__content_border {
  border-bottom: none; }

.booking__selects-range:not(.is-active) {
  display: none; }

.booking__map {
  height: 400px; }
  .booking__map .map-wrap__inner {
    height: 100%; }

.booking__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 16px; }

.booking p {
  font-size: 14px; }
  @media only screen and (min-width: 992px) {
    .booking p {
      font-size: 16px; } }

.booking__list_columns {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 992px) {
    .booking__list_columns {
      flex-direction: row;
      justify-content: space-between; } }
  .booking__list_columns li {
    width: 100%; }

.booking__list ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  margin-left: 0; }
  .booking__list ul li {
    margin-right: 30px;
    position: relative;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 13px; }
    .booking__list ul li:after {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #009999; }

.booking__description {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px; }

.booking__time-wrap {
  background: #FFFFFF;
  border-radius: 6px;
  margin-top: 20px;
  padding: 12px;
  text-align: center;
  margin-bottom: 20px; }
  @media only screen and (min-width: 992px) {
    .booking__time-wrap {
      margin-top: 30px; } }
  .booking__time-wrap h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 15px; }

.booking__breadcrumbs {
  padding: 12px 0;
  margin-bottom: 8px; }
  @media only screen and (min-width: 992px) {
    .booking__breadcrumbs {
      border-bottom: 1px solid #8DBADD;
      padding: 18px 0;
      margin-bottom: 21px; } }
  .booking__breadcrumbs .breadcrumbs {
    padding: 0;
    width: calc(100% + 30px);
    margin-left: -15px; }
    @media only screen and (min-width: 992px) {
      .booking__breadcrumbs .breadcrumbs {
        width: 100%;
        margin-left: 0; } }
    .booking__breadcrumbs .breadcrumbs__list {
      justify-content: flex-start; }
      .booking__breadcrumbs .breadcrumbs__list li:before {
        border-color: #4b4b4b;
        border-width: 0 1px 1px 0;
        top: 9px;
        padding: 2px; }
      .booking__breadcrumbs .breadcrumbs__list li a > span {
        color: #4b4b4b;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px; }
        @media only screen and (min-width: 992px) {
          .booking__breadcrumbs .breadcrumbs__list li a > span {
            font-size: 14px; } }

.booking__id {
  font-size: 14px;
  line-height: 27px;
  color: #4b4b4b;
  font-weight: 700; }
  @media only screen and (min-width: 992px) {
    .booking__id {
      font-size: 20px; } }
  .booking__id i {
    color: #009999;
    margin-right: 2px;
    font-size: 18px; }
    @media only screen and (min-width: 992px) {
      .booking__id i {
        font-size: 22px; } }

.booking__actions {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.booking__actions-btn {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  position: absolute;
  top: 15px;
  right: 15px;
  width: fit-content; }
  .booking__actions-btn > * {
    width: 30px;
    height: 30px;
    background-color: white;
    color: #4b4b4b;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 50%;
    margin-bottom: 10px; }
  .booking__actions-btn i {
    vertical-align: middle;
    font-size: 15px; }
  @media only screen and (min-width: 992px) {
    .booking__actions-btn {
      max-width: 117px;
      width: 117px;
      flex-direction: row;
      position: static; }
      .booking__actions-btn > * {
        width: 51px;
        height: 51px;
        background-color: white;
        color: #4b4b4b;
        text-align: center;
        line-height: 51px;
        display: block;
        border-radius: 50%;
        margin-bottom: 0; }
      .booking__actions-btn i {
        vertical-align: middle;
        font-size: 18px; } }

.booking__name {
  margin-bottom: 25px; }
  @media only screen and (min-width: 992px) {
    .booking__name {
      margin-bottom: 3px; } }
  .booking__name h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px; }
    @media only screen and (min-width: 992px) {
      .booking__name h1 {
        font-size: 36px;
        line-height: 49px; } }

.booking__subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px; }

.booking-hero {
  margin: 0 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #C7C7C7; }
  @media only screen and (min-width: 992px) {
    .booking-hero {
      margin-top: 30px; } }

.working-time {
  display: flex;
  font-size: 14px;
  margin-top: 20px; }
  .working-time li {
    margin-bottom: 5px;
    line-height: 19px; }
  .working-time__days {
    margin-right: 20px; }

.booking-similar-slider .slick-list {
  margin: 0 -10px; }

.booking-similar-slider .slick-slide {
  margin: 0 10px; }
  .booking-similar-slider .slick-slide .residence-card {
    margin-right: 0; }

.booking-request {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: 30px; }
  @media only screen and (min-width: 768px) {
    .booking-request {
      width: 70%;
      margin: 30px auto 0; } }
  @media only screen and (min-width: 992px) {
    .booking-request {
      border-radius: 6px;
      width: 100%;
      margin-left: 0; } }
  .booking-request_full {
    background: #33669a;
    width: 100%;
    margin-left: 0;
    border: none;
    border-radius: 0; }
    .booking-request_full .cost-summ {
      padding: 12px 0 0; }
      .booking-request_full .cost-summ__row {
        margin-bottom: 12px; }
        .booking-request_full .cost-summ__row p {
          font-size: 23px; }
      .booking-request_full .cost-summ__title {
        font-size: 23px;
        margin-bottom: 12px; }
        .booking-request_full .cost-summ__title .subtitle {
          font-weight: inherit; }
      .booking-request_full .cost-summ__total:before {
        top: -5px;
        width: 100%; }
      .booking-request_full .cost-summ p, .booking-request_full .cost-summ h3 {
        color: #fff; }
    .booking-request_full .booking-request__container {
      padding: 0 165px;
      max-width: 1440px;
      margin: 0 auto; }
    .booking-request_full .booking-request__head {
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 35px 0 6px; }
    .booking-request_full .booking-request__col_top {
      position: static;
      top: auto;
      transform: none; }
    .booking-request_full .booking-request__content {
      background: #33669a;
      border-radius: 0;
      padding: 70px 0 48px;
      min-height: auto;
      border-top: 1px solid #979797; }
    .booking-request_full .booking-request__summary {
      margin: 0; }
    .booking-request_full .booking-request__desc {
      font-size: 20px;
      max-width: 160px; }
    .booking-request_full .booking-request__tariff, .booking-request_full .booking-request__subtariff {
      margin-bottom: 8px; }
  .booking-request .select__title {
    color: #4b4b4b;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px; }
  .booking-request .custom-select__field {
    background: #fff; }
  .booking-request .custom-select__list, .booking-request .custom-select__dropdown {
    width: 170px; }
  .booking-request__head {
    color: white;
    position: relative;
    background: #33669a;
    padding: 150px 45px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0; }
    @media only screen and (min-width: 992px) {
      .booking-request__head {
        border-radius: 6px; } }
    .booking-request__head:before {
      content: '';
      background-color: #79A8D7;
      height: 1px;
      width: 100%;
      left: 0;
      top: 60%;
      position: absolute; }
  .booking-request__content {
    position: relative;
    background: white;
    padding: 17px 14px 70px;
    border-radius: 6px; }
  .booking-request__discount {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 46px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    font-size: 11px;
    font-weight: 700;
    color: #4b4b4b;
    letter-spacing: 1px;
    border-radius: 50%;
    border: 2px solid #009999; }
  .booking-request__non-profit {
    text-align: center;
    font-size: 14px !important;
    margin: 15px 0 0 0;
    padding: 0 15px; }
  .booking-request__col_top {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0); }
  .booking-request__tariff, .booking-request__subtariff {
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 20px; }
  .booking-request__subtariff {
    font-size: 18px;
    font-style: italic;
    letter-spacing: 0; }
  .booking-request__desc {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .booking-request__desc {
        font-size: 16px; } }
    @media only screen and (min-width: 1200px) {
      .booking-request__desc {
        font-size: 18px; } }
    .booking-request__desc p {
      font-size: inherit;
      color: inherit;
      margin-bottom: 10px; }
      .booking-request__desc p:last-child {
        margin-bottom: 0; }
  .booking-request__rating .rating {
    text-align: center; }
    .booking-request__rating .rating svg {
      fill: white;
      opacity: 1; }
  .booking-request__row {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .booking-request__row {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end; } }
    .booking-request__row .select {
      flex: 1;
      max-width: 100%;
      margin: 5px 3px; }
      .booking-request__row .select .datepicker-select_sm, .booking-request__row .select .datepicker-select {
        width: 100%; }
      .booking-request__row .select .custom-select__text, .booking-request__row .select .custom-select__input {
        width: 100%; }
  .booking-request__timing {
    cursor: pointer;
    font-size: 16px; }
  .booking-request__summary {
    margin: 20px 0;
    display: none; }
    .booking-request__summary.is-shown {
      display: block; }
  .booking-request__btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }
    .booking-request__btn .btn {
      min-width: 260px; }
  .booking-request__time-link {
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px; }
  .booking-request__time-link_reset {
    margin-left: auto;
    margin-right: 0;
    color: #4b4b4b;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500; }
    .booking-request__time-link_reset i {
      font-size: 16px;
      color: #009999;
      margin-right: 5px; }
  .booking-request__invalid-code {
    color: white;
    background-color: #009999;
    font-weight: 700;
    padding: 10px 15px;
    position: relative;
    top: -5px;
    text-align: center; }

.booking-notification {
  background-color: #33669a;
  padding: 15px 0 25px;
  color: white; }
  .booking-notification__title {
    margin-bottom: 15px;
    color: white; }
    .booking-notification__title h2 {
      color: white; }
  .booking-notification__actions a {
    margin-bottom: 20px; }

.cost-summ__title .subtitle {
  font-weight: 700;
  text-align: left;
  margin-bottom: 15px; }

.cost-summ__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }

.cost-summ__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #d8dde6; }
  .cost-summ__total h3 {
    margin-bottom: 0;
    font-weight: 700; }

.review__wrapper {
  margin-bottom: 30px; }

.review__item {
  display: flex;
  align-items: flex-start;
  padding: 15px 15px 15px 0;
  position: relative; }
  .review__item_answer {
    background-color: white;
    border-radius: 6px;
    padding-left: 15px; }
    @media only screen and (min-width: 576px) {
      .review__item_answer {
        margin-left: 100px; } }
    .review__item_answer:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid white;
      top: -9px;
      left: 20px; }

.review__avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  max-width: 45px;
  flex: 0 0 45px; }
  @media only screen and (min-width: 576px) {
    .review__avatar {
      width: 70px;
      height: 70px;
      max-width: 70px;
      flex: 0 0 70px; } }
  .review__avatar img {
    width: 100%;
    height: 100%; }

.review__body {
  margin-left: 15px; }
  @media only screen and (min-width: 576px) {
    .review__body {
      margin-left: 30px; } }

.review__date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 5px; }

.review__name {
  font-weight: 700;
  margin-bottom: 5px; }

.review__rating {
  margin-bottom: 15px; }

.review__btn .btn {
  font-size: 14px;
  font-weight: 600;
  min-height: 30px;
  padding: 5px 30px 5px 15px;
  color: #4b4b4b;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .review__btn .btn:focus:before, .review__btn .btn:active:before, .review__btn .btn:hover:before {
    border-left-color: white;
    border-bottom-color: white; }
  .review__btn .btn:before {
    content: '';
    position: absolute;
    top: 45%;
    right: 9px;
    height: 7px;
    width: 7px;
    border-left: 2px solid #009999;
    border-bottom: 2px solid #009999;
    transform: rotate(-45deg) scale(1, 1) translate(0, -50%);
    transform-origin: center;
    transition: .3s ease;
    z-index: 15; }
  .review__btn .btn.is-open:before {
    transform: rotate(135deg) scale(1, 1) translate(0, 0);
    top: 45%;
    right: 12px; }

.contact-user {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  margin-top: 25px;
  justify-content: center;
  padding: 15px; }
  @media only screen and (min-width: 768px) {
    .contact-user {
      width: 70%;
      margin: 15px auto 0; } }
  @media only screen and (min-width: 992px) {
    .contact-user {
      width: 100%; } }
  @media only screen and (min-width: 1200px) {
    .contact-user {
      padding: 15px 30px; } }
  .contact-user__avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 30px; }
    .contact-user__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }
  .contact-user__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 15px; }
  .contact-user__btn .btn {
    font-size: 14px;
    font-weight: 600;
    min-height: 30px;
    padding: 5px 15px;
    color: #4b4b4b; }

.message {
  padding: 30px 0; }
  .message .title {
    font-size: 24px;
    line-height: 1.4;
    margin: 15px 0; }
    @media only screen and (min-width: 992px) {
      .message .title {
        font-size: 27px; } }

.user-description__textarea {
  width: 100%;
  margin: 0 auto 30px; }
  @media only screen and (min-width: 768px) {
    .user-description__textarea {
      width: 90%; } }
  @media only screen and (min-width: 992px) {
    .user-description__textarea {
      width: 70%; } }

.user-description__head {
  background-color: white;
  border-radius: 6px;
  padding: 17px 24px;
  width: 100%;
  margin: 16px auto 16px; }
  @media only screen and (min-width: 768px) {
    .user-description__head {
      width: 90%; } }
  @media only screen and (min-width: 1200px) {
    .user-description__head {
      width: 70%; } }

.user-description__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px; }
  .user-description__image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.user-description__title {
  display: inline-block;
  vertical-align: middle; }

.user-description__list {
  margin-left: 80px; }
  @media only screen and (min-width: 992px) {
    .user-description__list {
      margin-left: 110px; } }
  .user-description__list li {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    position: relative;
    margin-bottom: 3px; }
    @media only screen and (min-width: 992px) {
      .user-description__list li {
        font-size: 18px; } }
    .user-description__list li:before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #009999; }

.booking__content_checkout {
  padding-bottom: 20px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 992px) {
    .booking__content_checkout {
      padding-bottom: 30px;
      margin-bottom: 30px; } }
  .booking__content_checkout .custom-select__field {
    width: fit-content; }

.booking__name_checkout {
  margin-bottom: 12px; }

.booking__subtitle_checkout {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 12px; }

.booking__id_checkout {
  font-size: 18px;
  font-weight: 600; }

.booking__information {
  padding-top: 20px;
  padding-bottom: 50px;
  font-size: 14px;
  line-height: 19px;
  color: #999999; }

.booking__payment .payment {
  margin-left: -15px;
  width: calc(100% + 30px); }
  @media only screen and (min-width: 992px) {
    .booking__payment .payment {
      width: 100%; } }
  .booking__payment .payment h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 15px; }
    @media only screen and (min-width: 992px) {
      .booking__payment .payment h3 {
        margin-bottom: 15px; } }
  .booking__payment .payment .field {
    width: 100%; }
    .booking__payment .payment .field__input {
      width: 100%; }

.checkout-form .select {
  margin-bottom: 20px; }
  .checkout-form .select .custom-select__text, .checkout-form .select .custom-select__input {
    width: 225px; }

.checkout-form .field {
  width: 200px; }
  .checkout-form .field__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .checkout-form .field__desc {
    font-size: 16px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .checkout-form .field__desc i {
      color: #009999; }
  .checkout-form .field__input {
    width: 140px; }
  .checkout-form .field__note {
    font-size: 16px;
    line-height: 22px;
    color: #999999;
    margin-top: 0; }
  .checkout-form .field__flex {
    width: 285px;
    justify-content: space-between; }

.checkout-form .user-description__head {
  width: 100%;
  background-color: transparent;
  padding: 0;
  margin: 0; }

.checkout-form .user-description__image {
  width: 49px;
  height: 49px; }
  @media only screen and (min-width: 992px) {
    .checkout-form .user-description__image {
      width: 80px;
      height: 80px;
      min-width: 80px; } }

.checkout-form .user-description p {
  margin-top: 10px; }
  @media only screen and (min-width: 992px) {
    .checkout-form .user-description p {
      margin-left: 100px; } }

.checkout-form .user-description__list {
  margin-top: 10px;
  margin-left: 15px; }
  @media only screen and (min-width: 992px) {
    .checkout-form .user-description__list {
      margin-left: 110px;
      margin-top: 30px; } }
  .checkout-form .user-description__list li {
    font-weight: 500;
    font-size: 14px; }
    @media only screen and (min-width: 992px) {
      .checkout-form .user-description__list li {
        font-size: 16px; } }

.checkout-form .user-description__textarea {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) {
    .checkout-form .user-description__textarea {
      width: calc(100% - 100px);
      margin-left: 100px;
      margin-top: 30px;
      margin-bottom: 0; } }

.booking-request_checkout {
  margin-top: -30px; }
  @media only screen and (min-width: 768px) {
    .booking-request_checkout {
      margin: 0;
      width: 100%; } }
  .booking-request_checkout .booking-request__content {
    border-radius: 0;
    background-color: #33669a;
    padding-bottom: 20px; }
    @media only screen and (min-width: 992px) {
      .booking-request_checkout .booking-request__content {
        border-radius: 6px; } }
    .booking-request_checkout .booking-request__content p, .booking-request_checkout .booking-request__content h3 {
      color: white;
      font-size: 20px; }
  .booking-request_checkout .booking-request__summary {
    margin: 0; }
  .booking-request_checkout .booking-request .cost-summ__total {
    border-top-color: #79A8D7; }

.timetable {
  max-width: 350px;
  margin: 0 auto; }
  .timetable table {
    border-radius: 6px;
    border: 1px solid #33669a;
    overflow: hidden;
    text-align: center;
    border-collapse: separate;
    margin-bottom: 15px; }
  .timetable tr:not(:last-child) td {
    border-bottom: 1px solid #33669a; }
  .timetable thead {
    background-color: rgba(51, 102, 154, 0.8); }
  .timetable th, .timetable td {
    color: #4b4b4b;
    font-weight: 400;
    font-size: 14px; }
    @media only screen and (min-width: 992px) {
      .timetable th, .timetable td {
        font-size: 16px; } }
  .timetable th {
    border-bottom: 1px solid #33669a;
    border-left: 1px solid white;
    padding: 7px;
    color: white; }
  .timetable td {
    padding: 10px 5px;
    border-left: 1px solid #33669a; }
  .timetable td:first-child, .timetable th:first-child {
    border-left: none; }
  .timetable__edit {
    font-size: 16px;
    margin-top: 10px; }

.payment-info {
  max-width: 450px; }
  .payment-info__row {
    display: flex; }
  .payment-info__cell {
    flex: 1;
    display: flex;
    align-items: center; }
    .payment-info__cell i {
      color: #009999;
      margin-right: 5px; }
    .payment-info__cell:last-child {
      margin-bottom: 0; }
    .payment-info__cell p {
      font-weight: bold;
      font-size: 14px;
      line-height: 36px; }
      @media only screen and (min-width: 768px) {
        .payment-info__cell p {
          font-size: 18px; } }

.hero {
  margin-top: 0;
  width: 100%;
  padding: 50px 0 0;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) {
    .hero {
      padding: 90px 0 0; } }
  @media only screen and (min-width: 992px) {
    .hero {
      padding: 0;
      margin-top: 92px; } }
  .hero__content {
    width: 100%;
    min-height: 600px;
    position: relative; }
    @media only screen and (min-width: 992px) {
      .hero__content {
        min-height: calc(100vh - 90px); } }
  .hero__static {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3; }
  .hero__title {
    font-size: 26px;
    color: white;
    font-weight: 900;
    text-align: center;
    margin: 0 auto 55px;
    line-height: 1.2; }
    @media only screen and (min-width: 992px) {
      .hero__title {
        font-size: 56px;
        width: 80%; } }
  .hero__subtitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 95px;
    color: white; }
  .hero__slide {
    min-height: 600px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media only screen and (min-width: 992px) {
      .hero__slide {
        min-height: calc(100vh - 90px); } }
    .hero__slide .bg_img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute; }
  .hero__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .hero__form {
        flex-direction: row; } }
    .hero__form .field {
      flex: 1;
      margin-bottom: 10px;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .hero__form .field {
          margin-bottom: 0; } }
      .hero__form .field input {
        margin-bottom: 0;
        border-radius: 4px; }
      @media only screen and (min-width: 768px) {
        .hero__form .field input {
          border-radius: 0; }
        .hero__form .field:first-of-type input {
          border-radius: 4px 0 0 4px; }
        .hero__form .field:last-of-type input {
          border-radius: 0 4px 4px 0; } }
  .hero .btn_icon {
    width: 100%;
    height: 36px;
    border-radius: 4px; }
    @media only screen and (min-width: 768px) {
      .hero .btn_icon {
        width: 59px;
        border-radius: 50%;
        height: 59px;
        margin-left: 15px; } }
    .hero .btn_icon span {
      display: block; }
      @media only screen and (min-width: 768px) {
        .hero .btn_icon span {
          display: none; } }
    .hero .btn_icon i {
      display: none; }
      @media only screen and (min-width: 768px) {
        .hero .btn_icon i {
          display: block; } }

.categories__icon {
  background-image: url(../img/bgicon_secondary.svg);
  width: 140px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  line-height: 130px;
  margin: 0 auto; }
  .categories__icon i {
    font-size: 40px; }

.categories__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 7px; }
  @media only screen and (min-width: 768px) {
    .categories__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px; } }

.categories__description {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 15px; }
  @media only screen and (min-width: 768px) {
    .categories__description {
      margin-bottom: 20px; } }

.categories i {
  color: #009999; }

.non-profits__text {
  margin: 20px 0; }
  @media only screen and (min-width: 768px) {
    .non-profits__text {
      margin: 50px 0; } }
  .non-profits__text p {
    font-size: 17px;
    margin-bottom: 15px;
    line-height: 20px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .non-profits__text p {
        font-size: 22px;
        margin-bottom: 22px;
        line-height: 26px; } }
    .non-profits__text p:last-child {
      margin-bottom: 0; }

.non-profits__consideration {
  background-color: white;
  padding: 30px;
  margin-top: 30px; }
  @media only screen and (min-width: 768px) {
    .non-profits__consideration {
      margin-top: 90px; } }
  .non-profits__consideration .consideration {
    display: flex;
    align-items: center; }
    .non-profits__consideration .consideration__image {
      min-width: 170px;
      height: 70px;
      margin-right: 20px; }
      @media only screen and (min-width: 992px) {
        .non-profits__consideration .consideration__image {
          min-width: 270px;
          height: 170px;
          margin-right: 40px; } }
      .non-profits__consideration .consideration__image img {
        width: 100%;
        height: auto; }
    .non-profits__consideration .consideration__text p {
      font-weight: normal;
      font-size: 16px;
      line-height: 26px; }
    .non-profits__consideration .consideration__title {
      font-size: 24px;
      line-height: 34px;
      font-weight: bold;
      margin-bottom: 15px; }

.reasons .slider {
  margin-top: 40px; }

.advantage {
  position: relative;
  margin-bottom: 30px; }
  .advantage:after {
    position: absolute;
    content: '';
    background-image: url(../img/secondary.svg);
    width: 65px;
    height: 65px;
    background-repeat: no-repeat;
    background-size: contain; }
  @media only screen and (min-width: 768px) {
    .advantage:after {
      width: 115px;
      height: 115px; } }
  .advantage_left-top:after {
    left: -20px;
    top: -20px; }
  .advantage_right-top:after {
    right: -20px;
    top: -20px; }
  .advantage_left-bottom:after {
    bottom: -20px;
    left: -20px; }
  .advantage_right-bottom:after {
    bottom: -20px;
    right: -20px; }
  .advantage__icon {
    width: 71px;
    height: 71px;
    line-height: 71px;
    padding: 11px;
    text-align: center;
    margin: 0 auto 25px;
    box-shadow: 0 0 10px rgba(97, 135, 132, 0.2);
    background-color: #FDFDFD;
    border-radius: 6px;
    display: flex; }
    .advantage__icon i {
      color: #009999;
      font-size: 49px;
      max-width: 47px;
      display: block;
      margin: auto; }
  .advantage__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 15px; }
  .advantage__description {
    font-size: 16px;
    line-height: 26px;
    text-align: center; }
  .advantage .card {
    margin-top: 0; }

.reasons-video {
  background-color: #33669a;
  overflow: hidden;
  position: relative; }
  .reasons-video:after, .reasons-video:before {
    position: absolute;
    content: '';
    width: 600px;
    height: 190px;
    background-size: contain;
    background-repeat: no-repeat; }
  .reasons-video:after {
    background-image: url(../img/primary-bottom.svg);
    bottom: -50px;
    left: 0; }
  .reasons-video:before {
    background-image: url(../img/primary_small.svg);
    right: -30px;
    top: 0; }
  .reasons-video .video:before {
    display: none; }
  .reasons-video .draggable {
    min-height: 300px !important; }

.about-video {
  position: relative;
  background-color: #33669a;
  overflow: hidden; }
  .about-video:after {
    content: '';
    position: absolute;
    background-image: url(../img/primary-bottom.svg);
    width: 330px;
    height: 284px;
    bottom: -150px;
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    transform: scale(-1, 1); }
  .about-video .social {
    display: flex;
    justify-content: space-between;
    width: 195px;
    margin: 25px auto 0; }
    .about-video .social__link {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease-in-out; }
      .about-video .social__link:hover {
        background-color: #009999; }
        .about-video .social__link:hover i {
          color: white; }
      .about-video .social__link i {
        font-size: 15px;
        color: #009999;
        transition: all 0.4s ease-in-out; }
    @media only screen and (min-width: 992px) {
      .about-video .social {
        margin: 25px 0 0; } }
  .about-video .title {
    text-align: center;
    margin-bottom: 10px; }
    @media only screen and (min-width: 992px) {
      .about-video .title {
        text-align: left; } }
  .about-video .bg_text {
    font-weight: 800;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 26px;
    margin-right: -26px;
    color: #588BBE;
    position: relative;
    margin-bottom: 20px; }
    .about-video .bg_text:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 60%;
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #86B5E4; }
    @media only screen and (min-width: 768px) {
      .about-video .bg_text {
        font-size: 34px; } }
    @media only screen and (min-width: 992px) {
      .about-video .bg_text:before {
        width: 120%;
        left: 0;
        transform: translateX(0); } }
    @media only screen and (min-width: 1200px) {
      .about-video .bg_text {
        width: 100%;
        font-size: 42px;
        letter-spacing: 30px;
        margin-right: -30px; } }
  .about-video__text {
    color: white;
    font-size: 18px;
    line-height: 22px;
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .about-video__text {
        text-align: left;
        font-size: 22px;
        line-height: 38px; } }
  @media only screen and (min-width: 576px) {
    .about-video .content-wrap {
      height: 286px; } }
  @media only screen and (min-width: 768px) {
    .about-video .content-wrap {
      height: 388px; } }
  @media only screen and (min-width: 992px) {
    .about-video .content-wrap {
      height: 344px; } }
  @media only screen and (min-width: 1200px) {
    .about-video .content-wrap {
      height: 410px; } }
  .about-video__video {
    height: 100%;
    margin-top: 30px; }
    @media only screen and (min-width: 992px) {
      .about-video__video {
        margin-top: 0; } }
    .about-video__video .video__btn_main {
      width: 70px;
      height: 70px;
      top: 31%; }
      @media only screen and (min-width: 768px) {
        .about-video__video .video__btn_main {
          width: 120px;
          height: 120px;
          top: 34%; } }

.img-slider__inner {
  margin-top: 50px; }

.img-caption {
  max-width: 100%;
  margin: 0 auto 25px;
  display: block;
  text-decoration: none; }
  @media only screen and (min-width: 992px) {
    .img-caption {
      margin-bottom: 0; } }
  .img-caption__image {
    margin: 0 20px; }
    .img-caption__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .img-caption__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #4b4b4b;
    margin-top: 10px; }
    @media only screen and (min-width: 768px) {
      .img-caption__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px; } }

@media only screen and (min-width: 768px) {
  .index .header {
    background-color: transparent;
    box-shadow: none; }
    .index .header .logo-subname {
      fill: white;
      stroke: white; }
    .index .header .logo-name {
      fill: white; }
    .index .header__nav {
      background-color: transparent; }
      .index .header__nav .nav__item > a, .index .header__nav .nav__item_sublist > a {
        color: white; }
      .index .header__nav .nav__item_sublist > a:after {
        border-color: white; }
      .index .header__nav .nav__item_button {
        border-color: white; }
        .index .header__nav .nav__item_button:hover {
          background: white; }
          .index .header__nav .nav__item_button:hover a {
            color: #4b4b4b; }
    .index .header .user__no-image {
      border-color: white;
      color: white; }
    .index .header.is-fixed {
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); }
      .index .header.is-fixed .logo-subname {
        fill: #1aa99e;
        stroke: #1aa99e; }
      .index .header.is-fixed .logo-name {
        fill: #1b75bc; }
      .index .header.is-fixed .nav__item > a {
        color: #4b4b4b; }
      .index .header.is-fixed .nav__item_sublist > a {
        color: #009999; }
      .index .header.is-fixed .nav__item_sublist > a:after {
        border-color: #009999; }
      .index .header.is-fixed .nav__item_button {
        border-color: #009999; }
        .index .header.is-fixed .nav__item_button:hover {
          background: #009999; }
          .index .header.is-fixed .nav__item_button:hover a {
            color: white; }
      .index .header.is-fixed .user__no-image {
        border-color: #009999;
        color: #4b4b4b; } }

.index .hero {
  margin-top: 0;
  padding: 0; }
  @media only screen and (min-width: 992px) {
    .index .hero__content {
      min-height: 100vh; }
    .index .hero__slide {
      min-height: 100vh; } }

@media only screen and (min-width: 992px) {
  .list-venue {
    background-image: url(../img/main.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 120%;
    margin-bottom: 0; } }

@media only screen and (min-width: 1920px) {
  .list-venue {
    background-size: cover; } }

.section-info {
  padding: 30px 0 50px; }
  @media only screen and (min-width: 992px) {
    .section-info {
      padding: 40px 0 70px; } }
  .section-info__icons {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 290px;
    background-size: contain; }
    @media only screen and (min-width: 992px) {
      .section-info__icons {
        min-height: 360px;
        background-position: right center; } }
  .section-info__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #4b4b4b;
    position: relative;
    margin-bottom: 25px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .section-info__title {
        font-size: 30px;
        margin-bottom: 35px; }
        .section-info__title:before {
          position: absolute;
          content: '';
          height: 1px;
          width: 110%;
          left: 0;
          bottom: -5px;
          background-color: #009999; } }
    @media only screen and (min-width: 992px) {
      .section-info__title {
        font-size: 32px;
        line-height: 62px; } }
    @media only screen and (min-width: 1200px) {
      .section-info__title {
        font-size: 36px; } }
  .section-info__description {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4b4b4b; }
    @media only screen and (min-width: 992px) {
      .section-info__description {
        font-weight: 700;
        font-style: normal;
        font-size: 24px;
        line-height: 38px; } }

.list-cards {
  padding: 0 0 40px; }
  @media only screen and (min-width: 992px) {
    .list-cards {
      padding: 0 0 100px; } }
  .list-cards__title {
    margin-bottom: 55px;
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    font-weight: 700; }
    @media only screen and (min-width: 992px) {
      .list-cards__title {
        font-size: 48px;
        margin-bottom: 135px; } }
  .list-cards__content .slider_dots .slick-dots {
    margin-top: 0;
    margin-bottom: 25px; }
  @media only screen and (min-width: 992px) {
    .list-cards__content {
      max-height: 450px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-bottom: 120px;
      background-image: url(../img/list-cards-bg.svg); } }
  .list-cards__content .step {
    min-height: 450px; }
    @media only screen and (min-width: 992px) {
      .list-cards__content .step {
        min-height: 590px;
        margin-top: -70px; } }
    .list-cards__content .step__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 20px; }
      @media only screen and (min-width: 992px) {
        .list-cards__content .step__title {
          font-weight: 600;
          font-size: 36px;
          line-height: 45px;
          margin: 10px 0; } }
    .list-cards__content .step__list {
      margin-left: 20px; }
      @media only screen and (min-width: 992px) {
        .list-cards__content .step__list {
          margin-left: 60px;
          margin-top: 25px; } }
    .list-cards__content .step__list-item {
      text-align: left;
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      display: flex;
      align-items: flex-start; }
      @media only screen and (min-width: 992px) {
        .list-cards__content .step__list-item {
          margin-bottom: 30px;
          font-size: 18px; } }
      .list-cards__content .step__list-item i {
        margin-right: 10px;
        margin-top: 5px; }
      @media only screen and (min-width: 768px) {
        .list-cards__content .step__list-item {
          margin-right: 15px;
          margin-top: 3px; } }
    .list-cards__content .step__top {
      width: 200px;
      height: 200px;
      margin-top: -120px; }
      @media only screen and (min-width: 992px) {
        .list-cards__content .step__top {
          width: 300px;
          height: 300px;
          margin-top: -180px;
          margin-bottom: 25px; } }
    .list-cards__content .step__icon {
      bottom: 30px; }
      .list-cards__content .step__icon i {
        font-size: 60px; }
        @media only screen and (min-width: 992px) {
          .list-cards__content .step__icon i {
            font-size: 80px; } }

.hero-list-item {
  padding: 30px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 82px; }
  .hero-list-item .btn {
    margin: 0 auto;
    display: block;
    width: 240px; }
    @media only screen and (min-width: 992px) {
      .hero-list-item .btn {
        margin-left: 30px; } }
  @media only screen and (min-width: 992px) {
    .hero-list-item {
      padding: 108px 0; } }
  .hero-list-item h1 {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: white;
    margin-bottom: 20px;
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .hero-list-item h1 {
        font-size: 44px;
        font-weight: 900;
        line-height: 58px;
        margin-bottom: 40px;
        text-align: left; } }
  .hero-list-item .list-icons {
    margin-bottom: 25px;
    margin-left: 25px; }
    @media only screen and (min-width: 992px) {
      .hero-list-item .list-icons {
        margin-bottom: 50px;
        margin-left: 30px; } }
    .hero-list-item .list-icons__item {
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
      @media only screen and (min-width: 992px) {
        .hero-list-item .list-icons__item {
          margin-bottom: 5px; } }
    .hero-list-item .list-icons__icon {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-radius: 6px;
      background-color: white;
      line-height: 35px;
      text-align: center;
      margin-right: 12px; }
      .hero-list-item .list-icons__icon i {
        color: #009999;
        font-size: 18px; }
    .hero-list-item .list-icons__text {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      color: white; }
      @media only screen and (min-width: 992px) {
        .hero-list-item .list-icons__text {
          font-size: 24px;
          line-height: 45px; } }

.btn {
  min-height: 43px;
  padding: 7px 40px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  outline: none;
  appearance: none;
  border: 2px solid #009999;
  cursor: pointer; }
  .btn_back {
    position: relative;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: #009999;
    display: inline-block;
    padding-left: 15px; }
    @media only screen and (min-width: 992px) {
      .btn_back {
        font-size: 18px; } }
    .btn_back:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      left: 0;
      top: 4px;
      border: 1px solid #009999;
      border-right: none;
      border-top: none;
      transform: rotate(45deg); }
      @media only screen and (min-width: 992px) {
        .btn_back:before {
          top: 7px;
          width: 7px;
          height: 7px; } }
  .btn:hover, .btn:focus, .btn:active {
    background-color: #009999;
    color: white; }
  .btn_primary {
    background-color: #009999;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 153, 151, 0.2); }
    .btn_primary:hover, .btn_primary:focus, .btn_primary:active {
      background-color: white;
      border-color: #009999;
      color: #009999; }
  .btn.is-disabled {
    background-color: #D9D9D9;
    color: white;
    border-color: #D9D9D9;
    box-shadow: none; }
  .btn_transparent {
    border-color: transparent;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    color: #4b4b4b;
    height: fit-content;
    min-height: auto; }
    .btn_transparent:hover {
      background: transparent;
      color: #009999; }
    .btn_transparent:after {
      position: absolute;
      content: '';
      right: -17px;
      top: 4px;
      border: solid #009999;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      transition: transform 0.3s ease; }
  .btn_secondary {
    background-color: white;
    color: #009999; }
  .btn_cancelation {
    background-color: white;
    font-size: 16px;
    color: #4b4b4b;
    font-weight: 600; }
  .btn_disabled {
    background-color: #D8D8D8;
    color: white;
    border-color: #D8D8D8; }
  .btn_sm {
    padding: 11px 25px; }
  .btn_edit {
    min-height: 30px;
    font-size: 14px;
    padding: 4px 15px;
    margin: 7px 0; }
  .btn_center {
    display: block;
    margin: 0 auto;
    width: fit-content; }
  .btn_icon {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    padding: 0; }
    .btn_icon i {
      font-size: 25px; }
  .btn_icon-before {
    flex-direction: row-reverse;
    font-weight: 600; }
    .btn_icon-before:before {
      color: #009999; }

.dropdown {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 30px;
  left: 0;
  min-width: 140px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  transition: all 0.4s ease-in-out;
  z-index: 100; }
  @media only screen and (min-width: 768px) {
    .dropdown {
      top: 60px; } }
  .dropdown__item {
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 15px; }
    .dropdown__item:last-child {
      margin-bottom: 0; }
    .dropdown__item a {
      color: #4b4b4b;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.3s ease; }
      .dropdown__item a:hover {
        border-bottom-color: #009999; }
  .dropdown:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid white;
    top: -9px;
    left: 12px; }

.nav {
  width: 100%; }
  .nav__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .nav__list {
        flex-direction: row;
        justify-content: flex-end; } }
  .nav__item {
    border: 2px solid transparent;
    position: relative;
    margin-bottom: 5px;
    padding: 0;
    cursor: pointer; }
    .nav__item.has-messages span {
      position: relative; }
      .nav__item.has-messages span:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #099;
        top: 0;
        right: -8px; }
        @media only screen and (min-width: 768px) {
          .nav__item.has-messages span:before {
            width: 10px;
            height: 10px; } }
    @media only screen and (min-width: 768px) {
      .nav__item {
        padding: 20px 0;
        margin-bottom: 0; } }
    .nav__item > a {
      color: #4b4b4b;
      font-size: 15px;
      font-weight: 700;
      line-height: 25px;
      transition: color 0.3s ease; }
      @media only screen and (min-width: 768px) {
        .nav__item > a {
          font-size: 18px; } }
    .nav__item:hover > a {
      color: #009999; }
    .nav__item_sublist > a {
      color: #009999;
      position: relative; }
      .nav__item_sublist > a:after {
        position: absolute;
        content: '';
        right: -17px;
        top: 4px;
        border: solid #009999;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        transition: transform 0.3s ease; }
    .nav__item_sublist:hover a:after {
      transform: rotate(-135deg);
      top: 6px; }
    .nav__item_sublist:hover .dropdown {
      opacity: 1;
      visibility: visible;
      pointer-events: all; }
    @media only screen and (min-width: 768px) {
      .nav__item_sublist > a:after {
        top: 7px; }
      .nav__item_sublist:hover a:after {
        top: 9px; } }
    @media only screen and (min-width: 768px) {
      .nav__item_button {
        padding: 10px 30px;
        border-color: #009999;
        transition: background-color 0.3s ease;
        border-radius: 6px; }
        .nav__item_button:hover {
          background-color: #009999; }
          .nav__item_button:hover a {
            color: white; }
        .nav__item_button a {
          color: #009999;
          transition: color 0.3s ease; } }

.user {
  position: relative;
  display: block; }
  .user .dropdown {
    display: none;
    top: 105%;
    left: auto;
    right: 0; }
    .user .dropdown:before {
      right: 12px;
      left: auto; }
    @media only screen and (min-width: 768px) {
      .user .dropdown {
        display: block; } }
  .user:hover .dropdown {
    pointer-events: all;
    opacity: 1;
    visibility: visible; }
  @media only screen and (min-width: 768px) {
    .user.has-messages:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #099;
      top: -1px;
      right: 2px; } }
  .user__image {
    display: block;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none; }
    .user__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .user__no-image {
    width: 100%;
    height: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4b4b4b;
    text-align: center;
    border: 1px solid #009999;
    border-radius: 50%; }
    .user__no-image i {
      max-height: 25px; }

.burger {
  width: 50px;
  height: 30px;
  position: relative;
  cursor: pointer;
  z-index: 51; }
  @media only screen and (min-width: 768px) {
    .burger {
      display: none; } }
  .burger span {
    position: absolute;
    left: 50%;
    width: 23px;
    height: 2px;
    background-color: #009999;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out; }
  .burger:before, .burger:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 23px;
    height: 2px;
    background-color: #009999;
    transform: translate(-50%, -50%) rotate(0); }
  .burger:before {
    top: 8px; }
  .burger:after {
    bottom: 6px; }
  .burger.is-open span {
    opacity: 0; }
  .burger.is-open:before {
    top: 50%;
    transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
    transform: translate(-50%, -50%) rotate(45deg); }
  .burger.is-open:after {
    top: 50%;
    transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
    transform: translate(-50%, -50%) rotate(-45deg); }

.breadcrumbs {
  padding: 7px 0; }
  .breadcrumbs__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .breadcrumbs__list li {
      position: relative; }
      .breadcrumbs__list li a,
      .breadcrumbs__list li span {
        color: #e5e5e5;
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 500; }
    .breadcrumbs__list li + li {
      margin-left: 4px;
      padding-left: 20px; }
      .breadcrumbs__list li + li:before {
        content: '';
        border: solid #e5e5e5;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        transform: rotate(-45deg);
        top: 6px;
        left: 3px; }

.modal {
  max-width: 520px;
  margin: 50px auto;
  background: #FFFFFF;
  border-radius: 0; }
  @media only screen and (min-width: 768px) {
    .modal {
      border-radius: 20px;
      box-shadow: 0 2px 40px rgba(23, 128, 224, 0.1); } }
  .modal_lg {
    max-width: 700px;
    margin-top: 70px; }
    @media only screen and (min-width: 768px) {
      .modal_lg {
        margin-top: 90px; } }
    @media only screen and (min-width: 768px) {
      .modal_lg .modal__content {
        padding: 0 51px 51px; } }
  .modal__content {
    padding: 0 11px 31px;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .modal__content {
        padding: 0 41px 51px; } }
  .modal__form {
    padding-top: 31px; }
    @media only screen and (min-width: 768px) {
      .modal__form {
        padding-top: 41px; } }

.tabs_main {
  display: flex;
  justify-content: center;
  align-items: center; }
  .tabs_main .tab {
    background-color: #e5e5e5;
    flex: 50%;
    text-align: center;
    color: #B2B0B0;
    cursor: pointer;
    text-decoration: none;
    padding: 7px;
    position: relative;
    transition: color .2s ease; }
    @media only screen and (min-width: 768px) {
      .tabs_main .tab {
        padding: 11px 20px; }
        .tabs_main .tab:first-child {
          border-radius: 20px 0; }
        .tabs_main .tab:last-child {
          border-radius: 0 20px; } }
    .tabs_main .tab:hover {
      color: #4b4b4b; }
    .tabs_main .tab span {
      position: relative;
      padding: 7px;
      font-weight: bold;
      font-size: 18px;
      line-height: 11px; }
      @media only screen and (min-width: 768px) {
        .tabs_main .tab span {
          padding: 20px;
          font-size: 30px;
          line-height: 41px; } }
    .tabs_main .tab.is-active {
      background-color: white; }
      .tabs_main .tab.is-active span {
        color: #4b4b4b; }

.tab-content {
  display: none; }
  .tab-content.is-active {
    display: block; }

.card {
  background-color: white;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 13px 7px;
  text-align: center;
  margin-top: 30px;
  z-index: 2;
  position: relative; }
  .card__title {
    font-weight: 700; }
  .card i {
    color: #009999; }
  .card__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px; }
  @media only screen and (min-width: 768px) {
    .card {
      padding: 23px 13px;
      margin-top: 60px; } }
  .card_big {
    padding: 30px 20px;
    min-height: 280px; }
    .card_big i {
      font-size: 35px; }
    .card_big i {
      font-size: 35px; }

.error-msg {
  padding: 7px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .error-msg__text {
    font-weight: 600;
    font-size: 20px;
    color: #ff0000;
    line-height: 27px;
    margin-right: 10px; }
  .error-msg i {
    color: #ff0000;
    font-size: 20px; }

.notification__wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; }

.notification__modal {
  color: white;
  font-size: 10px;
  min-height: 40px;
  background: #009999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 100;
  padding: 7px 20px;
  margin-bottom: 15px;
  transition: 0.3s; }
  @media only screen and (min-width: 576px) {
    .notification__modal {
      font-size: 12px; } }
  @media only screen and (min-width: 768px) {
    .notification__modal {
      max-width: 720px;
      width: 720px;
      font-size: 18px; } }
  .notification__modal.error,
  .notification__modal.error .info-modal {
    background-color: #ff0000;
    color: white; }
  .notification__modal.info,
  .notification__modal.info .info-modal {
    background-color: #fcf8e3;
    color: #4b4b4b; }
    .notification__modal.info .info-modal__close:before, .notification__modal.info .info-modal__close:after,
    .notification__modal.info .info-modal .info-modal__close:before,
    .notification__modal.info .info-modal .info-modal__close:after {
      background-color: #4b4b4b; }

.steps {
  margin-bottom: 50px; }

.step {
  width: 100%;
  padding: 12px;
  background-color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  margin-bottom: 25px; }
  @media only screen and (min-width: 992px) {
    .step {
      margin-bottom: 0; } }
  .step_md {
    padding-bottom: 90px; }
  .step_lg {
    padding-bottom: 20px; }
  .step__top {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background: linear-gradient(197.87deg, #99CCCC 40.5%, #009999 96.44%);
    text-align: center;
    position: relative;
    margin: -95px auto 0; }
  .step__icon {
    position: absolute;
    bottom: 25px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%); }
    .step__icon i {
      font-size: 52px;
      color: white; }
  .step__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    color: #4b4b4b;
    margin: 10px 0; }
  .step__description {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px; }
  .step__bg {
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .step__bg {
        background-image: url(../img/bgicon.svg);
        min-height: 170px;
        min-width: 185px;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 25px;
        display: flex;
        position: relative; } }
    .step__bg span {
      color: #009999;
      font-size: 30px;
      line-height: 46px;
      font-weight: 700;
      position: static; }
      @media only screen and (min-width: 992px) {
        .step__bg span {
          position: absolute;
          top: 30px; } }

.recaptcha {
  display: flex;
  margin: 15px 0;
  overflow: hidden;
  justify-content: center; }
  .recaptcha__absolute {
    position: absolute; }

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 159;
  overflow-y: auto;
  background-color: rgba(74, 74, 74, 0.45); }
  .popup__head {
    max-width: calc(100% - 30px);
    display: flex;
    align-items: center; }
    .popup__head_full {
      width: 100%;
      max-width: 100%; }
    @media only screen and (min-width: 768px) {
      .popup__head {
        margin-bottom: 16px; } }
    .popup__head h3 {
      text-align: center;
      width: 100%; }
  .popup__icon {
    margin-right: 6px; }
  .popup__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    text-align: center; }
  .popup__textual {
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px; }
  .popup__sharing {
    margin-top: 25px; }
    @media only screen and (min-width: 992px) {
      .popup__sharing {
        margin-bottom: 20px; } }
    .popup__sharing .sharing {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap; }
      @media only screen and (min-width: 768px) {
        .popup__sharing .sharing {
          flex-direction: row; } }
      .popup__sharing .sharing a {
        width: 35%;
        margin: 0 5px 15px;
        text-align: center; }
        @media only screen and (min-width: 992px) {
          .popup__sharing .sharing a {
            width: 22%;
            margin-bottom: 0; } }
      .popup__sharing .sharing__icon {
        background-color: #009999;
        width: 40px;
        height: 40px;
        max-width: 40px;
        text-align: center;
        line-height: 46px;
        color: white;
        border-radius: 3px;
        margin: 0 auto 5px; }
        .popup__sharing .sharing__icon i {
          font-size: 20px; }
      .popup__sharing .sharing__name {
        font-size: 16px;
        color: #4b4b4b; }
  .popup__overlay {
    height: 100vh;
    position: fixed;
    width: 100%; }
  .popup__content {
    left: 50%;
    top: 53%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 83.33333%;
    background-color: white;
    margin: 0;
    padding: 15px;
    border-radius: 6px; }
    @media only screen and (min-width: 768px) {
      .popup__content {
        padding: 30px; } }
    .popup__content_main {
      max-width: 820px;
      width: 60.29412%; }
    .popup__content_md {
      max-width: 600px;
      padding-bottom: 25px; }
    .popup__content_sm {
      width: 90%;
      max-width: 425px; }
      @media only screen and (min-width: 768px) {
        .popup__content_sm {
          width: 60.29412%; } }
    .popup__content_pt-md {
      padding-top: 55px; }
  .popup__close {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
    font-size: 14px;
    z-index: 1200;
    width: 20px;
    height: 20px; }
    .popup__close:before, .popup__close:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 2px;
      background-color: black; }
    .popup__close:before {
      transform: rotate(45deg); }
    .popup__close:after {
      transform: rotate(-45deg); }
    .popup__close_light:before, .popup__close_light:after {
      background-color: #9b9b9b; }
    .popup__close_circle {
      background-color: #009999;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      top: -15px;
      right: -15px; }
      .popup__close_circle:before, .popup__close_circle:after {
        background-color: white;
        width: 10px;
        left: 50%;
        top: 50%; }
      .popup__close_circle:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .popup__close_circle:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .popup__close_big {
      width: 60px;
      height: 60px; }
    .popup__close.is-hidden {
      display: none; }
  .popup__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px; }
  .popup__cancel-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    flex-direction: column; }
    @media only screen and (min-width: 768px) {
      .popup__cancel-btns {
        flex-direction: row; } }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .popup__cancel-btns {
        margin-top: 80px;
        margin-left: -20px; } }
    .popup__cancel-btns .btn {
      padding: 7px 17px;
      margin-bottom: 5px;
      margin-right: 0; }
      @media only screen and (min-width: 768px) {
        .popup__cancel-btns .btn:not(:last-child) {
          margin-right: 8px; } }

@media only screen and (min-width: 768px) and (-ms-high-contrast: active), only screen and (min-width: 768px) and (-ms-high-contrast: none) {
  .popup__cancel-btns .btn:last-child {
    line-height: 50px; } }
    .popup__cancel-btns__aprove {
      margin-top: 25px; }
      .popup__cancel-btns__aprove .btn:not(:last-child) {
        margin-bottom: 10px; }
  .popup_video .popup__content {
    width: 100%;
    height: 125px;
    border-radius: 0;
    top: 40%;
    padding: 0;
    margin-top: 100px; }
    @media only screen and (min-width: 576px) {
      .popup_video .popup__content {
        width: 400px;
        height: 223px; } }
    @media only screen and (min-width: 992px) {
      .popup_video .popup__content {
        width: 700px;
        height: 395px; } }
  .popup_video .popup__close {
    top: -2px;
    right: -25px; }
  .popup_video_tooltip {
    z-index: 151; }
  .popup_video_conf {
    background-color: transparent;
    overflow: visible;
    max-width: 450px;
    min-height: 275px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%); }
  .popup_video_conf-md {
    max-width: 480px; }
  .popup_video_steps {
    background-color: transparent;
    overflow: visible;
    max-width: 725px;
    min-height: 305px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%); }
  .popup_video_disabled {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100vh; }
  .popup_video_sm {
    z-index: 151; }
  .popup_video.is-visible {
    display: block; }
    .popup_video.is-visible .booking-request {
      width: 100%;
      margin: 0; }
      .popup_video.is-visible .booking-request__head {
        width: calc(100% + 50px);
        margin-left: -25px;
        padding-left: 25px;
        padding-right: 25px; }
      .popup_video.is-visible .booking-request__container {
        background: #fff; }
      .popup_video.is-visible .booking-request__content {
        background: #fff;
        display: block;
        border-top: none;
        padding-bottom: 140px; }
      .popup_video.is-visible .booking-request__rows {
        border-top: none; }
      .popup_video.is-visible .booking-request__row {
        margin: 0;
        display: block; }
      .popup_video.is-visible .booking-request__time-link {
        color: black; }
      .popup_video.is-visible .booking-request__btn .btn {
        font-size: 18px;
        min-height: 45px; }
      .popup_video.is-visible .booking-request .select {
        margin: 0 0 20px; }
        .popup_video.is-visible .booking-request .select__title {
          color: black;
          margin-bottom: 5px; }
      .popup_video.is-visible .booking-request .cost-summ__title {
        margin-bottom: 15px; }
      .popup_video.is-visible .booking-request .cost-summ__row {
        margin-bottom: 15px; }
  .popup_video_send-message .user-description__title {
    margin-bottom: 0; }
  .popup_video_dark-bg {
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden; }
  .popup_video__form {
    margin-top: 15px;
    margin-bottom: 15px; }
    .popup_video__form-button {
      position: relative;
      left: 50%;
      transform: translateX(-50%); }
  .popup_video__field_full {
    width: 100%; }
  .popup_video__field_half + .popup_video__field_half {
    margin-left: 2%; }
  .popup_video__field_half {
    width: 49%;
    float: left; }
  .popup_video__scrolable {
    max-height: 50vh;
    overflow: auto; }
    .popup_video__scrolable::-webkit-scrollbar {
      width: 10px; }
    .popup_video__scrolable::-webkit-scrollbar-thumb {
      width: 10px;
      border-radius: 10px;
      background-color: #33669a; }
    .popup_video__scrolable::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(51, 102, 154, 0.5);
      border-radius: 10px; }

.popup-step__title {
  padding-bottom: 20px;
  border-bottom: 1px solid #979797;
  font-size: 16px;
  color: black;
  letter-spacing: 1.1px;
  padding-bottom: 20px; }

.popup-step__text {
  padding-top: 40px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1.1px;
  color: black; }

.popup-step__pricing {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 20px; }

.popup-step__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px; }
  .popup-step__row:last-child p {
    font-weight: 700; }
  .popup-step__row p:first-child {
    margin-right: 10px; }
  .popup-step__row_border {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
    padding-bottom: 15px; }

.popup-step__btns {
  margin-top: 70px;
  width: 100%; }

.videos {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  text-align: center;
  margin-top: 40px; }

.video {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  float: left; }
  .video:before {
    content: '';
    display: block;
    margin-top: 56.25%; }
  @media only screen and (min-width: 992px) {
    .video {
      margin-bottom: 0; } }
  .video__title {
    margin-top: 15px;
    color: white;
    text-align: center; }
  .video__bg, .video iframe {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .video__btn {
    display: block;
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%); }
    .video__btn img {
      width: 100%;
      height: 100%; }
    .video__btn_main {
      width: 120px;
      height: 120px;
      top: 34%; }
    .video__btn_white {
      width: 58px;
      height: 58px;
      top: 37%; }

.slider-slide {
  text-decoration: none;
  color: #4b4b4b; }

.slider_primary .slick-slide {
  max-height: 480px;
  height: 480px;
  border-radius: 6px; }
  .slider_primary .slick-slide > div {
    max-height: inherit;
    height: inherit; }
    .slider_primary .slick-slide > div img {
      width: 100%;
      height: 100%;
      max-height: inherit;
      object-fit: cover;
      border-radius: 6px; }

.slider_thumb .slick-list {
  max-width: 90%;
  margin: 33px auto 0; }

.slider_thumb .slick-slide {
  max-height: 95px;
  height: 95px;
  border-radius: 6px;
  margin: 0 3px; }
  .slider_thumb .slick-slide > div {
    max-height: inherit;
    height: inherit;
    cursor: pointer; }
    .slider_thumb .slick-slide > div img {
      width: 100%;
      height: 100%;
      max-height: inherit;
      object-fit: cover;
      border-radius: 6px; }

.slider_thumb .next, .slider_thumb .prev {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #009999;
  line-height: 33px;
  top: 35%;
  position: absolute;
  cursor: pointer; }
  .slider_thumb .next svg, .slider_thumb .prev svg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 15px;
    stroke: none;
    top: 50%; }

.slider_thumb .next {
  right: -5px; }
  @media only screen and (min-width: 1200px) {
    .slider_thumb .next {
      right: 0; } }

.slider_thumb .prev {
  left: -2px; }
  @media only screen and (min-width: 1200px) {
    .slider_thumb .prev {
      left: 3px; } }
  .slider_thumb .prev svg {
    transform: translate(-50%, -50%) scale(-1, 1); }

.slider__social-btns {
  position: absolute;
  top: 33px;
  right: 33px;
  z-index: 25; }

.slider_common {
  max-width: 1255px;
  margin: 0 auto; }
  .slider_common a.slider-slide {
    display: block; }

.slider_dots .slick-dots {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 35px; }
  .slider_dots .slick-dots li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 4px;
    position: relative;
    background-color: transparent;
    border: 1px solid #009999;
    border-radius: 50%; }
    .slider_dots .slick-dots li:before, .slider_dots .slick-dots li:after {
      position: absolute;
      content: ' ';
      height: 12px;
      width: 4px;
      top: 0;
      background-color: transparent; }
    .slider_dots .slick-dots li:before {
      left: -4px; }
    .slider_dots .slick-dots li:after {
      right: -4px; }
  .slider_dots .slick-dots button {
    font-size: 0; }
  .slider_dots .slick-dots .slick-active {
    background-color: #009999; }

.slider_dots-white .slick-dots li {
  border: 1px solid white; }

.slider_dots-white .slick-dots .slick-active {
  background-color: white; }

.slider_full .slider__inner {
  width: 100%; }

.slider_full .slider-slide {
  width: 100%;
  height: 70vh; }
  .slider_full .slider-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .slider_full .slider-slide:not(.slick-initialized):not(:first-child) {
    display: none; }

.slider.is-hidden-before-init .residence-card__image:not(:first-child) {
  display: none; }

.slider.is-hidden-before-init.slick-initialized .residence-card__image {
  display: block; }

.slider-arrows .prev, .slider-arrows .next {
  position: absolute;
  bottom: -50px;
  z-index: 15;
  background-color: transparent;
  width: 30px;
  height: 40px;
  font-size: 0;
  cursor: pointer;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 992px) {
    .slider-arrows .prev, .slider-arrows .next {
      top: 35%; } }
  .slider-arrows .prev svg, .slider-arrows .next svg {
    display: block;
    width: 100%;
    max-width: 20px;
    max-height: 40px;
    height: 100%;
    fill: #009999;
    stroke: #009999;
    stroke-width: 1px; }

.slider-arrows .prev {
  left: 40%;
  transform: translate3d(0, 0, 0) translateY(-50%); }
  @media only screen and (min-width: 992px) {
    .slider-arrows .prev {
      left: -15px; } }

.slider-arrows .next {
  right: 40%;
  transform: translate3d(0, 0, 0) translateY(-50%) scale(-1, 1); }
  @media only screen and (min-width: 992px) {
    .slider-arrows .next {
      right: -15px; } }

.slider-arrows_full .prev {
  left: 0; }

.slider-arrows_full .next {
  right: 0; }

.slider-arrows_full .prev, .slider-arrows_full .next {
  width: 210px;
  padding-left: 85px;
  padding-right: 85px;
  height: 100%; }
  .slider-arrows_full .prev svg, .slider-arrows_full .next svg {
    max-width: 33px;
    max-height: 45px; }

.slider-arrows_popup .prev, .slider-arrows_popup .next {
  width: 210px;
  padding-left: 85px;
  padding-right: 85px;
  height: 80%; }
  .slider-arrows_popup .prev svg, .slider-arrows_popup .next svg {
    max-width: 45px;
    max-height: 70px; }

.slider-popup_main {
  margin-bottom: 35px; }
  .slider-popup_main .slick-track {
    padding-top: 30px; }
  .slider-popup_main + .btn {
    display: none; }

.slider-popup_thumbnails {
  max-width: 1220px;
  margin: 0 auto; }
  .slider-popup_thumbnails img {
    border: 2px solid transparent; }
  .slider-popup_thumbnails .slick-slide {
    margin: 0 5px; }

.slider-counter {
  text-align: center;
  color: white;
  font-size: 16px;
  display: none;
  line-height: 1.2;
  letter-spacing: 1.2px;
  position: absolute;
  width: 100%; }

.popup-slide {
  text-align: center; }
  .popup-slide__inner {
    max-width: 76vw;
    height: 614px;
    margin: 0 auto;
    position: relative; }
    .popup-slide__inner img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }

.slide-thumb {
  text-align: center;
  cursor: pointer; }
  .slide-thumb__inner {
    max-width: 268px;
    height: 168px; }
    .slide-thumb__inner img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.dropdown-menu {
  width: 100%;
  background-color: white;
  text-align: center;
  overflow: hidden;
  display: none;
  border-radius: 4px;
  position: relative;
  z-index: 48; }
  .dropdown-menu__wrapper {
    width: 100%;
    max-height: 240px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0; }
  .dropdown-menu li {
    padding: 0 25px; }
    .dropdown-menu li:hover {
      background-color: #eaeaea; }
    .dropdown-menu li a {
      padding: 6px 0;
      color: #009999;
      display: block;
      width: 100%;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: -0.12px; }

.dropdown-menu__wrapper::-webkit-scrollbar {
  width: 10px; }

.dropdown-menu__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.dropdown-menu__wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9c9c9c; }

.rating {
  font-size: 0;
  width: auto;
  position: relative;
  overflow: hidden; }
  .rating__star {
    display: inline-block;
    width: 19px;
    height: 19px;
    padding-right: 3px; }
    .rating__star svg {
      display: block;
      fill: #e5e5e5;
      width: 19px;
      height: 19px; }
    .rating__star.is-full svg {
      fill: #ff9500; }

@media only screen and (min-width: 992px) {
  .residence-cards {
    margin-right: -17px; } }

.residence-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: left;
  box-shadow: none;
  transform: translateY(0);
  transition: box-shadow .35s ease;
  width: 100%;
  border-radius: 6px;
  background-color: white; }
  @media only screen and (min-width: 992px) {
    .residence-card {
      width: calc(100%/3 - 17px);
      margin-right: 17px; }
      .residence-card:nth-child(3n+3) {
        margin-right: 0; } }
  .residence-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }
  .residence-card__title {
    margin-bottom: 5px;
    font-size: 16px; }
    .residence-card__title a {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #4b4b4b; }
  .residence-card__inner {
    text-decoration: none;
    display: block;
    overflow: hidden;
    position: relative; }
  .residence-card__discount {
    width: 220px;
    height: 50px;
    position: absolute;
    top: 14px;
    left: -69px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #009999;
    font-size: 16px;
    font-weight: 700;
    color: white;
    line-height: 1;
    transform: rotate(-45deg);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5); }
  .residence-card__images {
    width: 100%;
    position: relative;
    border-radius: 6px 6px 0 0; }
  .residence-card__image {
    width: 100%;
    height: 235px;
    position: relative;
    border-radius: 6px 6px 0 0; }
    .residence-card__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0; }
  .residence-card__description {
    background-color: white;
    padding: 11px 17px 17px;
    border-radius: 0 0 6px 6px; }
  .residence-card__price {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #33669a; }
  .residence-card__subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
    color: #999; }
  .residence-card__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .residence-card__like-btn {
    position: absolute;
    display: block;
    top: 12px;
    right: 13px;
    font-size: 17px;
    z-index: 15;
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px; }
  .residence-card .slider-arrows .next, .residence-card .slider-arrows .prev {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #009999;
    line-height: 33px;
    top: 35%; }
    .residence-card .slider-arrows .next svg, .residence-card .slider-arrows .prev svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-height: 15px;
      stroke: none; }
  .residence-card .slider-arrows .next {
    right: 10px;
    transform: translate3d(0, 0, 0) translateY(-50%); }
  .residence-card .slider-arrows .prev {
    left: 10px;
    transform: translate3d(0, 0, 0) translateY(-50%) scale(-1, 1); }

.infoBox {
  min-width: 250px;
  width: 250px;
  margin-left: 25px;
  border-radius: 6px; }
  @media only screen and (min-width: 992px) {
    .infoBox {
      min-width: 317px;
      width: 317px; } }
  .infoBox .residence-card__image, .infoBox .search-map_half-map .residence-card__image {
    height: 206px !important; }
  .infoBox > img {
    position: absolute !important;
    right: -18px;
    top: -18px;
    z-index: 1000; }

.pagination__numb-wrap {
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination__amount {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.88px;
  color: #4b4b4b;
  margin-top: 20px;
  text-align: center; }

.pagination__numb {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer; }
  @media only screen and (min-width: 768px) {
    .pagination__numb {
      height: 48px;
      width: 48px; } }
  .pagination__numb a {
    color: #4b4b4b;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center; }
  .pagination__numb.is-active {
    border: 1px solid #009999;
    background-color: #009999;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center; }
    .pagination__numb.is-active a {
      color: white; }

.pagination .gap {
  font-size: 24px;
  margin: 0 12px; }

.pagination__left, .pagination__right {
  position: relative;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  line-height: 35px;
  text-align: center; }
  .pagination__left a, .pagination__right a {
    display: block;
    height: 35px;
    background: transparent;
    position: relative;
    z-index: 2; }
  .pagination__left i, .pagination__right i {
    font-size: 10px;
    color: #4b4b4b;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    line-height: 35px;
    z-index: 1;
    top: 0; }
  @media only screen and (min-width: 768px) {
    .pagination__left, .pagination__right {
      height: 48px;
      width: 98px;
      line-height: 48px; }
      .pagination__left a, .pagination__right a {
        height: 48px; }
      .pagination__left i, .pagination__right i {
        font-size: 13px;
        line-height: 48px; } }

.pagination__left {
  margin-right: 5px; }
  .pagination__left i {
    transform: scale(-1, 1); }

.pagination__right {
  margin-left: 5px; }

.pagination_half .pagination__numb {
  width: 35px;
  height: 35px; }

.pagination_half.is-active {
  font-size: 16px; }

.pagination_half .gap {
  font-size: 18px; }

.pagination_half .pagination__left, .pagination_half .pagination__right {
  width: 35px;
  height: 35px;
  line-height: 35px; }
  .pagination_half .pagination__left a, .pagination_half .pagination__right a {
    height: 35px; }
  .pagination_half .pagination__left i, .pagination_half .pagination__right i {
    font-size: 10px;
    line-height: 35px; }

.datepicker-select {
  height: 38px;
  position: relative; }
  .datepicker-select:before {
    content: '';
    position: absolute;
    top: 33%;
    right: 12px;
    height: 7px;
    width: 7px;
    border-left: 2px solid #4b4b4b;
    border-bottom: 2px solid #4b4b4b;
    transform: rotate(-45deg) scale(1, 1) translate(0, 0);
    transform-origin: center;
    transition: .3s ease;
    z-index: 15; }
  .datepicker-select input::placeholder {
    color: #4b4b4b;
    font-size: 14px; }
  .datepicker-select__input {
    height: 100%;
    width: 100%;
    font-size: 13px;
    border: 1px solid #d8dde6;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 20px;
    white-space: nowrap;
    color: #4b4b4b;
    font-weight: 600;
    position: relative; }

.flatpickr-day.flatpickr-disabled.booked, .flatpickr-day.flatpickr-disabled:hover.booked {
  color: #4a4a4a;
  text-align: right;
  overflow: hidden; }
  .flatpickr-day.flatpickr-disabled.booked:before, .flatpickr-day.flatpickr-disabled:hover.booked:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #dcdcdc;
    width: 50px;
    height: 200px;
    transform: rotate(45deg);
    left: -25px;
    top: -65px; }
  .flatpickr-day.flatpickr-disabled.booked:after, .flatpickr-day.flatpickr-disabled:hover.booked:after {
    content: 'x';
    color: white;
    font-weight: 700;
    position: absolute;
    left: 14px;
    top: 10px;
    line-height: 1; }

.js-toggle-target {
  display: none;
  position: relative;
  overflow: hidden;
  max-height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: max-height .5s; }
  .js-toggle-target.is-open {
    max-height: 2500px;
    opacity: 1;
    visibility: visible;
    transition: 1.3s; }

.js-toggle:before, .js-toggle:after {
  transition: .5s; }

.js-toggle.is-open:before {
  transform: translateX(-4px) rotate(-40deg);
  transition: .5s; }

.js-toggle.is-open:after {
  transform: translateX(4px) rotate(40deg);
  transition: .5s; }

.js-toggle-expand {
  display: block;
  max-height: 90px;
  opacity: 1;
  visibility: visible; }
  .js-toggle-expand:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 30px;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%, white 100%); }
  .js-toggle-expand.is-open:after {
    height: 1px; }

.expander__head {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .expander__head .subtitle {
    margin-right: 20px;
    color: #4b4b4b;
    font-size: 20px;
    font-weight: 700; }

.expander__content {
  padding-top: 20px; }
  @media only screen and (min-width: 768px) {
    .expander__content {
      padding-top: 30px; } }

.expander__close-icon {
  width: 20px;
  height: 20px;
  position: relative;
  transform: scale(1, 1);
  transition: all .25s ease; }
  .expander__close-icon:before, .expander__close-icon:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 2px;
    width: 10px;
    background-color: #009999; }
  .expander__close-icon:before {
    transform: rotate(45deg);
    right: 6px; }
  .expander__close-icon:after {
    transform: rotate(-45deg); }

.expander.is-open .expander__close-icon {
  transform: scale(-1, -1);
  margin-left: 4px; }
  @media only screen and (min-width: 768px) {
    .expander.is-open .expander__close-icon {
      margin-left: -7px; } }

.expander h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px; }

.expander p {
  margin-bottom: 10px; }

.map-wrap {
  width: 100%;
  height: 100%; }
  .map-wrap__inner {
    width: 100%;
    height: 100vh; }

.map {
  width: 100%;
  height: 100%;
  position: relative; }
  .map__controls {
    position: absolute;
    top: 80px;
    left: 5px;
    height: 80px;
    width: 40px;
    background-color: #fff;
    z-index: 75;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #979797; }
    .map__controls:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
      height: 1px;
      margin: 0 auto;
      background-color: #dddddd; }
    .map__controls_top {
      top: 5px; }
  .map__zoom-in, .map__zoom-out {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer; }
    .map__zoom-in:before, .map__zoom-out:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background-color: #4a4a4a;
      width: 18px;
      height: 4px;
      margin: 0 auto; }
  .map__zoom-in {
    top: 0; }
    .map__zoom-in:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background-color: #4a4a4a;
      width: 18px;
      height: 4px;
      margin: 0 auto;
      transform: rotate(90deg); }
  .map__zoom-out {
    bottom: 0; }
  .map__fullscreen {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 4px;
    top: 2px;
    right: 2px;
    z-index: 75;
    cursor: pointer;
    display: block; }
    .map__fullscreen:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(../img/map-expand.svg);
      background-repeat: no-repeat;
      background-size: cover; }
    .map__fullscreen.is-open {
      display: none; }
  .map__fullscreen-close {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #777;
    border-radius: 50%;
    top: 2px;
    right: 2px;
    z-index: 75;
    cursor: pointer;
    display: none; }
    .map__fullscreen-close:before, .map__fullscreen-close:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      background-color: white;
      width: 10px;
      height: 2px; }
    .map__fullscreen-close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .map__fullscreen-close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .map__fullscreen-close.is-open {
      display: block; }
  .map__checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 75;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #4a4a4a; }
    .map__checkbox .checkbox .checkbox__wrapper {
      padding: 8px 15px; }
      .map__checkbox .checkbox .checkbox__wrapper input {
        width: 15px;
        height: 15px;
        left: 18px; }
      .map__checkbox .checkbox .checkbox__wrapper input:checked + .checkbox__ico:before {
        width: 3px; }
      .map__checkbox .checkbox .checkbox__wrapper input:checked + .checkbox__ico:after {
        width: 8px; }
    .map__checkbox .checkbox .checkbox__label {
      font-size: 16px; }
    .map__checkbox .checkbox .checkbox__ico {
      margin-right: 15px;
      width: 15px;
      height: 15px;
      min-width: 15px;
      border-radius: 0;
      border-color: #009999; }
      .map__checkbox .checkbox .checkbox__ico:before {
        bottom: 4px;
        left: 2px;
        border-color: #009999; }
      .map__checkbox .checkbox .checkbox__ico:after {
        transform: translate(4px, 8px) rotate(-45deg);
        border-color: #009999; }
  .map .is-hidden {
    display: none; }

.field {
  position: relative;
  margin-bottom: 15px; }
  .field .error-text {
    color: #ff0000;
    margin-top: 7px;
    font-size: 14px; }
  .field input, .field .input {
    background-color: white;
    width: 100%;
    height: 100%;
    color: #4b4b4b;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #d8dde6;
    appearance: none;
    border-radius: 4px;
    padding: 7px 15px;
    min-height: 36px; }
  .field input::placeholder {
    color: #979797;
    font-weight: 400;
    font-size: 15px; }
  .field_lg input {
    min-height: 48px; }
  .field__desc {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4b4b4b;
    margin: 5px 0; }
  .field__toggle-pass {
    min-height: 30px;
    line-height: 30px;
    padding: 0 10px;
    position: absolute;
    right: 6px;
    font-size: 15px;
    top: 50%;
    transform: translateY(-50%); }
  .field__label {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #4b4b4b;
    margin-bottom: 5px;
    display: inline-block; }
  .field__input {
    width: 100%;
    position: relative; }
  .field__flex {
    display: flex; }
  .field__error-message {
    color: #ff0000;
    font-size: 14px;
    margin: 4px 0 0;
    display: block; }
    @media only screen and (min-width: 992px) {
      .field__error-message {
        font-size: 16px; } }
  .field__dropdown {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    left: 0;
    width: 100%; }
  .field__link {
    color: #009999;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-left: auto; }
  .field__note {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    margin-top: 25px; }
    .field__note a {
      color: #009999; }
    @media only screen and (min-width: 768px) {
      .field__note {
        font-size: 16px; } }
  .field_full {
    width: 100%; }
  .field.is-error input {
    border-color: #ff0000; }
  .field_xs {
    max-width: 65px; }
  .field_sm {
    max-width: 200px; }
  @media only screen and (min-width: 768px) {
    .field_margin {
      margin-right: 20px; } }
  .field_gray input {
    min-height: 43px;
    background-color: #F1F4F9;
    border-radius: 8px;
    padding: 9px 15px; }
    .field_gray input.password {
      padding-right: 75px; }

.textarea {
  position: relative; }
  .textarea .error-text {
    color: #ff0000;
    margin-top: 4px; }
  .textarea textarea {
    width: 100%;
    min-height: 100px;
    resize: none;
    border-radius: 4px;
    border: 1px solid #d8dde6;
    padding: 7px 15px;
    background-color: white; }
  .textarea textarea::placeholder {
    color: #979797;
    font-weight: 400;
    font-size: 15px; }
  .textarea__counter {
    position: absolute;
    bottom: 7px;
    right: 7px;
    font-size: 12px;
    color: #e5e5e5;
    font-weight: 400; }
  .textarea__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 5px; }
    @media only screen and (min-width: 768px) {
      .textarea__label {
        font-size: 18px; } }

.custom-select {
  position: relative; }
  .custom-select select {
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .custom-select__text, .custom-select__input {
    height: 38px;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    line-height: 38px;
    color: #4b4b4b;
    vertical-align: middle;
    padding-left: 12px;
    padding-right: 25px;
    width: 180px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    white-space: nowrap;
    background-color: white;
    overflow: hidden; }
    .custom-select__text input, .custom-select__input input {
      font-size: 14px;
      color: #4b4b4b;
      width: 100%; }
    .custom-select__text input::placeholder, .custom-select__input input::placeholder {
      color: #4b4b4b; }
  .custom-select__field {
    position: relative;
    display: block;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer; }
  .custom-select__text {
    position: relative;
    text-overflow: ellipsis; }
    .custom-select__text:before {
      content: '';
      position: absolute;
      top: 33%;
      right: 17px;
      height: 7px;
      width: 7px;
      border-left: 2px solid #4b4b4b;
      border-bottom: 2px solid #4b4b4b;
      transform: rotate(-45deg) scale(1, 1) translate(0, 0);
      transform-origin: center;
      transition: .3s ease;
      z-index: 15; }
  .custom-select__icon {
    position: absolute; }
  .custom-select__dropdown {
    position: absolute;
    top: 140%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: .25s ease;
    background-color: white;
    z-index: 80;
    max-width: 175px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25); }
    .custom-select__dropdown:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid white;
      top: -9px;
      left: 12px; }
  .custom-select__list {
    text-align: left;
    overflow: hidden;
    overflow-y: auto;
    max-height: 260px; }
    .custom-select__list::-webkit-scrollbar {
      width: 5px; }
    .custom-select__list::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 153, 153, 0.4);
      border-radius: 10px;
      width: 5px; }
    .custom-select__list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 153, 153, 0.7); }
    .custom-select__list li {
      font-size: 16px;
      color: #4b4b4b;
      font-weight: 600;
      cursor: pointer;
      padding: 7px 11px; }
      .custom-select__list li:hover, .custom-select__list li:focus, .custom-select__list li:active, .custom-select__list li.is-active {
        background-color: #009999;
        color: white; }
  .custom-select_input .custom-select__input {
    padding-right: 25px; }
  .custom-select_input .field {
    margin-bottom: 0; }
  .custom-select.is-open .custom-select__dropdown {
    visibility: visible;
    opacity: 1; }
  .custom-select.is-open .custom-select__dropdown__text:before {
    transform: rotate(45deg) scale(1, -1) translate(3px, -3px); }

.select.is-error .custom-select__field {
  background-color: #fff8f9;
  border: 2px solid #ff0000;
  box-shadow: none;
  overflow: hidden; }

.select__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  margin-bottom: 5px; }

.select_top .custom-select__dropdown {
  bottom: 100%;
  top: auto; }

.select_lg .custom-select__text {
  width: 300px; }

.select_md .custom-select__text {
  width: 200px; }

.select_secondary .custom-select__text {
  padding-left: 25px;
  font-size: 16px;
  letter-spacing: 0.88px; }
  .select_secondary .custom-select__text:before {
    top: 21%;
    height: 15px;
    width: 15px;
    border-left: 2px solid #4b4b4b;
    border-bottom: 2px solid #4b4b4b; }

.select_secondary .custom-select__dropdown {
  padding-top: 4px; }

.select_secondary .custom-select__list {
  text-align: left; }
  .select_secondary .custom-select__list li {
    padding: 5px 20px;
    letter-spacing: 0.9px; }

.select_secondary .custom-select.is-open .custom-select__text:before {
  transform: rotate(45deg) scale(1, -1) translate(5px, -5px); }

.select_secondary_xs .custom-select__text {
  width: 80px;
  padding-left: 8px;
  font-size: 16px; }
  .select_secondary_xs .custom-select__text:before {
    right: 8px; }
  .select_secondary_xs .custom-select__text .select__title {
    margin-bottom: 12px;
    font-size: 16px; }

.select_secondary_xxs .custom-select__text {
  width: 85px;
  padding: 0 24px 0 8px; }

.select_secondary_width-md .custom-select__text {
  width: 173px; }

.select_secondary_full .custom-select__text {
  width: 100%; }

.select_secondary_full .custom-select__input {
  width: 100%; }

.select_secondary_mb {
  margin-bottom: 25px; }

.select_center-holder .custom-select__text {
  text-align: left; }

.select_center-holder_arrow-bg .custom-select__text:before {
  top: 26%;
  right: 12px;
  height: 14px;
  width: 14px;
  border-left: 4px solid #4a4a4a;
  border-bottom: 4px solid #4a4a4a; }

.select-column:not(:last-child) {
  margin-bottom: 25px; }

.select-column .select:not(:last-child) {
  margin-bottom: 15px; }

.select-tabs__title {
  color: #4b4b4b;
  letter-spacing: 1.71px; }

.select-tabs .custom-select {
  display: flex; }
  .select-tabs .custom-select__list {
    display: flex;
    align-items: flex-start;
    border: 1px solid #e5e5e5;
    background-color: white;
    overflow: hidden;
    width: 100%; }
    .select-tabs .custom-select__list li {
      background-color: white;
      font-size: 12px;
      color: #4b4b4b;
      letter-spacing: 0.81px;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      flex: 1; }
      .select-tabs .custom-select__list li:not(:last-of-type) {
        border-right: 1px solid #e5e5e5;
        border-bottom: none; }
      .select-tabs .custom-select__list li.is-active {
        background-color: #009999;
        color: white; }

.switch {
  font-size: 0;
  position: relative; }
  .switch__label {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: #4b4b4b;
    margin-right: 7px;
    cursor: pointer; }
  .switch__checkbox {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    width: 45px;
    height: 16px;
    position: relative;
    z-index: 2; }
  .switch__circle {
    width: 20px;
    height: 19px;
    position: absolute;
    left: -2px;
    top: -3px;
    border-radius: 50%;
    background: #d8dde6;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: translateX(0) translateZ(0); }
    .switch__circle:before, .switch__circle:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 1px;
      background-color: white; }
    .switch__circle:before {
      transform: translate(-50%, -50%) translateZ(0) rotate(55deg); }
    .switch__circle:after {
      transform: translate(-50%, -50%) translateZ(0) rotate(-55deg); }
  .switch input {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 45px;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer; }
  .switch input:checked + .switch__checkbox {
    background-color: transparent;
    border: 1px solid #009999; }
    .switch input:checked + .switch__checkbox .switch__circle {
      transform: translateX(130%) translateZ(0);
      background: #009999; }
      .switch input:checked + .switch__checkbox .switch__circle:before, .switch input:checked + .switch__checkbox .switch__circle:after {
        background-color: white; }
      .switch input:checked + .switch__checkbox .switch__circle:before {
        width: 4px;
        transform: translate(-5px, 3px) translateZ(0) rotate(40deg); }
      .switch input:checked + .switch__checkbox .switch__circle:after {
        width: 11px;
        transform: translate(-4px, 0) translateZ(0) rotate(-55deg); }
  .switch_link {
    display: block;
    position: relative;
    z-index: 12;
    text-decoration: none;
    color: #4b4b4b; }
  .switch_dib {
    display: inline-block; }
  .switch_animate .switch__circle {
    transition: all .25s ease; }
  .switch_animate .switch__checkbox {
    transition: all .25s ease; }
  .switch_label-right input {
    left: 0;
    right: auto; }
  .switch_label-right .switch__label {
    margin-right: 0;
    margin-left: 15px; }

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 4px; }
  @media only screen and (min-width: 992px) {
    .checkbox {
      padding: 10px; } }
  .checkbox_input {
    flex-wrap: wrap; }
    .checkbox_input input[type="checkbox"] {
      transform: translate(0, 0);
      top: 11px; }
  .checkbox__label {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.5;
    color: #4b4b4b;
    position: relative; }
    .checkbox__label br {
      content: '';
      display: block;
      margin-top: 10px; }
  .checkbox a {
    color: #009999; }
  .checkbox input {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    width: 20px;
    height: 20px;
    opacity: 0;
    top: 50%;
    left: 10px;
    transform: translateY(-50%); }
  .checkbox__input {
    width: 100%;
    margin-top: 10px; }
    .checkbox__input input {
      border: 1px solid #d8dde6;
      min-height: 41px;
      min-width: 210px;
      opacity: 1;
      margin-top: 30px;
      margin-left: 30px;
      padding: 0 10px;
      font-size: 16px;
      color: #4b4b4b;
      border-radius: 4px; }
      @media only screen and (min-width: 768px) {
        .checkbox__input input {
          min-width: 310px; } }
  .checkbox__ico {
    position: relative;
    display: block;
    margin-right: 11px; }
  .checkbox_common input:checked + .checkbox__ico {
    border-color: #009999;
    background-color: #009999; }
    .checkbox_common input:checked + .checkbox__ico:before {
      width: 7px;
      transition-delay: 0ms; }
    .checkbox_common input:checked + .checkbox__ico:after {
      width: 11px;
      transition-delay: 150ms; }
  .checkbox_common .checkbox__ico {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #4b4b4b; }
    .checkbox_common .checkbox__ico:before {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background: white;
      bottom: 9px;
      left: 2px;
      transform: rotate(45deg);
      transform-origin: left;
      transition: all 150ms;
      transition-delay: 150ms; }
    .checkbox_common .checkbox__ico:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background: white;
      transform: translate(7px, 11px) rotate(-45deg);
      transform-origin: left;
      transition: all 150ms;
      transition-delay: 0ms; }
  .checkbox_secondary {
    padding: 0; }
    .checkbox_secondary .checkbox__label {
      font-size: 15px; }
  .checkbox_primary .checkbox__label {
    font-size: 15px; }
    @media only screen and (min-width: 992px) {
      .checkbox_primary .checkbox__label {
        font-size: 18px; } }
  .checkbox_primary .checkbox__ico {
    margin-right: 10px; }
  .checkbox_primary__error-message {
    color: #ff0000;
    font-size: 16px;
    margin-top: 10px; }
  .checkbox.is-error {
    border: 1px solid #ff0000;
    padding: 10px;
    box-shadow: inset 0 0 1px #ff0000; }

.checkboxes-block__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .checkboxes-block__row .checkbox {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .checkboxes-block__row .checkbox {
        width: 50%; } }

.checkboxes-block_main {
  max-width: 1180px;
  margin: 0 auto 80px;
  padding-left: 5%; }

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: .2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: .2em;
    height: .5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg); } }

.error-checkbox {
  margin-left: 12px;
  margin-top: -4px; }
