.videos {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  text-align: center;
  margin-top: 40px; }

.video {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  float: left;

  &:before {
    content: '';
    display: block;
    margin-top: 56.25%; }

  @include lg {
    margin-bottom: 0; }

  &__title {
    margin-top: 15px;
    color: white;
    text-align: center; }

  &__bg, iframe {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

  &__btn {
    display: block;
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%);

    img {
      width: 100%;
      height: 100%; }

    &_main {
      width: 120px;
      height: 120px;
      top: 34%; }

    &_white {
      width: 58px;
      height: 58px;
      top: 37%; } } }
