.categories {
  &__icon {
    background-image: url(../img/bgicon_secondary.svg);
    width: 140px;
    height: 130px;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    line-height: 130px;
    margin: 0 auto;

    i {
      font-size: 40px; } }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 7px;

    @include md {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px; } }

  &__description {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 15px;

    @include md {
      margin-bottom: 20px; } }

  i {
    color: $green; } }


