.message {
  padding: 30px 0;

  .title {
    font-size: 24px;
    line-height: 1.4;
    margin: 15px 0;

    @include lg {
      font-size: 27px; } } }

.user-description {

  &__textarea {
    width: 100%;
    margin: 0 auto 30px;

    @include md {
      width: 90%; }

    @include lg {
      width: 70%; } }

  &__head {
    background-color: white;
    border-radius: 6px;
    padding: 17px 24px;
    width: 100%;
    margin: 16px auto 16px;

    @include md {
      width: 90%; }

    @include xl {
      width: 70%; } }

  &__image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; } }

  &__title {
    display: inline-block;
    vertical-align: middle; }

  &__list {
    margin-left: 80px;

    @include lg {
      margin-left: 110px; }

    li {
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      position: relative;
      margin-bottom: 3px;

      @include lg {
        font-size: 18px; }

      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $green; } } } }
