.contact-us-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;

  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 41px;
    font-weight: 600;
    margin: 0 auto 40px;
    width: 100%;

    @include md {
      width: 70%;
      margin-bottom: 80px;
      font-size: 30px; }

    a {
      color: $green;
      font-weight: 700; } }

  &__cols {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include lg {
      max-width: 66%;
      flex-direction: row;
      margin: 0 auto;

      &:before, &:after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        width: 115px;
        height: 115px;
        background-image: url(../img/secondary.svg);
        background-repeat: no-repeat;
        z-index: -1; }

      &:before {
        left: -10px; }

      &:after {
        right: -10px; } } }

  &__col {
    margin: 0 0 20px;
    flex: 1;
    background-color: white;
    padding: 20px;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;

    @include lg {
      max-width: 325px;
      margin: 0 20px 0; }

    i {
      font-size: 28px;
      color: $green;
      margin-right: 12px; }

    a {
      color: black;
      font-weight: 600;
      font-size: 18px;
      transition: color 0.3s ease;

      @include md {
        font-size: 22px; }

      &:hover {
        color: $green; } } } }
