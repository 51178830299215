.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 159;
  overflow-y: auto;
  background-color: rgba(74, 74, 74, 0.45);

  &__head {
    max-width: calc(100% - 30px);
    display: flex;
    align-items: center;

    &_full {
      width: 100%;
      max-width: 100%; }

    @include md {
      margin-bottom: 16px; }

    h3 {
      text-align: center;
      width: 100%; } }

  &__icon {
    margin-right: 6px; }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    text-align: center; }

  &__textual {
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px; }

  &__sharing {
    margin-top: 25px;

    @include lg {
      margin-bottom: 20px; }

    .sharing {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @include md {
        flex-direction: row; }

      a {
        width: 35%;
        margin: 0 5px 15px;
        text-align: center;

        @include lg {
          width: 22%;
          margin-bottom: 0; } }

      &__icon {
        background-color: $green;
        width: 40px;
        height: 40px;
        max-width: 40px;
        text-align: center;
        line-height: 46px;
        color: white;
        border-radius: 3px;
        margin: 0 auto 5px;

        i {
          font-size: 20px; } }

      &__name {
        font-size: 16px;
        color: $text-dark; } } }

  &__overlay {
    height: 100vh;
    position: fixed;
    width: 100%; }

  &__content {
    left: 50%;
    top: 53%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: percentage(1200px/1440px);
    background-color: white;
    margin: 0;
    padding: 15px;
    border-radius: 6px;

    @include md {
      padding: 30px; }

    &_main {
      max-width: 820px;
      width: percentage(820px/1360px); }

    &_md {
      max-width: 600px;
      padding-bottom: 25px; }

    &_sm {
      width: 90%;
      max-width: 425px;

      @include md {
        width: percentage(820px/1360px); } }

    &_pt-md {
      padding-top: 55px; } }

  &__close {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
    font-size: 14px;
    z-index: 1200;
    width: 20px;
    height: 20px;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 2px;
      background-color: black; }

    &:before {
      transform: rotate(45deg); }

    &:after {
      transform: rotate(-45deg); }

    &_light {
      &:before, &:after {
        background-color: #9b9b9b; } }

    &_circle {
      background-color: $green;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      top: -15px;
      right: -15px;

      &:before, &:after {
        background-color: white;
        width: 10px;
        left: 50%;
        top: 50%; }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); } }

    &_big {
      width: 60px;
      height: 60px; }

    &.is-hidden {
      display: none; } }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px; }

  &__cancel-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    flex-direction: column;

    @include md {
      flex-direction: row; }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      margin-top: 80px;
      margin-left: -20px; }

    .btn {
      padding: 7px 17px;
      margin-bottom: 5px;
      margin-right: 0;

      @include md {

        &:not(:last-child) {
          margin-right: 8px; }

        &:last-child {
          @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            line-height: 50px; } } } }

    &__aprove {
      margin-top: 25px;

      .btn {
        &:not(:last-child) {
          margin-bottom: 10px; } } } }

  &_video {
    .popup__content {
      width: 100%;
      height: 125px;
      border-radius: 0;
      top: 40%;
      padding: 0;
      margin-top: 100px;

      @include sm {
        width: 400px;
        height: 223px; }

      @include lg {
        width: 700px;
        height: 395px; } }

    .popup__close {
      top: -2px;
      right: -25px; }


    &_tooltip {
      z-index: 151; }

    &_conf {
      background-color: transparent;
      overflow: visible;
      max-width: 450px;
      min-height: 275px;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -35%); }

    &_conf-md {
      max-width: 480px; }


    &_steps {
      background-color: transparent;
      overflow: visible;
      max-width: 725px;
      min-height: 305px;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -35%); }


    &_disabled {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      height: 100vh; }

    &_sm {
      z-index: 151; }

    &.is-visible {
      display: block;

      .booking-request {
        width: 100%;
        margin: 0;

        &__head {
          width: calc(100% + 50px);
          margin-left: -25px;
          padding-left: 25px;
          padding-right: 25px; }

        &__container {
          background: #fff; }

        &__content {
          background: #fff;
          display: block;
          border-top: none;
          padding-bottom: 140px; }

        &__rows {
          border-top: none; }

        &__row {
          margin: 0;
          display: block; }

        &__time-link {
          color: black; }

        &__btn {
          .btn {
            font-size: 18px;
            min-height: 45px; } }

        .select {
          margin: 0 0 20px;

          &__title {
            color: black;
            margin-bottom: 5px; } }

        .cost-summ {
          &__title {
            margin-bottom: 15px; }

          &__row {
            margin-bottom: 15px; } } } }

    &_send-message {
      .user-description {
        &__title {
          margin-bottom: 0; } } }

    &_dark-bg {
      background-color: rgba(black, 0.6);
      overflow: hidden; }

    &__form {
      margin-top: 15px;
      margin-bottom: 15px;

      &-button {
        position: relative;
        left: 50%;
        transform: translateX(-50%); } }

    &__field {
      &_full {
        width: 100%; }

      &_half + &_half {
        margin-left: 2%; }

      &_half {
        width: 49%;
        float: left; } }

    &__scrolable {
      max-height: 50vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px; }

      &::-webkit-scrollbar-thumb {
        width: 10px;
        border-radius: 10px;
        background-color: #33669a; }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(51, 102, 154, .5);
        border-radius: 10px; } } } }

.popup-step {
  &__title {
    padding-bottom: 20px;
    border-bottom: 1px solid #979797;
    font-size: 16px;
    color: black;
    letter-spacing: 1.1px;
    padding-bottom: 20px; }

  &__text {
    padding-top: 40px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1.1px;
    color: black; }

  &__pricing {
    max-width: 350px;
    margin: 0 auto;
    margin-top: 20px; }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
      p {
        font-weight: 700; } }

    p:first-child {
      margin-right: 10px; }

    &_border {
      border-bottom: 1px solid black;
      margin-bottom: 15px;
      padding-bottom: 15px; } }

  &__btns {
    margin-top: 70px;
    width: 100%; } }
