.burger {
  width: 50px;
  height: 30px;
  position: relative;
  cursor: pointer;
  z-index: 51;

  @include md {
    display: none; }

  span {
    position: absolute;
    left: 50%;
    width: 23px;
    height: 2px;
    background-color: $green;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: $base-transition; }

  &:before, &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 23px;
    height: 2px;
    background-color: $green;
    transform: translate(-50%, -50%) rotate(0); }

  &:before {
    top: 8px; }

  &:after {
    bottom: 6px; }

  &.is-open {
    span {
      opacity: 0; }

    &:before {
      top: 50%;
      transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
      transform: translate(-50%, -50%) rotate(45deg); }

    &:after {
      top: 50%;
      transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
      transform: translate(-50%, -50%) rotate(-45deg); } } }
