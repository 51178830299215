.search-map {
  &__pagination {
    margin: 60px 0; }

  &_half-map {
    .container {
      max-width: none;
      padding: 0; }

    .search-map {
      &__container {
        display: flex;
        position: relative; }

      &__cards {
        display: none;

        @include lg {
          width: 50%;
          display: block;
          padding-left: 30px;
          padding-right: 10px;
          margin-right: auto;
          max-height: 100vh;
          overflow: auto;
          transition: all .25s ease; }

        &.is-open {
          width: 0;
          padding: 0; } }

      &__map-wrapper {
        width: 100%;
        padding: 0 15px;
        height: 100vh;
        transition: all .25s ease;

        @include lg {
          padding-left: 10px;
          padding-right: 30px;
          width: 50%;
          max-height: 100vh; }

        &.is-open {
          width: 100%;
          padding-left: 30px; } } }

    .residence-cards {
      margin-right: 0; }

    .residence-card {
      width: calc(50% - 14px);
      margin-right: 14px;
      max-width: none;

      &:nth-child(2n) {
        margin-right: 0; }

      &:nth-child(3n +3) {
        margin-right: 14px; } } } }

.search-map__cards::-webkit-scrollbar {
  width: 7px; }

.search-map__cards::-webkit-scrollbar-track {
  background-color: $gray-darker;
  border-radius: 10px; }

.search-map__cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $green; }
