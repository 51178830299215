.subscription-form {
  padding: 25px 0;
  position: relative;
  background-color: $primary;
  overflow: hidden;

  @include md {
    padding: 50px 0;

    &:before, &:after {
      content: '';
      position: absolute;
      height: 200px;
      min-width: 270px;
      width: 270px;
      background-image: url(../img/primary-desktop.svg);
      background-repeat: no-repeat;
      background-size: contain; }

    &:before {
      left: 0;
      bottom: -50px;
      transform: rotate(180deg); }

    &:after {
      right: 0;
      top: -50px; } }

  &__text {
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    color: white;
    margin-bottom: 10px; }

  p {
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 600; }

  &__inner {
    position: relative;
    z-index: 4; }

  .field {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include md {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }

    &__input {
      flex: 1;
      margin-bottom: 20px;

      @include md {
        margin-right: 20px;
        margin-bottom: 0; } } }

  .error-text {
    color: $red-light; } }
