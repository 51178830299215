// width
@mixin w-max($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin w-min($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

// height
@mixin h-max($height) {
  @media only screen and (max-height: $height + "px") {
    @content; } }

@mixin h-min($height) {
  @media only screen and (min-height: $height + "px") {
    @content; } }

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin hover {
  &:hover,
  &:focus,
  &:active {
    @content; } }

%pseudo {
  position: relative;
  &:before,&:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0; } }

@mixin pseudo {
  @extend %pseudo; }

@mixin clr {
  &:after {
    content: " ";
    display: table;
    clear: both; } }

//triangle
@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == r {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg; }
  @if $direction == b {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent; }
  @if $direction == l {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent; }
  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == bl {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin center($axis) {
  position: absolute;
  @if $axis == both {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @else if $axis == horizontal {
    left: 50%;
    transform: translateX(-50%); }
  @else if $axis == vertical {
    top: 50%;
    transform: translateY(-50%); } }

// font-family
// accepts 4 arguments
//  1. font name
//  2. font name + its weight in words
//  3. font weight in numbers depending on font parameters
//     300 - Light
//     400 - Regular
//     700 - Bold
//  4. enumeration of font extensions in parentheses
// Usage +font(OpenSans, OpenSansRegular, 400, (eot, ttf, woff))
@mixin font($font-family, $font-family-local, $font-weight: 400, $font-extensions: null, $font-style: normal) {
  @font-face {
    font-family: quote($font-family);

    @each $ext in $font-extensions {
      @if ($ext == 'eot') {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".eot");
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".eot?#iefix") format("embedded-opentype"); }
      @else if ($ext == 'woff') {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".woff") format("woff"); }
      @else if ($ext == 'woff2') {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".woff2") format("woff2"); }
      @else if ($ext == 'ttf') {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".ttf") format("truetype"); }
      @else if ($ext == 'svg') {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + ".svg#" + $font-family) format("svg"); }
      @else {
        src: local(quote($font-family-local)), url("../redesign-fonts/" + $font-family-local + "/" + $font-family-local + "." + $ext); } }
    font-style: $font-style;
    font-weight: $font-weight; } }
