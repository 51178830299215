.index {
  .header {
    @include md {
      background-color: transparent;
      box-shadow: none;

      .logo-subname {
        fill: white;
        stroke: white; }

      .logo-name {
        fill: white; }

      &__nav {
        background-color: transparent;

        .nav__item > a, .nav__item_sublist > a {
          color: white; }

        .nav__item_sublist > a:after {
          border-color: white; }

        .nav__item_button {
          border-color: white;

          &:hover {
            background: white;

            a {
              color: $text-dark; } } } }

      .user__no-image {
        border-color: white;
        color: white; }

      &.is-fixed {
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

        .logo-subname {
          fill: #1aa99e;
          stroke: #1aa99e; }

        .logo-name {
          fill: #1b75bc; }

        .nav__item > a {
          color: $text-dark; }

        .nav__item_sublist > a {
          color: $green; }

        .nav__item_sublist > a:after {
          border-color: $green; }

        .nav__item_button {
          border-color: $green;

          &:hover {
            background: $green;

            a {
              color: white; } } }

        .user__no-image {
          border-color: $green;
          color: $text-dark; } } } }

  .hero {
    margin-top: 0;
    padding: 0;

    @include lg {

      &__content {
        min-height: 100vh; }

      &__slide {
        min-height: 100vh; } } } }
