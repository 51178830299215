.error-msg {
  padding: 7px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-weight: 600;
    font-size: 20px;
    color: $red;
    line-height: 27px;
    margin-right: 10px; }

  i {
    color: $red;
    font-size: 20px; } }
