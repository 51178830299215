.dropdown-menu {
  width: 100%;
  background-color: white;
  text-align: center;
  overflow: hidden;
  display: none;
  border-radius: 4px;
  position: relative;
  z-index: 48;

  &__wrapper {
    width: 100%;
    max-height: 240px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0; }
  li {
    padding: 0 25px;

    &:hover {
      background-color: #eaeaea; }
    a {
      padding: 6px 0;
      color: $green;
      display: block;
      width: 100%;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: -0.12px; } } }

.dropdown-menu__wrapper::-webkit-scrollbar {
  width: 10px; }

.dropdown-menu__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  border-radius: 10px; }

.dropdown-menu__wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9c9c9c; }
