.booking {
  .custom-select__list {
    li {
      font-size: 14px; }

    .disabled {
      background-color: transparent;
      padding: 0;
      display: none; } }

  .custom-select__text, .custom-select__input {
    font-size: 13px;
    font-weight: 600; }

  .residence-card {
    min-height: 365px; }

  .slider_primary {
    width: calc(100% + 30px);
    margin-left: -15px;

    @include lg {
      width: 100%;
      margin-left: 0; }

    .slick-arrow {
      position: absolute;
      top: 40%;
      background-color: white;
      border-radius: 50%;
      text-align: center;
      line-height: 37px;
      width: 35px;
      height: 35px;
      z-index: 1;
      border: 1px solid $green; }

    .next {
      right: 10px; }

    .prev {
      left: 10px;
      transform: scale(-1,1); }

    .slick-slide {
      min-height: 350px;
      height: 350px;
      border-radius: 0;

      img {
        border-radius: 0; }

      @include lg {
        min-height: 480px;
        height: 480px;
        border-radius: 6px;

        img {
          border-radius: 6px; } } } }

  &__return-link {
    margin-bottom: 30px; }

  &__message {
    background-color: white;
    padding: 15px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 22px; }

  .card {
    margin-top: 0;
    min-height: fit-content;
    margin-bottom: 20px;
    padding: 15px;

    @include md {
      width: 70%;
      margin: 0 auto 20px; }

    @include lg {
      margin-bottom: 0;
      min-height: 180px;
      padding: 30px 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }

    i {
      font-size: 24px;
      margin-bottom: 16px;

      @include lg {
        font-size: 44px; } }

    &__title {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 5px;

      @include lg {
        font-size: 20px; } }

    &__info {
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;

      @include lg {
        font-size: 20px; } }

    .company-name {
      font-weight: 800;
      font-size: 16px;
      line-height: 21px;
      margin-top: 5px;

      @include lg {
        font-size: 22px; } } }

  &__content {
    padding-bottom: 30px;
    border-bottom: 1px solid #C7C7C7;
    margin-bottom: 30px;

    .residence-card {
      .slider-arrows {
        .prev {
          transform: translate3d(0,0,0) translateY(-50%); }

        .next {
          transform: translate3d(0,0,0) translateY(-50%) scale(-1, 1); } } } }

  &__content_border {
    border-bottom: none; }

  &__selects-range {
    &:not(.is-active) {
      display: none; } }

  &__map {
    height: 400px;

    .map-wrap__inner {
      height: 100%; } }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 16px; }

  p {
    font-size: 14px;

    @include lg {
      font-size: 16px; } }

  &__list {
    &_columns {
     display: flex;
     flex-direction: column;

     @include lg {
        flex-direction: row;
        justify-content: space-between; }

     li {
       width: 100%; } }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 15px;
      margin-left: 0;

      li {
        margin-right: 30px;
        position: relative;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 13px;

        &:after {
          content: '';
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $green; } } } }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px; }

  &__time-wrap {
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 20px;
    padding: 12px;
    text-align: center;
    margin-bottom: 20px;

    @include lg {
      margin-top: 30px; }

    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 15px; } }

  &__breadcrumbs {
    padding: 12px 0;
    margin-bottom: 8px;

    @include lg {
      border-bottom: 1px solid #8DBADD;
      padding: 18px 0;
      margin-bottom: 21px; }

    .breadcrumbs {
      padding: 0;
      width: calc(100% + 30px);
      margin-left: -15px;

      @include lg {
        width: 100%;
        margin-left: 0; }

      &__list {
        justify-content: flex-start;

        li {
          &:before {
            border-color: $text-dark;
            border-width: 0 1px 1px 0;
            top: 9px;
            padding: 2px; }

          a > span {
            color: $text-dark;
            font-weight: 500;
            font-size: 12px;
            line-height: 19px;

            @include lg {
              font-size: 14px; } } } } } }

  &__id {
    font-size: 14px;
    line-height: 27px;
    color: $text-dark;
    font-weight: 700;

    @include lg {
      font-size: 20px; }

    i {
      color: $green;
      margin-right: 2px;
      font-size: 18px;

      @include lg {
        font-size: 22px; } } }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__actions-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: absolute;
    top: 15px;
    right: 15px;
    width: fit-content;

    & > * {
      width: 30px;
      height: 30px;
      background-color: white;
      color: $text-dark;
      text-align: center;
      line-height: 30px;
      display: block;
      border-radius: 50%;
      margin-bottom: 10px; }

    i {
      vertical-align: middle;
      font-size: 15px; }

    @include lg {
      max-width: 117px;
      width: 117px;
      flex-direction: row;
      position: static;

      & > * {
        width: 51px;
        height: 51px;
        background-color: white;
        color: $text-dark;
        text-align: center;
        line-height: 51px;
        display: block;
        border-radius: 50%;
        margin-bottom: 0; }

      i {
        vertical-align: middle;
        font-size: 18px; } } }

  &__name {
    margin-bottom: 25px;

    @include lg {
      margin-bottom: 3px; }

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;

      @include lg {
        font-size: 36px;
        line-height: 49px; } } }

  &__subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px; } }

.booking-hero {
  margin: 0 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #C7C7C7;

  @include lg {
    margin-top: 30px; } }

.working-time {
  display: flex;
  font-size: 14px;
  margin-top: 20px;

  li {
    margin-bottom: 5px;
    line-height: 19px; }

  &__days {
    margin-right: 20px; } }

.booking-similar-slider {

  .slick-list {
    margin: 0 -10px; }

  .slick-slide {
    margin: 0 10px;

    .residence-card {
      margin-right: 0; } } }

.booking-request {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: 30px;

  @include md {
    width: 70%;
    margin: 30px auto 0; }

  @include lg {
    border-radius: 6px;
    width: 100%;
    margin-left: 0; }

  &_full {
    background: $primary;
    width: 100%;
    margin-left: 0;
    border: none;
    border-radius: 0;

    .cost-summ {
      padding: 12px 0 0;

      &__row {
        margin-bottom: 12px;

        p {
          font-size: 23px; } }

      &__title {
        font-size: 23px;
        margin-bottom: 12px;

        .subtitle {
          font-weight: inherit; } }

      &__total {
        &:before {
          top: -5px;
          width: 100%; } }

      p, h3 {
        color: #fff; } }

    .booking-request {
      &__container {
        padding: 0 165px;
        max-width: 1440px;
        margin: 0 auto; }

      &__head {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 0 6px; }

      &__col {
        &_top {
          position: static;
          top: auto;
          transform: none; } }

      &__content {
        background: $primary;
        border-radius: 0;
        padding: 70px 0 48px;
        min-height: auto;
        border-top: 1px solid #979797; }

      &__summary {
        margin: 0; }

      &__desc {
        font-size: 20px;
        max-width: 160px; }

      &__tariff,
      &__subtariff {
        margin-bottom: 8px; } } }

  .select {
      &__title {
        color: $text-dark;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px; } }

  .custom-select {
    &__field {
      background: #fff; }

    &__list, &__dropdown {
      width: 170px; } }

  &__head {
    color: white;
    position: relative;
    background: $primary;
    padding: 150px 45px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;

    @include lg {
      border-radius: 6px; }

    &:before {
      content: '';
      background-color: #79A8D7;
      height: 1px;
      width: 100%;
      left: 0;
      top: 60%;
      position: absolute; } }

  &__content {
    position: relative;
    background: white;
    padding: 17px 14px 70px;
    border-radius: 6px; }

  &__discount {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 46px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    font-size: 11px;
    font-weight: 700;
    color: $text-dark;
    letter-spacing: 1px;
    border-radius: 50%;
    border: 2px solid $green; }

  &__non-profit {
    text-align: center;
    font-size: 14px !important;
    margin: 15px 0 0 0;
    padding: 0 15px; }

  &__col {
    &_top {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translate(-50%,0); } }

  &__tariff,
  &__subtariff {
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 20px; }

  &__subtariff {
    font-size: 18px;
    font-style: italic;
    letter-spacing: 0; }

  &__desc {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;

    @include lg {
      font-size: 16px; }

    @include xl {
      font-size: 18px; }

    p {
      font-size: inherit;
      color: inherit;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0; } } }

  &__rating {
    .rating {
      text-align: center;

      svg {
        fill: white;
        opacity: 1; } } }

  &__row {
    display: flex;
    flex-direction: column;

    @include lg {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end; }

    .select {
      flex: 1;
      max-width: 100%;
      margin: 5px 3px;

      .datepicker-select_sm, .datepicker-select {
        width: 100%; }

      .custom-select__text, .custom-select__input {
        width: 100%; } } }

  &__timing {
    cursor: pointer;
    font-size: 16px; }

  &__summary {
    margin: 20px 0;
    display: none;

    &.is-shown {
      display: block; } }

  &__btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    .btn {
      min-width: 260px; } }

  &__time-link {
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px; }

  &__time-link_reset {
    margin-left: auto;
    margin-right: 0;
    color: $text-dark;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;

    i {
      font-size: 16px;
      color: $green;
      margin-right: 5px; } }

  &__invalid-code {
    color: white;
    background-color: $green;
    font-weight: 700;
    padding: 10px 15px;
    position: relative;
    top: -5px;
    text-align: center; } }

.booking-notification {
  background-color: $primary;
  padding: 15px 0 25px;
  color: white;

  &__title {
    margin-bottom: 15px;
    color: white;

    h2 {
      color: white; } }

  &__actions {
    a {
      margin-bottom: 20px; } } }


.cost-summ {
  &__title {
    .subtitle {
      font-weight: 700;
      text-align: left;
      margin-bottom: 15px; } }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid $gray;

    h3 {
      margin-bottom: 0;
      font-weight: 700; } } }
