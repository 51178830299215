.img-slider {
  &__inner {
    margin-top: 50px; } }

.img-caption {
  max-width: 100%;
  margin: 0 auto 25px;
  display: block;
  text-decoration: none;

  @include lg {
    margin-bottom: 0; }

  &__image {
    margin: 0 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: $text-dark;
    margin-top: 10px;

    @include md {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px; } } }
