.breadcrumbs {
  padding: 7px 0;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative;

      a,
      span {
        color: $gray-dark;
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 500; } }

    li + li {
      margin-left: 4px;
      padding-left: 20px;

      &:before {
        content: '';
        border: solid $gray-dark;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        transform: rotate(-45deg);
        top: 6px;
        left: 3px; } } } }
