@mixin font-heading {
  font-family: $font-heading, $font-default; }

@mixin font-text {
  font-family: $font-default; }

@mixin font-icon {
  font-family: $font-icon; }

@mixin smooth-scroll {
  -webkit-overflow-scrolling: touch; }

@mixin smooth-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin gpu {
  transform: translate3d(0,0,0); }

@mixin sm {
  @include w-min(576) {
    @content; } }

@mixin md {
  @include w-min(768) {
    @content; } }

@mixin lg {
  @include w-min(992) {
    @content; } }

@mixin xl {
  @include w-min(1200) {
    @content; } }

@mixin xxl {
  @include w-min(1920) {
    @content; } }
