.card {
  background-color: white;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 13px 7px;
  text-align: center;
  margin-top: 30px;
  z-index: 2;
  position: relative;

  &__title {
    font-weight: 700; }

  i {
    color: $green; }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px; }

  @include md {
    padding: 23px 13px;
    margin-top: 60px; }

  &_big {
    padding: 30px 20px;
    min-height: 280px;

    i {
      font-size: 35px; }

    i {
      font-size: 35px; } } }
