.reasons {
  .slider {
    margin-top: 40px; } }

.advantage {
  position: relative;
  margin-bottom: 30px;

  &:after {
    position: absolute;
    content: '';
    background-image: url(../img/secondary.svg);
    width: 65px;
    height: 65px;
    background-repeat: no-repeat;
    background-size: contain; }

  @include md {
     &:after {
       width: 115px;
       height: 115px; } }

  &_left-top {
    &:after {
      left: -20px;
      top: -20px; } }

  &_right-top {
    &:after {
      right: -20px;
      top: -20px; } }

  &_left-bottom {
    &:after {
      bottom: -20px;
      left: -20px; } }

  &_right-bottom {
    &:after {
      bottom: -20px;
      right: -20px; } }

  &__icon {
    width: 71px;
    height: 71px;
    line-height: 71px;
    padding: 11px;
    text-align: center;
    margin: 0 auto 25px;
    box-shadow: 0 0 10px rgba(97, 135, 132, 0.2);
    background-color: #FDFDFD;
    border-radius: 6px;
    display: flex;

    i {
      color: $green;
      font-size: 49px;
      max-width: 47px;
      display: block;
      margin: auto; } }

  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 15px; }

  &__description {
    font-size: 16px;
    line-height: 26px;
    text-align: center; }

  .card {
    margin-top: 0; } }
