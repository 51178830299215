.tabs {
  &_main {
    display: flex;
    justify-content: center;
    align-items: center;

    .tab {
      background-color: $gray-darker;
      flex: 50%;
      text-align: center;
      color: #B2B0B0;
      cursor: pointer;
      text-decoration: none;
      padding: 7px;
      position: relative;
      transition: color .2s ease;

      @include md {
        padding: 11px 20px;

        &:first-child {
          border-radius: 20px 0; }

        &:last-child {
          border-radius: 0 20px; } }

      &:hover {
        color: $text-dark; }

      span {
        position: relative;
        padding: 7px;
        font-weight: bold;
        font-size: 18px;
        line-height: 11px;

        @include md {
          padding: 20px;
          font-size: 30px;
          line-height: 41px; } }

      &.is-active {
        background-color: white;

        span {
          color: $text-dark; } } } } }

.tab-content {
  display: none;

  &.is-active {
    display: block; } }
