.review {
  &__wrapper {
    margin-bottom: 30px; }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 15px 15px 15px 0;
    position: relative;

    &_answer {
      background-color: white;
      border-radius: 6px;
      padding-left: 15px;
      @include sm {
        margin-left: 100px; }

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid white;
        top: -9px;
        left: 20px; } } }

  &__avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    max-width: 45px;
    flex: 0 0 45px;
    @include sm {
      width: 70px;
      height: 70px;
      max-width: 70px;
      flex: 0 0 70px; }

    img {
      width: 100%;
      height: 100%; } }

  &__body {
    margin-left: 15px;
    @include sm {
      margin-left: 30px; } }


  &__date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 5px; }

  &__name {
    font-weight: 700;
    margin-bottom: 5px; }

  &__rating {
    margin-bottom: 15px; }

  &__btn {
    .btn {
      font-size: 14px;
      font-weight: 600;
      min-height: 30px;
      padding: 5px 30px 5px 15px;
      color: $text-dark;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      &:focus,
      &:active,
      &:hover {
        &:before {
          border-left-color: white;
          border-bottom-color: white; } }

      &:before {
        content: '';
        position: absolute;
        top: 45%;
        right: 9px;
        height: 7px;
        width: 7px;
        border-left: 2px solid $green;
        border-bottom: 2px solid $green;
        transform: rotate(-45deg) scale(1, 1) translate(0, -50%);
        transform-origin: center;
        transition: .3s ease;
        z-index: 15; }

      &.is-open {
        &:before {
          transform: rotate(135deg) scale(1, 1) translate(0, 0);
          top: 45%;
          right: 12px; } } } } }
