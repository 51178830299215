body {
  background-color: white;
  line-height: 1.43;
  color: $text-dark;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  font-family: $font-heading;
  overflow-x: hidden;

  &.opened {
    overflow: hidden; } }

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;

  @include lg {
    margin-bottom: 85px; } }

.hidden {
  display: none !important; }

ul {
  margin: 0;
  padding: 0; }

a {
  transition: $base-transition;
  text-decoration: none;

  &:hover {
    text-decoration: none; } }

.text-center {
  text-align: center !important; }

.out {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: $primary-background; }

.main {
  margin-top: 92px;

  &_checkout {
    margin-top: 180px; }

  &_checkout_small {
    margin-top: 130px;
    padding-bottom: 70px; } }

.mt-default {
  margin-top: 30px !important; }

.mb-default {
  margin-bottom: 30px; }

.info-modal {
  &__close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $text-dark; }

    &:before {
      transform: translateY(-50%) rotate(45deg); }

    &:after {
      transform: translateY(-50%) rotate(-45deg); } } }

.content-wrap {
  border-radius:  6px;
  position: relative;
  z-index: 1;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);

  @include md {
    &:before, &:after {
      content: '';
      position: absolute;
      background-image: url(../img/secondary.svg);
      background-repeat: no-repeat;
      width: 115px;
      height: 115px;
      z-index: -1; }

    &:before {
      left: -25px;
      bottom: -25px; }

    &:after {
      right: -25px;
      top: -25px; } }

  &_white {
    &:before, &:after {
      background-image: url(../img/secondary_white.svg); } } }

.map, .map-wrap, .map-wrap__inner {
  width: 100%;
  height: 100%; }

.like-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: $text-dark;

  &.is-active {
    .icon-like {
      &:before {
        content: "\e921";
        color: $red; } } } }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.pos-sticky {
  position: sticky;
  top: -160px;
  height: fit-content;

  @media (min-height: 720px) {
    top: 70px; }

  &_top-big {
    top: 150px;

    @media (min-height: 720px) {
      top: 150px; } } }

.read-more {
  position: relative;
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  text-decoration: none;
  padding-right: 40px;
  color: $green;

  &:before, &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    background-color: $green;
    width: 10px;
    height: 1px; }

  &.is-open {
    &:after {
      transform: translateX(4px) rotate(40deg); }

    &:before {
      transform: translateX(-4px) rotate(-40deg); } }

  &:before {
    transform: translateX(-4px) rotate(40deg); }

  &:after {
    transform: translateX(4px) rotate(-40deg); } }

.read-more-text {
  max-height: 20px;
  overflow: hidden;

  &_lg {
    max-height: 125px; }

  &.is-open {
    max-height: none; } }

.copy-link {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 17px;
  display: none;

  &.is-open {
    display: flex;
    flex-direction: column; }

  &__btn {
    font-size: 16px;
    text-decoration: none;
    color: $green;
    display: block;

    &:hover {
      text-decoration: underline; } }
  &__text {
    font-size: 12px;
    letter-spacing: 0.81px;
    color: #9b9b9b; }

  &__message {
    display: none;
    text-align: center;
    color: $green;
    margin-top: 10px; } }

.section-wrapper {
  position: relative;
  padding: 50px 0;

  @include lg {
    padding: 100px 0; } }

.info-modal {
  width: 100%;
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 25px;
  text-align: center;
  background-color: $green;

  @include lg {
    padding: 0 35px;
    min-height: 60px; }

  [class^="icon-"], [class*=" icon-"] {
    margin: 0 10px;
    color: $text-dark;
    font-size: 18px; }

  &__close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;

    @include lg {
      width: 24px;
      height: 24px; }

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: white; }
    &:before {
      transform: translateY(-50%) rotate(45deg); }

    &:after {
      transform: translateY(-50%) rotate(-45deg); } }

  &_fixed {
    position: fixed;
    top: 90px;
    height: 50px;
    min-height: 50px;
    font-size: 14px;
    z-index: 999;
    transition: .3s;

    @include lg {
      top: 90px;
      font-size: 18px;

      span {
        display: none; } } }

  &.warning {
    background-color: $red;
    color: white;

    [class^="icon-"] {
      &:before {
        color: white; } } } }

.error, .error-text, .payment__error-message {
  color: $red;
  font-size: 14px;
  margin: 4px 0 0;
  display: block;

  @include lg {
    font-size: 16px; } }

.bb-default {
  border-bottom: 1px solid $green;
  padding-bottom: 1px;
  width: fit-content; }
