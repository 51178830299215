.caption {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary;
  position: relative;
  overflow: hidden;
  padding: 25px 10px;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 100px;
    min-width: 140px;
    width: 140px;
    background-repeat: no-repeat; }

  &:after {
    background-image: url(../img/primary-desktop.svg);
    right: -50px;
    top: -15px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1; }

  @include lg {
    padding: 35px 0;

    &:before, &:after {
      height: 280px;
      min-width: 330px;
      width: 330px;
      top: -100px; }

    &:before {
      background-image: url(../img/primary-left.svg);
      left: 0;
      top: -100px; }

    .after {
      right: 0; } }

  &_big {
    padding-bottom: 45px;

    @include lg {
      padding-bottom: 85px; } }

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: white;
    margin-bottom: 5px;
    position: relative;
    z-index: 2; } }
