.switch {
  font-size: 0;
  position: relative;

  &__label {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: $text-dark;
    margin-right: 7px;
    cursor: pointer; }

  &__checkbox {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    border: 1px solid $gray-darker;
    width: 45px;
    height: 16px;
    position: relative;
    z-index: 2; }

  &__circle {
    width: 20px;
    height: 19px;
    position: absolute;
    left: -2px;
    top: -3px;
    border-radius: 50%;
    background: $gray;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: translateX(0) translateZ(0);

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 1px;
      background-color: white; }

    &:before {
      transform: translate(-50%, -50%) translateZ(0) rotate(55deg); }

    &:after {
      transform: translate(-50%, -50%) translateZ(0) rotate(-55deg); } }

  input {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 45px;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer; }

  input:checked {
    & + .switch__checkbox {
      background-color: transparent;
      border: 1px solid $green;

      .switch__circle {
        transform: translateX(130%) translateZ(0);
        background: $green;

        &:before, &:after {
          background-color: white; }

        &:before {
          width: 4px;
          transform: translate(-5px, 3px) translateZ(0) rotate(40deg); }

        &:after {
          width: 11px;
          transform: translate(-4px, 0) translateZ(0) rotate(-55deg); } } } }

  &_link {
    display: block;
    position: relative;
    z-index: 12;
    text-decoration: none;
    color: $text-dark; }

  &_dib {
    display: inline-block; }

  &_animate {
    .switch__circle {
      transition: all .25s ease; }

    .switch__checkbox {
      transition: all .25s ease; } }

  &_label-right {
    input {
      left: 0;
      right: auto; }

    .switch__label {
      margin-right: 0;
      margin-left: 15px; } } }
