.about-video {
  position: relative;
  background-color: $primary;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-image: url(../img/primary-bottom.svg);
    width: 330px;
    height: 284px;
    bottom: -150px;
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    transform: scale(-1, 1); }

  .social {
    display: flex;
    justify-content: space-between;
    width: 195px;
    margin: 25px auto 0;

    &__link {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $base-transition;

      &:hover {
        background-color: $green;
        i {
          color: white; } }

      i {
        font-size: 15px;
        color: $green;
        transition: $base-transition; } }

    @include lg {
      margin: 25px 0 0; } }

  .title {
    text-align: center;
    margin-bottom: 10px;

    @include lg {
      text-align: left; } }

  .bg_text {
    font-weight: 800;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 26px;
    margin-right: -26px;
    color: #588BBE;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 60%;
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #86B5E4; }

    @include md {
      font-size: 34px; }

    @include lg {
      &:before {
        width: 120%;
        left: 0;
        transform: translateX(0); } }

    @include xl {
      width: 100%;
      font-size: 42px;
      letter-spacing: 30px;
      margin-right: -30px; } }

  &__text {
    color: white;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    @include lg {
      text-align: left;
      font-size: 22px;
      line-height: 38px; } }

  .content-wrap {

    @include sm {
      height: 286px; }

    @include md {
      height: 388px; }

    @include lg {
      height: 344px; }

    @include xl {
      height: 410px; } }

  &__video {
    height: 100%;
    margin-top: 30px;

    @include lg {
      margin-top: 0; }

    .video__btn_main {
      width: 70px;
      height: 70px;
      top: 31%;

      @include md {
        width: 120px;
        height: 120px;
        top: 34%; } } } }
