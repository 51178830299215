.user {
  position: relative;
  display: block;

  .dropdown {
    display: none;
    top: 105%;
    left: auto;
    right: 0;

    &:before {
      right: 12px;
      left: auto; }

    @include md {
      display: block; } }

  &:hover {
    .dropdown {
      pointer-events: all;
      opacity: 1;
      visibility: visible; } }

  &.has-messages {
    @include md {
      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #099;
        top: -1px;
        right: 2px; } } }

  &__image {
    display: block;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__no-image {
    width: 100%;
    height: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-dark;
    text-align: center;
    border: 1px solid $green;
    border-radius: 50%;

    i {
      max-height: 25px; } } }
