.modal {
  max-width: 520px;
  margin: 50px auto;
  background: #FFFFFF;
  border-radius: 0;

  @include md {
    border-radius: 20px;
    box-shadow: 0 2px 40px rgba(23, 128, 224, 0.1); }

  &_lg {
    max-width: 700px;
    margin-top: 70px;

    @include md {
      margin-top: 90px; }

    .modal__content {
      @include md {
        padding: 0 51px 51px; } } }

  &__content {
    padding: 0 11px 31px;
    position: relative;

    @include md {
      padding: 0 41px 51px; } }

  &__form {
    padding-top: 31px;

    @include md {
      padding-top: 41px; } } }
