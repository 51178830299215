.btn {
  min-height: 43px;
  padding: 7px 40px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: $base-transition;
  outline: none;
  appearance: none;
  border: 2px solid $green;
  cursor: pointer;

  &_back {
    position: relative;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: $green;
    display: inline-block;
    padding-left: 15px;

    @include lg {
      font-size: 18px; }

    &:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      left: 0;
      top: 4px;
      border: 1px solid $green;
      border-right: none;
      border-top: none;
      transform: rotate(45deg);

      @include lg {
        top: 7px;
        width: 7px;
        height: 7px; } } }

  @include hover {
    background-color: $green;
    color: white; }

  &_primary {
    background-color: $green;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 153, 151, 0.2);

    @include hover {
      background-color: white;
      border-color: $green;
      color: $green; } }

  &.is-disabled {
    background-color: #D9D9D9;
    color: white;
    border-color: #D9D9D9;
    box-shadow: none; }

  &_transparent {
    border-color: transparent;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    color: $text-dark;
    height: fit-content;
    min-height: auto;

    &:hover {
      background: transparent;
      color: $green; }

    &:after {
      position: absolute;
      content: '';
      right: -17px;
      top: 4px;
      border: solid $green;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      transition: transform 0.3s ease; } }

  &_secondary {
    background-color: white;
    color: $green; }

  &_cancelation {
    background-color: white;
    font-size: 16px;
    color: $text-dark;
    font-weight: 600; }

  &_disabled {
    background-color: #D8D8D8;
    color: white;
    border-color: #D8D8D8; }

  &_sm {
    padding: 11px 25px; }

  &_edit {
    min-height: 30px;
    font-size: 14px;
    padding: 4px 15px;
    margin: 7px 0; }

  &_center {
    display: block;
    margin: 0 auto;
    width: fit-content; }

  &_icon {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    padding: 0;

    i {
      font-size: 25px; } }

  &_icon-before {
    flex-direction: row-reverse;
    font-weight: 600;

    &:before {
      color: $green; } } }

