.contact-user {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  margin-top: 25px;
  justify-content: center;
  padding: 15px;

  @include md {
    width: 70%;
    margin: 15px auto 0; }

  @include lg {
    width: 100%; }

  @include xl {
    padding: 15px 30px; }

  &__avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; } }

  &__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 15px; }

  &__btn {
    .btn {
      font-size: 14px;
      font-weight: 600;
      min-height: 30px;
      padding: 5px 15px;
      color: $text-dark; } } }

