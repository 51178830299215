@font-face {
  font-family: 'icomoon';
  src:  url('../redesign-fonts/icomoon.eot?gwitnp');
  src:  url('../redesign-fonts/icomoon.eot?gwitnp#iefix') format('embedded-opentype'),
  url('../redesign-fonts/icomoon.ttf?gwitnp') format('truetype'),
  url('../redesign-fonts/icomoon.woff?gwitnp') format('woff'),
  url('../redesign-fonts/icomoon.svg?gwitnp#icomoon') format('svg') {}
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon {
  display: inline-block;
  vertical-align: middle;

  &-sm {
    font-size: 16px; }

  &-md {
    font-size: 20px; }

  &-lg {
    font-size: 24px; } }


.icon-filter:before {
  content: "\e900"; }

.icon-listing:before {
  content: "\e901"; }

.icon-profit:before {
  content: "\e902"; }

.icon-non-profit:before {
  content: "\e903"; }

.icon-medical:before {
  content: "\e904"; }

.icon-money:before {
  content: "\e905"; }

.icon-dog:before {
  content: "\e906"; }

.icon-charity:before {
  content: "\e907"; }

.icon-eco:before {
  content: "\e908"; }

.icon-list:before {
  content: "\e909"; }

.icon-earth:before {
  content: "\e90a"; }

.icon-save-money:before {
  content: "\e90b"; }

.icon-make-money:before {
  content: "\e90c"; }

.icon-map:before {
  content: "\e90d"; }

.icon-youtube:before {
  content: "\e90e"; }

.icon-mail:before {
  content: "\e90f"; }

.icon-instagram:before {
  content: "\e910"; }

.icon-coins:before {
  content: "\e911"; }

.icon-twitter:before {
  content: "\e912"; }

.icon-facebook:before {
  content: "\e913"; }

.icon-event:before {
  content: "\e914"; }

.icon-item:before {
  content: "\e915"; }

.icon-service:before {
  content: "\e916"; }

.icon-support:before {
  content: "\e917"; }

.icon-space:before {
  content: "\e918"; }

.icon-world:before {
  content: "\e919"; }

.icon-search:before {
  content: "\e91a"; }

.icon-user:before {
  content: "\e91b"; }

.icon-email:before {
  content: "\e91d"; }

.icon-phone:before {
  content: "\e91c"; }

.icon-warning:before {
  content: "\e91e"; }

.icon-arrow:before {
  content: "\e91f"; }

.icon-like:before {
  content: "\e920"; }

.icon-share:before {
  content: "\e923"; }

.icon-listing-id:before {
  content: "\e922"; }

.icon-benefiting:before {
  content: "\e91d"; }

.icon-team:before {
  content: "\e924"; }

.icon-share:before {
  content: "\e923"; }

.icon-listing-id:before {
  content: "\e922"; }

.icon-benefiting:before {
  content: "\e91d"; }

.icon-team:before {
  content: "\e924"; }

.icon-fb-share:before {
  content: "\e925"; }

.icon-twitter-share:before {
  content: "\e928"; }

.icon-link-share:before {
  content: "\e927"; }

.icon-mail-share:before {
  content: "\e926"; }

.icon-check:before {
  content: "\e929"; }

.icon-reset:before {
  content: "\e92a"; }

.icon-clock:before {
  content: "\e92b"; }

.icon-card:before {
  content: "\e92c"; }
