.expander {
  &__head {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .subtitle {
      margin-right: 20px;
      color: $text-dark;
      font-size: 20px;
      font-weight: 700; } }

  &__content {
    padding-top: 20px;

    @include md {
      padding-top: 30px; } }

  &__close-icon {
    width: 20px;
    height: 20px;
    position: relative;
    transform: scale(1, 1);
    transition: all .25s ease;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 2px;
      width: 10px;
      background-color: $green; }

    &:before {
      transform: rotate(45deg);
      right: 6px; }

    &:after {
      transform: rotate(-45deg); } }

  &.is-open {
    .expander__close-icon {
      transform: scale(-1, -1);
      margin-left: 4px;

      @include md {
        margin-left: -7px; } } }

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px; }

  p {
    margin-bottom: 10px; } }
