.dropdown {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 30px;
  left: 0;
  min-width: 140px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, .1);
  padding: 15px 0;
  transition: $base-transition;
  z-index: 100;

  @include md {
    top: 60px; }

  &__item {
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 15px;

    &:last-child {
      margin-bottom: 0; }

    a {
      color: $text-dark;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.3s ease;

      &:hover {
        border-bottom-color: $green; } } }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid white;
    top: -9px;
    left: 12px; } }
