.list-venue {
  @include lg {
    background-image: url(../img/main.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 120%;
    margin-bottom: 0; }

  @include xxl {
    background-size: cover; } }

.section-info {
  padding: 30px 0 50px;

  @include lg {
    padding: 40px 0 70px; }

  &__icons {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 290px;
    background-size: contain;

    @include lg {
      min-height: 360px;
      background-position: right center; } }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: $text-dark;
    position: relative;
    margin-bottom: 25px;
    text-align: center;

    @include md {
      font-size: 30px;
      margin-bottom: 35px;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 110%;
        left: 0;
        bottom: -5px;
        background-color: $green; } }

    @include lg {
      font-size: 32px;
      line-height: 62px; }

    @include xl {
      font-size: 36px; } }

  &__description {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-dark;

    @include lg {
      font-weight: 700;
      font-style: normal;
      font-size: 24px;
      line-height: 38px; } } }

.list-cards {
  padding: 0 0 40px;

  @include lg {
    padding: 0 0 100px; }

  &__title {
    margin-bottom: 55px;
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;

    @include lg {
      font-size: 48px;
      margin-bottom: 135px; } }

  &__content {

    .slider_dots .slick-dots {
      margin-top: 0;
      margin-bottom: 25px; }

    @include lg {
      max-height: 450px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-bottom: 120px;
      background-image: url(../img/list-cards-bg.svg); }

    .step {
      min-height: 450px;

      @include lg {
        min-height: 590px;
        margin-top: -70px; }

      &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 20px;

        @include lg {
          font-weight: 600;
          font-size: 36px;
          line-height: 45px;
          margin: 10px 0; } }

      &__list {
        margin-left: 20px;

        @include lg {
          margin-left: 60px;
          margin-top: 25px; } }

      &__list-item {
        text-align: left;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: flex-start;

        @include lg {
          margin-bottom: 30px;
          font-size: 18px; }

        i {
          margin-right: 10px;
          margin-top: 5px; }

        @include md {
          margin-right: 15px;
          margin-top: 3px; } }

      &__top {
        width: 200px;
        height: 200px;
        margin-top: -120px;

        @include lg {
          width: 300px;
          height: 300px;
          margin-top: -180px;
          margin-bottom: 25px; } }

      &__icon {
        bottom: 30px;

        i {
          font-size: 60px;

          @include lg {
            font-size: 80px; } } } } } }
