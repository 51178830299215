.hero {
  margin-top: 0;
  width: 100%;
  padding: 50px 0 0;
  margin-bottom: 0;

  @include md {
    padding: 90px 0 0; }

  @include lg {
    padding: 0;
    margin-top: 92px; }

  &__content {
    width: 100%;
    min-height: 600px;
    position: relative;

    @include lg {
      min-height: calc(100vh - 90px); } }

  &__static {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3; }

  &__title {
    font-size: 26px;
    color: white;
    font-weight: 900;
    text-align: center;
    margin: 0 auto 55px;
    line-height: 1.2;

    @include lg {
      font-size: 56px;
      width: 80%; } }

  &__subtitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 95px;
    color: white; }

  &__slide {
    min-height: 600px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //background-color: #1B75BC

    @include lg {
      min-height: calc(100vh - 90px); }

    .bg_img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
 } }      //opacity: 0.75

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    @include md {
      flex-direction: row; }

    .field {
      flex: 1;
      margin-bottom: 10px;
      width: 100%;

      @include md {
        margin-bottom: 0; }

      input {
        margin-bottom: 0;
        border-radius: 4px; }

      @include md {
        input {
          border-radius: 0; }

        &:first-of-type {
          input {
            border-radius: 4px 0 0 4px; } }

        &:last-of-type {
          input {
            border-radius: 0 4px 4px 0; } } } } }

  .btn_icon {
    width: 100%;
    height: 36px;
    border-radius: 4px;

    @include md {
      width: 59px;
      border-radius: 50%;
      height: 59px;
      margin-left: 15px; }

    span {
      display: block;

      @include md {
        display: none; } }

    i {
      display: none;

      @include md {
        display: block; } } } }
