h1, h2, h3, h4, h5, .heading {
  display: block;
  padding: 0;
  margin: 0;
  font-family: $font-heading;
  font-weight: 700;
  line-height: 1.43;
  color: $text-dark; }

h2 {
  font-size: $heading-size-xs;
  @include sm {
    font-size: $heading-size-sm; }
  @include md {
    font-size: $heading-size-md; }
  @include lg {
    font-size: $heading-size-lg; }
  @include xl {
    font-size: $heading-size-xl; } }

a {
  cursor: pointer;
  text-decoration: none; }

img {
  max-width: 100%; }

ul {
  list-style-type: none; }

input {
  @include font-text; }

input::placeholder {
  color: $gray;
  @include font-text;
  font-size: 18px; }

p {
  margin-bottom: 0; }

.color-green {
  color: $green; }

.link {
  color: $green; }

.title {
  font-size: 24px;
  line-height: 21px;
  margin-bottom: 15px;

  @include md {
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 21px; }

  &_lg {
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    text-align: center;

    @include md {
      font-size: 48px;
      line-height: 61px; } }

  &_white {
    color: white; } }

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  @include md {
    font-size: 18px; }

  &_white {
    color: white; }

  &_italic {
    font-style: italic; } }

.list {
  position: relative;
  padding: 23px 33px;
  background-color: white;
  border-radius: 6px;
  z-index: 3;

  &__title {
    color: $text-dark;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    margin-bottom: 7px; }

  li {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px; } }
