.rating {
  font-size: 0;
  width: auto;
  position: relative;
  overflow: hidden;

  &__star {
    display: inline-block;
    width: 19px;
    height: 19px;
    padding-right: 3px;

    svg {
      display: block;
      fill: $gray-dark;
      width: 19px;
      height: 19px; }

    &.is-full {
      svg {
        fill: $orange; } } } }
