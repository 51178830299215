.footer {
  width: 100%;
  background-color: $primary;
  position: relative;
  margin-top: auto;
  padding: 35px 0 15px;
  background-image: url(../img/primary.svg);
  background-repeat: no-repeat;
  background-position: right 0;

  @include lg {
    padding: 55px 0 25px; }

  .error-text {
    margin-top: 10px;
    color: $red-light; }

  &__conf-msg {
    color: $green; }

  li {
    margin-bottom: 12px; }

  .logo {
    display: none;

    @include lg {
      width: 220px;
      height: auto;
      display: block; }

    .logo-name, .logo-subname, .logo-tm {
      fill: white;
      stroke: white; } }

  &__form {
    margin-top: 35px;
    margin-bottom: 35px;

    @include md {
      margin-top: 0; }

    @include lg {
      margin-top: 35px;
      margin-bottom: 0; } }

  &__text {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 12px;

    @include lg {
      text-align: left; } }

  &__link {
    color: white;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    line-height: 24px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      left: 0;
      bottom: -3px;
      background-color: white;
      transition: width 0.3s ease; }

    &:hover {
      &:after {
        width: 100%; } }

    &_center {
      text-align: center;
      display: block;

      @include lg {
        display: inline-block;
        text-align: left; } } }

  &__social {
    .social {
      display: flex;
      justify-content: space-between;
      max-width: 70%;
      margin: 0 auto;

      &__link {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $base-transition;

        &:hover {
          background-color: $green;
          i {
            color: white; } }

        i {
          font-size: 14px;
          color: $green;
          transition: $base-transition; } }

      @include md {
        max-width: 50%; }

      @include lg {
        max-width: 100%;
        margin: 0; } } }

  &__copy {
    padding-top: 15px;
    border-top: 1px solid $blue-light;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-top: 30px;

    @include lg {
      padding-top: 25px; } }

  &__conf-msg {
    color: white;
    font-size: 14px;
    text-align: left; }

  &__list {
    width: 100%;

    @include lg {
      width: fit-content;
      margin-left: auto; } }

  &__field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include md {
      width: 70%;
      margin: 0 auto; }

    @include lg {
      width: 100%;
      flex-direction: row; }

    input {
      background: white;
      border: 1px solid #D8DDE6;
      border-radius: 6px;
      min-height: 43px;
      padding: 11px 18px;
      flex: 1;
      margin-bottom: 15px;

      @include lg {
        margin-bottom: 0;
        margin-right: 10px; } } } }
