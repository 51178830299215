.field {
  position: relative;
  margin-bottom: 15px;

  .error-text {
    color: $red;
    margin-top: 7px;
    font-size: 14px; }

  input, .input {
    background-color: white;
    width: 100%;
    height: 100%;
    color: $text-dark;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid $gray;
    appearance: none;
    border-radius: 4px;
    padding: 7px 15px;
    min-height: 36px; }

  input::placeholder {
    color: #979797;
    font-weight: 400;
    font-size: 15px; }

  &_lg {
    input {
      min-height: 48px; } }

  &__desc {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-dark;
    margin: 5px 0; }

  &__toggle-pass {
    min-height: 30px;
    line-height: 30px;
    padding: 0 10px;
    position: absolute;
    right: 6px;
    font-size: 15px;
    top: 50%;
    transform: translateY(-50%); }

  &__label {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $text-dark;
    margin-bottom: 5px;
    display: inline-block; }

  &__input {
    width: 100%;
    position: relative; }

  &__flex {
    display: flex; }

  &__error-message {
    color: $red;
    font-size: 14px;
    margin: 4px 0 0;
    display: block;

    @include lg {
      font-size: 16px; } }

  &__dropdown {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    left: 0;
    width: 100%; }

  &__link {
    color: $green;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-left: auto; }

  &__note {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    margin-top: 25px;

    a {
      color: $green; }

    @include md {
      font-size: 16px; } }

  &_full {
    width: 100%; }

  &.is-error {
    input {
      border-color: $red; } }

  &_xs {
    max-width: 65px; }

  &_sm {
    max-width: 200px; }

  &_margin {
    @include md {
      margin-right: 20px; } }

  &_gray {
    input {
      min-height: 43px;
      background-color: #F1F4F9;
      border-radius: 8px;
      padding: 9px 15px;

      &.password {
        padding-right: 75px; } } } }
