.slider {
    &-slide {
        text-decoration: none;
        color: $text-dark; }

    &_primary {
        .slick-slide {
            max-height: 480px;
            height: 480px;
            border-radius: 6px;

            & > div {
                max-height: inherit;
                height: inherit;

                img {
                    width: 100%;
                    height: 100%;
                    max-height: inherit;
                    object-fit: cover;
                    border-radius: 6px; } } } }

    &_thumb {
        .slick-list {
            max-width: 90%;
            margin: 33px auto 0; }

        .slick-slide {
            max-height: 95px;
            height: 95px;
            border-radius: 6px;
            margin: 0 3px;

            & > div {
                max-height: inherit;
                height: inherit;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    max-height: inherit;
                    object-fit: cover;
                    border-radius: 6px; } } }

        .next, .prev {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background-color: white;
            border: 1px solid $green;
            line-height: 33px;
            top: 35%;
            position: absolute;
            cursor: pointer;

            svg {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 15px;
                stroke: none;
                top: 50%; } }

        .next {
            right: -5px;

            @include xl {
                right: 0; } }

        .prev {
            left: -2px;

            @include xl {
                left: 3px; }

            svg {
                transform: translate(-50%, -50%) scale(-1,1); } } }

    &__social-btns {
        position: absolute;
        top: 33px;
        right: 33px;
        z-index: 25; }

    &_common {
        max-width: 1255px;
        margin: 0 auto;

        a.slider-slide {
            display: block; } }

    &_dots {
        .slick-dots {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            margin-top: 35px;

            li {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin: 0 4px;
                position: relative;
                background-color: transparent;
                border: 1px solid $green;
                border-radius: 50%;

                &:before, &:after {
                    position: absolute;
                    content: ' ';
                    height: 12px;
                    width: 4px;
                    top: 0;
                    background-color: transparent; }

                &:before {
                    left: -4px; }

                &:after {
                    right: -4px; } }

            button {
                font-size: 0; }

            .slick-active {
                background-color: $green; } } }

    &_dots-white {
      .slick-dots {
        li {
          border: 1px solid white; }

        .slick-active {
          background-color: white; } } }

    &_full {
        .slider__inner {
            width: 100%; }

        .slider-slide {
            width: 100%;
            height: 70vh;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover; }

            &:not(.slick-initialized):not(:first-child) {
                display: none; } } }


    &.is-hidden-before-init {
        .residence-card__image {
            &:not(:first-child) {
                display: none; } }

        &.slick-initialized {
            .residence-card__image {
                display: block; } } } }

.slider-arrows {
    .prev, .next {
        position: absolute;
        bottom: -50px;
        z-index: 15;
        background-color: transparent;
        width: 30px;
        height: 40px;
        font-size: 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        @include lg {
            top: 35%; }

        svg {
            display: block;
            width: 100%;
            max-width: 20px;
            max-height: 40px;
            height: 100%;
            fill: $green;
            stroke: $green;
            stroke-width: 1px; } }

    .prev {
        left: 40%;
        transform: translate3d(0,0,0) translateY(-50%);

        @include lg {
            left: -15px; } }

    .next {
        right: 40%;
        transform: translate3d(0,0,0) translateY(-50%) scale(-1, 1);

        @include lg {
            right: -15px; } }

    &_full {
        .prev {
            left: 0; }

        .next {
            right: 0; }

        .prev, .next {
            width: 210px;
            padding-left: 85px;
            padding-right: 85px;
            height: 100%;

            svg {
                max-width: 33px;
                max-height: 45px; } } }
    &_popup {
        .prev, .next {
            width: 210px;
            padding-left: 85px;
            padding-right: 85px;
            height: 80%;

            svg {
                max-width: 45px;
                max-height: 70px; } } } }

.slider-popup {
    &_main {
        margin-bottom: 35px;

        .slick-track {
            padding-top: 30px; }

        & + .btn {
            display: none; } }

    &_thumbnails {
        max-width: 1220px;
        margin: 0 auto;

        img {
            border: 2px solid transparent; }

        .slick-slide {
            margin: 0 5px; } } }

.slider-counter {
    text-align: center;
    color: white;
    font-size: 16px;
    display: none;
    line-height: 1.2;
    letter-spacing: 1.2px;
    position: absolute;
    width: 100%; }

.popup-slide {
    text-align: center;

    &__inner {
        max-width: 76vw;
        height: 614px;
        margin: 0 auto;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; } } }

.slide-thumb {
    text-align: center;
    cursor: pointer;

    &__inner {
        max-width: 268px;
        height: 168px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }
